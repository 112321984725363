// React
import React from "react";
// External Libraries
import { Flex } from "@chakra-ui/react";

// Horizontal direction
const HSeparator = ({ variant, children, ...rest }) => {
  return <Flex h='1px' w='100%' bg='rgba(135, 140, 189, 0.3)' {...rest}></Flex>;
};

// Vertical direction
const VSeparator = ({ variant, children, ...rest }) => {
  return <Flex w='1px' bg='rgba(135, 140, 189, 0.3)' {...rest}></Flex>;
};

export { HSeparator, VSeparator };
