export const columnsDataTxn = [
  {
    Header: "DATE",
    accessor: "date",
  },
  {
    Header: "TYPE",
    accessor: "transaction_type",
  },
  {
    Header: "ACCOUNTS",
    accessor: "account",
  },
  {
    Header: "AMOUNT",
    accessor: "amount",
  },
  {
    Header: "CODE",
    accessor: "transaction_code",
  },
  {
    Header: "STATUS",
    accessor: "status",
  }
];
