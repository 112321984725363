// React
import React, { useEffect,useState } from "react";
// External Libraries
import AdminTxnTable from './components/AdminTxnTable';
//import { totalTable } from "variables/admin/totalTable";
import $ from "jquery";
window.$ = $;

export default function AdminDashboardTxn() {

// session status
const [totalTable, setTotalTable ] = useState("");      
// api call
// sanakang added (temporary)
useEffect(() => {
  fetch('/admin/totalTransactionInfo')
  .then(message => message.json())                        // api return data Json parsing
  .then(message =>{                                      // api return                   
    if (message !== undefined) {
      setTotalTable(message.totalTable);
    }
  });
},[]); // rerender to  message change -> refresh OR new session



if(totalTable.length > 0){
  return (<AdminTxnTable txnTableData = {totalTable} />);
  } else{
  return null;
}


}
