export const columnsDataAccordion = [
  {
    Header: "NAME",
    accessor: "name",
  },
  {
    Header: "ADDRESS",
    accessor: "address",
  },
  {
    Header: "KRDC",
    accessor: "krdcAmount",
  },
  {
    Header: "KRW",
    accessor: "krwAmount",
  }
];
