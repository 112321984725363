// React
import React from "react";
// External Libraries
import { Flex, Text, useColorModeValue } from "@chakra-ui/react";

export default function Footer() {
  return (
    <Flex
      zIndex='3'
      flexDirection={{
        base: "column",
        xl: "row",
      }}
      alignItems={{
        base: "center",
        xl: "start",
      }}
      justifyContent='center'
      px={{ base: "30px", md: "50px" }}
      pb='30px'>
      <Text
        color={useColorModeValue("gray.400", "white")}
        textAlign={{
          base: "center",
          xl: "center",
        }}
        mb={{ base: "20px", xl: "0px" }}>
        {" "}
        &copy;
        2022
        <Text as='span' fontWeight='500' ms='4px'>
          VENETA. All Rights Reserved.
        </Text>
      </Text>
    </Flex>
  );
}
