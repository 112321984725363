export const columnsDataTxn = [
  {
    Header: "DATE",
    accessor: "date",
  },
  {
    Header: "ACCOUNT",
    accessor: "account",
  },
  {
    Header: "REMARK",
    accessor: "remark",
  },
  {
    Header: "AMOUNT",
    accessor: "amount",
  },
  {
    Header: "BALANCE",
    accessor: "balance",
  },
  {
    Header: "STATUS",
    accessor: "status",
  },
  {
    Header: "TYPE",
    accessor: "type",
  }
];
