// React
import React, { useMemo } from "react";
// External Libraries
import { useGlobalFilter, usePagination, useSortBy, useTable } from "react-table";
import { BiWon, BiCoin } from "react-icons/bi";
import { Flex, Table, Icon, Tbody, Td, Text, Th, Thead, Tr, useColorModeValue } from "@chakra-ui/react";
// Components
import Card from "components/card/Card";
import ModalButton from "components/modal/ModalButton";
import KrwInput from "variables/modal/KrwInput";
import KrcBuy from "variables/modal/KrcBuy";

/** 
 * 
 * Props list
 *  - columnsData: data for the column names of the table
 *  - tableData: data for the table
 *  - setCheckAxios: data for Modal
 * 
 */
export default function AssetTableMobile({ columnsData, tableData, setCheckAxios}) {

  // Varaibles for AssetTable (react-table)
  const data = useMemo(() => tableData, [tableData]);
  const columns = useMemo(() => columnsData, [columnsData]);
  const { getTableProps, getTableBodyProps, headerGroups, page, prepareRow, initialState }
    = useTable( { columns, data }, useGlobalFilter, useSortBy, usePagination );
  initialState.pageSize = 5;

  // Variables for CSS Styling
  const textColor = useColorModeValue("secondaryGray.900", "white");
  const borderColor = useColorModeValue("gray.200", "whiteAlpha.100");

  // Variables for ModalButton handling
  var modalTag, modalType, buttonTxt = "";
  const modalButtonKrw = <KrwInput titleText = "KRW 예치하기" setCheckAxios={setCheckAxios}/>
  const modalButtonKrc = <KrcBuy titleText = "KRDC 구매하기" setCheckAxios={setCheckAxios}/>

  return (
    <Card
      direction='column'
      w='100%'
      px='0px'
      overflowX={{ sm: "scroll", lg: "hidden" }}>
      <Flex px='25px' justify='space-between' mb='10px' align='center'>
        <Text
          mt="20px"
          color={textColor}
          fontSize='15px'
          fontWeight='700'
          lineHeight='100%'>
          MY ASSET
        </Text>
      </Flex>
      <Table {...getTableProps()} variant='simple' color='gray.500' mb='24px' mt="25px">
        <Thead>
          {headerGroups.map((headerGroup, index) => (
            <Tr {...headerGroup.getHeaderGroupProps()} key={index}>
              {headerGroup.headers.map((column, index) => (
                column.render("Header") !== 'RATIO'
                ? <Th
                    {...column.getHeaderProps(column.getSortByToggleProps())}
                    key={index}
                    borderColor={borderColor}>
                    <Flex
                      justify='center'
                      align='center'
                      fontSize='8px'
                      color='gray.400'
                      mb='5px'>
                        {column.render("Header")}
                    </Flex>
                  </Th>
                : null 
              ))}
            </Tr>
          ))}
          
        </Thead>
        <Tbody {...getTableBodyProps()}>
          {page.map((row, index) => {
            prepareRow(row);
            return (
              <Tr {...row.getRowProps()} key={index}> {
                row.cells.map((cell, index) => {
                  let data = "";
                  let display = true;

                  if (cell.column.Header === "NAME") {
                    data = (
                      <Flex align='center' justify='center'>
                        <Icon
                          w='16px'
                          h='16px'
                          color= "blue.600"
                          mr='6px'
                          as={
                            cell.value === "KRW"
                              ? BiWon
                              : cell.value === "KRDC"
                              ? BiCoin
                              : null
                          }
                        />
                      <Text color={textColor} fontSize='9pt' fontWeight='600'>
                        {cell.value}
                      </Text>
                      </Flex>
                    );
                    // For modal .jsx component
                    modalTag = (cell.value == "KRW")
                                    ? modalButtonKrw
                                    : cell.value == "KRDC"
                                    ? modalButtonKrc
                                    : ""

                    modalType = (cell.value == "KRW")
                                    ? "krwInput"
                                    : cell.value == "KRDC"
                                    ? "krcBuy"
                                    : ""

                    buttonTxt = (cell.value == "KRW")
                                    ? "Deposit"
                                    : cell.value == "KRDC"
                                    ? "Exchange"
                                    : ""

                  } else if (cell.column.Header === "BALANCE") {
                    data = (
                      <Flex align='center' justify='center'>
                        <Text color={textColor} fontSize='9pt' fontWeight='600'>
                          {cell.value}
                        </Text>
                      </Flex>
                    );
                  }  else if (cell.column.Header === "RATIO") {
                    data = "";
                    display = false;
                  } else if (cell.column.Header === "DEPOSIT") {
                    data = (
                      <Flex justify='center' align='center'>
                        <ModalButton
                          mr='0px' mt='5px' px='5px'
                          buttonText = {buttonTxt}
                          buttonColor = "blue"
                          buttonSize = 'xs'
                          buttonWidth = '70px'
                          modalType = {modalType}
                          contentText = {modalTag}
                          buttonTextSize='8px'/>
                      </Flex>
                    );
                    
                  }
                  return (
                    display==true
                    ? <Td
                      {...cell.getCellProps()}
                      key={index}
                      fontSize={{ base: "10px" }}
                      maxH='30px !important'
                      py='10px'
                      minW={{ sm: "80px", md: "150px", lg: "auto" }}
                      borderColor='transparent'>
                      {data}
                    </Td>
                    : null
                  );
                })}
              </Tr>
            );
          })}
        </Tbody>
      </Table>
    </Card>
  );
}
