export const linkStyles = {
  components: {
    Link: {
        baseStyle: {
          textDecoration: "none",
            boxShadow: "none",
          _focus: {
              boxShadow: "none"
          },
          _active: {
              boxShadow: "none"
          },
          _hover: {
            textDecoration: "none",
            border: "none"
          }

        },
        _hover: {
          textDecoration: "none",
          border: "none"
        }
    },
  },
};