
// React
import React from "react";
// External Libraries
import { MdKeyboardArrowDown } from "react-icons/md";
import { BiWon, BiCoin } from "react-icons/bi";
import { MdSwapHorizontalCircle } from "react-icons/md";
import { Icon, Flex, Text, Menu, MenuButton, MenuItem, MenuList, useDisclosure, useColorModeValue } from "@chakra-ui/react";

/**
 * 
 * Props list
 *  - setCurrentMenu: a function to set currentMenu (useState variable)
 * 
 */
export default function TxnTableButton({ setCurrentMenu }) {
  
  // Variables to control the menu button state (handled by useDisclosure, a Chakra-UI custom hook)
  const {
    isOpen: isOpen,
    onOpen: onOpen,
    onClose: onClose,
  } = useDisclosure();
  
  // Variables for CSS Styling
  const textColor = useColorModeValue("secondaryGray.500", "white");
  const textHover = useColorModeValue(
    { color: "secondaryGray.900", bg: "unset" },
    { color: "secondaryGray.500", bg: "unset" }
  );
  const bgList = useColorModeValue("white", "whiteAlpha.100");
  const bgShadow = useColorModeValue(
    "14px 17px 40px 4px rgba(112, 144, 176, 0.08)",
    "unset"
  );
  const bgButton = useColorModeValue("secondaryGray.300", "whiteAlpha.100");
  const bgHover = useColorModeValue(
    { bg: "secondaryGray.400" },
    { bg: "whiteAlpha.50" }
  );
  const bgFocus = useColorModeValue(
    { bg: "secondaryGray.300" },
    { bg: "whiteAlpha.100" }
  );

  return (
    <Menu isOpen={isOpen} onClose={onClose}>
      <MenuButton
        align='center'
        justifyContent='center'
        bg={bgButton}
        _hover={bgHover}
        _focus={bgFocus}
        _active={bgFocus}
        w='37px'
        h='37px'
        lineHeight='100%'
        onClick={onOpen}
        borderRadius='12px'
        >
        <Icon as={MdKeyboardArrowDown} color='blue.800' w='30px' h='30px' />
      </MenuButton>
      <MenuList
        w='150px'
        minW='unset'
        maxW='150px !important'
        border='transparent'
        backdropFilter='blur(63px)'
        bg={bgList}
        boxShadow={bgShadow}
        borderRadius='20px'
        p='15px'>
        <MenuItem
          transition='0.2s linear'
          color={textColor}
          _hover={textHover}
          p='0px'
          borderRadius='8px'
          _active={{
            bg: "transparent",
          }}
          _focus={{
            bg: "transparent",
          }}
          onClick={()=>{
            setCurrentMenu('ALL');
          }}
          mb='10px'>
          <Flex align='center'>
            <Icon as={MdSwapHorizontalCircle} h='16px' w='16px' me='8px' />
            <Text fontSize='sm' fontWeight='400'>
              ALL
            </Text>
          </Flex>
        </MenuItem>
        <MenuItem
          transition='0.2s linear'
          p='0px'
          borderRadius='8px'
          color={textColor}
          _hover={textHover}
          _active={{
            bg: "transparent",
          }}
          _focus={{
            bg: "transparent",
          }}
          onClick={()=>{
            setCurrentMenu('KRDC');
          }}
          mb='10px'>
          <Flex align='center'>
            <Icon as={BiCoin} h='16px' w='16px' me='8px' />
            <Text fontSize='sm' fontWeight='400'>
              KRDC
            </Text>
          </Flex>
        </MenuItem>
        <MenuItem
          transition='0.2s linear'
          p='0px'
          borderRadius='8px'
          color={textColor}
          _hover={textHover}
          _active={{
            bg: "transparent",
          }}
          _focus={{
            bg: "transparent",
          }}
          mb='10px'
          onClick={()=>{
            setCurrentMenu('KRW');
          }}
          >
          <Flex align='center'>
            <Icon as={BiWon} h='16px' w='16px' me='8px' />
            <Text fontSize='sm' fontWeight='400'>
              KRW
            </Text>
          </Flex>
        </MenuItem>
      </MenuList>
    </Menu>
  );
}