// React
import React from "react";
// External Libraries
import ReactDOM from "react-dom";
import { BrowserRouter, Route, Switch, Redirect } from "react-router-dom";
import { Provider } from "react-redux";
import { ChakraProvider } from "@chakra-ui/react";
import { ThemeEditorProvider } from "@hypertheme-editor/chakra-ui";
// Components
import LoginLayout from "layouts/login";
import AdminLayout from "layouts/admin";
import SignupView from "views/signUp";
import AuthAuthenticationView from "views/AuthAuthentication";
import ErrorView from "views/ErrorView";
import ServiceLayout from "layouts/service";
// Variables
import "assets/css/App.css";
import theme from "theme/theme";
// Assets
import store from './store.js'

/**
 * 
 *  React Branch by View with Router 
 *  
 *  - Router Infomation - 
 *  /auth/sign-in             = login Page
 *  /auth/sign-up             = sign-up Page
 *  /auth/auth-authentication = user authentication Check Page
 *  /auth/error               = error Page
 *  /main                     = service Main Page
 * 
 */
ReactDOM.render(
  <ChakraProvider theme={theme}>
    <React.StrictMode>
      <ThemeEditorProvider>
        <Provider store={store}>
        <BrowserRouter>
          <Switch>
            <Route path={`/auth/sign-in`} component={LoginLayout} />
            <Route path={`/auth/sign-up`} component={SignupView} />
            <Route path={`/auth/auth-authentication`} component={AuthAuthenticationView} />
            <Route path={`/auth/error`} component={ErrorView} />
            <Route path={`/console`} component={AdminLayout} />
            <Route path={`/admin/account`} component={AdminLayout} />
            <Route path={`/admin/user`} component={AdminLayout} />
            <Route path={`/admin/transaction`} component={AdminLayout} />
            <Route path={`/main`} component={ServiceLayout} />
            <Redirect from='/admin' to='/admin/user' />
            <Redirect from='/' to='/auth/sign-in' />
          </Switch>
        </BrowserRouter>
        </Provider>
      </ThemeEditorProvider>
    </React.StrictMode>
  </ChakraProvider>,
  document.getElementById("root")
);
