// React
import React, { useState, useRef} from 'react';
// External Libraries
import styled from 'styled-components';
import { Link } from 'react-router-dom';
import { CopyIcon } from '@chakra-ui/icons';
import { BsInfoCircle } from "react-icons/bs";
import { Text, IconButton, Popover, PopoverTrigger, PopoverContent, PopoverHeader, PopoverBody, PopoverArrow, PopoverCloseButton, Center, Flex, useClipboard } from "@chakra-ui/react";

/**
 * 
 * Props list
 *  - item: Each element of SidebarDataList from SidebarContent.js
 *          [{title, iconClosed, iconOpened, subNav},{title, iconClosed, iconOpened, subNav}]
 *          0th element : AccountList element  /  1st element : ContactList element
 *  - onClose: useDisclosure() variable to handle the open/close state of the subNav element
 * 
 */
export default function SidebarElement ({ item, onClose }) {
  // Variables to handle the visibility of each SidebarElement
  const [subnav, setSubnav] = useState(true);
  const showSubnav = () => setSubnav(!subnav);
  // A variable for useRef()
  const initialFocusRef = useRef();
  // Variables for the copy&paste functionality
  const [copyText, setCopyText] = useState('');
  const { hasCopied, onCopy } = useClipboard(copyText);

  return (
    <>
      <SidebarLink onClick={item.subNav && showSubnav}>
        <div>
          {item.icon}
          <SidebarLabel>{item.title}</SidebarLabel>
        </div>
        <div >
          {item.subNav && subnav
            ? item.iconOpened
            : item.subNav
            ? item.iconClosed
            : null}
        </div>
      </SidebarLink>
      {item.subNav !== undefined &&subnav &&
        item.subNav.map((item, index) => {
          
          return (
            <Flex direction='row' align='center' justify='center'>
              <DropdownLink to={item.path} key={index} onClick={onClose}>
                {item.icon}
                <SidebarLabel>{item.title}</SidebarLabel>
            </DropdownLink>
            
            <Popover
              initialFocusRef={initialFocusRef}
              orientation='horizontal'
              placement='bottom-start'
              closeOnBlur={true}
              variant="insideNav"
              arrowSize={0}
              onClick={(e)=>e.stopPropagation()}>
              <PopoverTrigger>
                <IconButton
                    aria-label='More Address Info'
                    icon={<Center w='75%' h='100%' pt={0.5}><BsInfoCircle color="gray.500"/></Center>}
                    p='0px'
                    isActive={false}
                    variant='unstyled'
                    size='sm'
                    isRound = {false}/>
              </PopoverTrigger>
              <PopoverContent color='white' bg='blue.800' borderColor='blue.800' marginLeft='30px' width='180px'>
                  <PopoverHeader pt={4} fontSize='18px' fontWeight='300' border='0' pl='20px'>
                      {item.title}
                      <IconButton
                        aria-label='More Address Info'
                        icon={<Center w='80%' h='100%' pb={1}><CopyIcon color="white"/></Center>}
                        p='0px'
                        isActive={false}
                        variant='unstyled'
                        size='sm'
                        isRound = {false}
                        onClick={onCopy}/>
                  </PopoverHeader>
                  <PopoverArrow />
                  <PopoverCloseButton />
                  <PopoverBody fontWeight='200'fontSize='md' pl='20px' pb='20px'>
                      <Text w='90%'>{item.address}{item.cName}</Text>
                  </PopoverBody>
              </PopoverContent>  
            </Popover>
            </Flex>
          );
        })}
    </>
  );
};

  const SidebarLink = styled(Link)`
    display: flex;
    color: #343434;
    justify-content: space-between;
    align-items: center;
    padding: 20px;
    list-style: none;
    height: 55px;
    text-decoration: none;
    font-size: 15px;
    font-weight: 700;
    margin-top: 5pt;
  
    &:hover {
      /* background: #D5D5D5; */
      border-left: 5px solid #2763A4;
      /* opacity: 0.3; */
      cursor: pointer;
    }
  `;
  
  const SidebarLabel = styled(Text)`
    margin-left: 16px;
    text-align: center;
  `;
  
  const DropdownLink = styled(Link)`
    /* background: #414757; */
    height: 55px;
    padding-left: 2rem;
    display: flex;
    align-items: center;
    text-decoration: none;
    /* color: #2763A4; */
    color: #5F5F5F;
    width: 60%;
    font-size: 15px;
    font-weight: 500;
  
    &:hover {
      background: #2763A4;
      color: white;
      cursor: pointer;
      opacity: 0.7;
    }
  `;
