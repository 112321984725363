// React
import React, { useRef, useState, useMemo } from "react";
// External Libraries
import { useDraggable } from "react-use-draggable-scroll";
import { Flex, Text } from "@chakra-ui/react";
// Components
import Card from "components/card/Card";
import TxnCard from "./TxnCard";
import TxnCardGroupButton from "./TxnCardGroupButton";
// Assets
import gradient1 from "assets/img/service/gradient1.jpg";

/**
 * 
 * Props list
 *  - txnTableData: all the transaction data
 *  - tableDataKRC, tableDataKRW: transaction data filtered by the type (i.e., KRC, KRW)
 * 
 */
export default function TxnCardGroup({ txnTableData }) {
  
  // A variable to set a focus towards TxnCardGroup
  const ref = useRef();
  
  // A variable to enable the scroll events inside TxnCardGroup
  const { events } = useDraggable(ref);
  
  // A variable to control the current menu of TxnCardGroup (useState variables)
  const [currentMenu, setCurrentMenu] = useState(txnTableData);
  
  // Variables for the table (react-table)
  const tableData = useMemo(() => txnTableData, [txnTableData]);
  const tableDataKRC = useMemo(() => txnTableData.filter(d => d.type == 'KRC'), [tableData]);
  const tableDataKRW = useMemo(() => txnTableData.filter(d => d.type == 'KRW'), [tableData]);

  return (
    <Card
      direction='row'
      w='100%'
      my='10px'
      overflowX="hidden"
      overflowY='hidden'
      backgroundImage={gradient1}
      backgroundRepeat='no-repeat'
      bgSize='cover'
      alignSelf='center'
      bgPosition='10%'>
      <Flex direction="column" justify='space-between'px='10px'>
        <Flex direction='row'justify='space-between' mt='35px' mb='0px' align='center'>
          <Text
            pl={{'base':'5px', 'md':'30px'}}
            mb={{'base':'0px', 'md':'10px'}}
            color='whiteAlpha.900'
            fontSize={{'base':'25px', 'md':'3xl'}}
            fontWeight='300'
            lineHeight='100%'
            overflowX='hidden'
            overflowY='hidden'>
            TRANSACTIONS
          </Text>
          <TxnCardGroupButton setCurrentMenu={setCurrentMenu}/>
        </Flex>
      <Flex
        direction="column"
        justify='space-between'
        m={{'base':'5px', 'md':'30px'}}
        mt={{'base':'30px', 'md':'35px'}}
        h='430px'
        bg='transparent'
        overflowY='scroll'
        className="flex max-w-xl space-x-3 overflow-x-scroll scrollbar-hide" 
        {...events}
        ref={ref}>
        {
          tableData.length === 0
          ? // if (txnTableData.length === 0)
            <Card bg="white" h="100%">
              <Text
                fontSize='xl'
                fontWeight='500'
                m="auto">
                  NO DATA AVAILABLE
              </Text>
            </Card>
          : (currentMenu === 'ALL' || currentMenu.length === 0)
          ? // if (txnTableData.length !== 0) && (currentMenu === 'ALL' || currentMenu.length === 0)
            tableData.map((data) => {
              return <TxnCard
                  date={data.date}
                  account={data.account}

                  amount={data.amount}
                  balance={data.balance}
                  
                  remark={data.remark}
                  type={data.type} />
            })
          : currentMenu === 'KRDC'
          ? // if (txnTableData.length !== 0) && (currentMenu === 'KRDC')
            tableDataKRC.map((data)=>{
              return <TxnCard
                  date={data.date}
                  account={data.account}

                  amount={data.amount}
                  balance={data.balance}
                  
                  remark={data.remark}
                  type={data.type} />
            })
          : currentMenu === 'KRW'
          ? // if (txnTableData.length !== 0) && (currentMenu === 'KRW')
            tableDataKRW.map((data)=>{
              return <TxnCard
                  date={data.date}
                  account={data.account}

                  amount={data.amount}
                  balance={data.balance}
                  
                  remark={data.remark}
                  type={data.type} />
            })
          : // if (txnTableData.length !== 0) && (currentMenu !== 'KRW') && (currentMenu !== 'KRDC') && (currentMenu !== 'ALL') & currentMenu.length !== 0
            txnTableData.map((data)=>{
              return <TxnCard
                  date={data.date}
                  account={data.account}

                  amount={data.amount}
                  balance={data.balance}
                  
                  remark={data.remark}
                  type={data.type}/>
            })
          }
        </Flex>
      </Flex>
    </Card>
  );
}