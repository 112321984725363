export const colDataComplex = [
  {
    Header: "NAME",
    accessor: "name",
  },
  {
    Header: "BALANCE",
    accessor: "balance",
  },
  {
    Header: "RATIO",
    accessor: "ratio",
  },
  {
    Header: "DEPOSIT",
    accessor: "deposit",
  }
];
