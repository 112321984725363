// React
import React, { useMemo } from "react";
// Components
import AdminTxnTableContent from "./AdminTxnTableContent";
// Variables
import { columnsDataTxn } from "variables/admin/columnsDataTxn";

/**
 * 
 * Props list
 *  - txnTableData: transaction data for TxnTable
 * 
 */
export default function AdminTxnTable({ txnTableData }) {

  // Variables for the table (react-table)
  const columns = useMemo(() => columnsDataTxn, [columnsDataTxn]);
  const tableData = useMemo(() => txnTableData, [txnTableData]);

  return (
        <AdminTxnTableContent
          columns = {columns}
          data = {tableData}/>
  );
}