// React
import React from "react";
// External Libraries
import { useLocation,useHistory } from "react-router-dom";
import {Box, Button,Flex, Heading, Text, useColorModeValue,} from "@chakra-ui/react";
// Components
import DefaultLogin from "layouts/login/Default";
// Assets
import illustration from "assets/img/login/auth.png";

/**
 * 
 * Props list - none
 */
 export default function AuthAuthentication() {

  // route Change variables
  const history = useHistory();
  const location = useLocation();

  // http variables
  const item = location.state.separateCheck;

  // style variables
  const textColor = useColorModeValue("navy.700", "white");
  const textColorSecondary = "gray.400";

  return (
    <DefaultLogin illustrationBackground={illustration} image={illustration}>
      <Flex
        maxW={{ base: "100%", md: "max-content" }}
        w='100%'
        mx={{ base: "auto", lg: "0px" }}
        me='auto'
        h='100%'
        alignItems='start'
        justifyContent='center'
        mb={{ base: "30px", md: "60px" }}
        px={{ base: "25px", md: "0px" }}
        mt={{ base: "40px", md: "14vh" }}
        flexDirection='column'>
        <Box me='auto'>
          <Heading color={textColor} fontSize='36px' mb='10px' >
            {
              item.returnCode === "first"
              ?
                "Welcome, "+ item.returnMessage+"!"
                : (item.returnCode === "second"
                  ?
                    "RE:) Welcome, "+ item.returnMessage+"!"
                :
                "No Welcome !"              
              )
            }
          </Heading>
          <br/><br/>
          <Text
            mb='36px'
            ms='4px'
            color={textColorSecondary}
            fontWeight='400'
            fontSize='md'>
              Identification has been completed<br/><br/>
              You can use the <strong>VenetaKRDC</strong> service<br/><br/>
              Click the button below to go to the login page
          </Text>
          <br/><br/>
        </Box>
        <br/><br/>
        <Button
              fontSize='sm'
              variant='brand'
              fontWeight='500'
              w='100%'
              h='50'
              mb='24px'
              onClick={()=>(history.push('/auth'))}>
              Move to Login Page
            </Button>
      </Flex>
    </DefaultLogin>
  );
}


