// React
import React from 'react';
// External Libraries
import { useDispatch } from "react-redux"
import { Button, Text } from "@chakra-ui/react";
// Components
import Modal from "components/modal/Modal";
// Variables
import { changeModalState } from "../../store"

/**
 * 
 * Props list
 *  - modalType: Refers to the type of modal to be controlled
 *  - buttonText:  Refers to the text of ModalButton
 *  - mt, px, buttonTextSize, buttonIcon, buttonColor, buttonSize: CSS styling props for the ModalButton 
 * 
 */
export default function ModalButton({ modalType, buttonText, buttonTextSize, buttonIcon, contentText, buttonColor, buttonSize, mr, mt, px, buttonWidth}) {
  
  // A variable to use function variables from store.js
  const dispatch = useDispatch();
  
  return (
    <>
      <Button colorScheme={buttonColor} size={buttonSize} width = {buttonWidth} onClick={ ()=>{ dispatch(changeModalState(modalType))}} mr={mr} mt={mt} px={px}>
        {buttonIcon}
        <Text mx='auto'fontSize={buttonTextSize}> {buttonText} </Text>
      </Button>
      <Modal
        modalContent = {contentText}
        modalType= {modalType}/>
    </>
  );
}
