import DefaultColumnFilter from "views/admin/user/components/DefaultColumnFilter";
import { SelectColumnFilter } from "views/admin/user/components/SelectColumnFilter";

export const columnsDataUser = [
  {
    Header: "NAME",
    accessor: "name",
    // Filter: SelectColumnFilter,
    // filter: 'equals'
  },
  {
    Header: "EMAIL",
    accessor: "email",
    // Filter: SelectColumnFilter,
    // filter: 'equals'
  },
  {
    Header: "DATE",
    accessor: "date",
  },
  {
    Header: "ACCOUNTS",
    accessor: "accountList",
    disableSortBy: true
  }
];
