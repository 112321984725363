// React
import React from "react";
// External Libraries
import { Box,  useColorModeValue, SimpleGrid } from "@chakra-ui/react";
// Components
import Card from "components/card/Card";
import AssetChartLeft from "views/service/components/AssetChartLeft";
import AssetChartRight from "views/service/components/AssetChartRight";

export default function AssetChart() {
  return (
    <Card
      direction='column'
      w='100%'
      py='0px'
      my='0px'
      shadow={ useColorModeValue(
        "14px 17px 40px 10px rgba(112, 144, 176, 0.08)",
        "unset"
      ) }
      overflowX={{ sm: "scroll", lg: "hidden" }}>
      <Box justify='center' align='center'>
        <SimpleGrid columns={{ base: 1, md: 2, xl: 2 }} gap='10px'>
          <AssetChartLeft title= "Total Asset" />
          <AssetChartRight title= "Total KRDC" />
        </SimpleGrid>
      </Box>
    </Card>
  );
}
