// React
import React, { useEffect,useState } from "react";
// External Libraries
import UserTable from './components/UserTable';
//import { userTableData } from "variables/admin/userData";
export default function AdminDashboardUser() {

  // session status
  const [userTableData, setUserTableData ] = useState("");
  // api status
  const [apiStatus, setApiStatus ] = useState(false);            
  // api call
  useEffect(() => {
    fetch('/admin/totalUserInfo')
    .then(message => message.json())                        // api return data Json parsing
    .then(message =>{                                      // api return                   
      setUserTableData(message);
    });
  },[apiStatus]); // rerender to  message change -> refresh OR new session

if(userTableData.length > 0){
  return (<UserTable txnTableData = {userTableData} setApiStatus={setApiStatus}/> );
}else{
  return null;
}

}
