// React
import React from 'react';
// External Libraries
import { useSelector } from "react-redux"
import { ExternalLinkIcon } from '@chakra-ui/icons';
import { Flex, Stat, StatLabel, StatNumber, useColorModeValue, Text } from "@chakra-ui/react";
// Components
import Card from "components/card/Card";
import ModalButton from "components/modal/ModalButton";
import KrcTransfer from "variables/modal/KrcTransfer";

/**
 * 
 * Props list
 *  - size: Refers to screen size (pc/mobile)
 *  - accountContactList, setCheckAxios: for children
 * 
 */
export default function AssetCardInside({ size, accountContactList, setCheckAxios }) {

  // A varible to represent current account info (from store.js)
  let accountAssetInfo = useSelector((state) => { return state.accountAssetInfo } );
  // A varible to control the fontsize of the AssetCard
  let fontSizeControl = (size === 'pc')? "2xl": { base: "lg", lg: "xl" };
  // A varible to control the ModalButton
  var modalButtonControl;
  // To define the value of modalButtonControl variable
  if (accountAssetInfo.krcAmountHold !== undefined && accountContactList.length > 0 && size !== undefined && size !== "") {
    if (size === 'pc') {
      modalButtonControl = {
        mt: '33px',
        buttonIcon: <ExternalLinkIcon mr='5px'/>,
        buttonTextSize: '18px',
        contentText: <KrcTransfer titleText = "KRDC 송금하기" accountContactList={accountContactList} setCheckAxios={setCheckAxios}/>
      };
    }
    else { // size === 'mobile'
      modalButtonControl = {
        mt: '28px',
        buttonIcon: null,
        buttonTextSize: '18px',
        contentText: <KrcTransfer titleText = "KRDC 송금하기" accountContactList={accountContactList} setCheckAxios={setCheckAxios}/>
      };
    }
  } else { // accountAssetInfo.krcAmountHold === undefined || accountContactList.length === 0 || size === undefined || size === ""
    modalButtonControl = {
      mt: '28px',
      buttonIcon: <ExternalLinkIcon mr='5px'/>,
      buttonTextSize: '18px',
      contentText: null
    }
  };

  return (
    <Card py='15px'>
      <Flex
        my='auto'
        h='100%'
        align={{ base: "center", xl: "start" }}
        justify={{ base: "center", xl: "center" }}>
        <Stat my='auto'>
          <StatLabel
            lineHeight='100%'
            color="secondaryGray.600"
            fontSize={{
              base: "15px",
              lg: "lg"
            }}>
            Available Balance
          </StatLabel>
          <StatNumber
            color={useColorModeValue("secondaryGray.900", "white")}
            fontSize={fontSizeControl}
            mt='3px'
            ml='6px'>
            {(accountAssetInfo.krcAmountHold).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',') || 0} KRDC
          </StatNumber>
          <Flex align='center'>
            <Text color='green.500' fontSize='xs' fontWeight='700' me='5px' ml='5px'>
              =
            </Text>
            <Text color='secondaryGray.600' fontSize='xs' fontWeight='400'>
              {(accountAssetInfo.krcAmountHold * 1000).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',') || 0} KRW
            </Text>
          </Flex>
        </Stat>
        <Flex ms='auto' w='max-content'>
        <ModalButton
          mt={modalButtonControl.mt}
          px='21px'
          buttonText = "Transfer"
          buttonTextSize={modalButtonControl.buttonTextSize}
          buttonIcon = {modalButtonControl.buttonIcon}
          buttonColor = "blue"
          buttonSize = 'md'
          modalType = "krcTransfer"
          contentText = {modalButtonControl.contentText} />
        </Flex>
      </Flex>
      </Card>
  );
}
