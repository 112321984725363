// React
import React from "react";
// External Libraries
import { HSeparator } from "components/separator/Separator";
import { ChakraProvider, Center, Flex, theme, Container, Stack, Text } from "@chakra-ui/react";

export default function SidebarConsoleHeader() {
  return (
    <Flex align='center' direction='column'>
      <ChakraProvider theme={theme}>
    <Container>
      <Stack>
        <Center>
          <Text as='samp' fontSize='2xl' pt="25px" pb="25px">VENETA ADMIN</Text>
        </Center>
      </Stack>
    </Container>
  </ChakraProvider>
      <HSeparator mb='20px' />
    </Flex>
  );
}
