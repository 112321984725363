// React
import React from 'react';
// External Libraries
import styled from "styled-components";
import PropTypes from 'prop-types'
import { useSelector } from "react-redux"
// Components
import ModalPortal from "components/modal/ModalPortal";

/**
 * 
 * Props list
 *  - modalContent: Refers to the .jsx file for Modal
 *  - modalType: Refers to a key value of the modalState variable from store.js
 * 
 */
export default function Modal ({ modalContent, modalType}) {
    // A varible to represent the state of each modal (from store.js)
    let modalState = useSelector((state) => { return state.modalState } );
  
    return (
        /**
         * 
         * ModalPortal: A component to open a separate react portal to mount the Modal
         * ModalOverlay, ModalWrapper, ModalInner: Styled components to control the outside/inside/wrapper CSS of the Modal
         * 
         */
        <ModalPortal>
            <ModalOverlay visible={modalState[modalType]}/>
            <ModalWrapper
                tabIndex="0"
                visible={modalState[modalType]}>
            <ModalInner tabIndex="1">
                {modalContent}
            </ModalInner>
            </ModalWrapper>
        </ModalPortal>
    );
    };

// Default props
Modal.defaultProps = {
    modalClosable: true,
    modalMaskClosable: true,
    visible: false
}

// Prop types
Modal.propTypes = {
    visible: PropTypes.bool,
}

const ModalWrapper = styled.div`
    box-sizing: border-box;
    display: ${(props) => (props.visible ? 'block' : 'none')};
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 1000;
    overflow: auto;
    outline: 0;
`

const ModalOverlay = styled.div`
    box-sizing: border-box;
    display: ${(props) => (props.visible ? 'block' : 'none')};
    position: fixed;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    background-color: rgba(0, 0, 0, 0.6);
    z-index: 999;
`

const ModalInner = styled.div`
    box-sizing: border-box;
    position: relative;
    box-shadow: 0 0 6px 0 rgba(0, 0, 0, 0.5);
    background-color: #fff;
    border-radius: 21px;
    width: 360px;
    max-width: 480px;
    top: 50%;
    transform: translateY(-50%);
    margin: 0 auto;
    padding: 0px 0px
`
  