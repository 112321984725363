// External Libraries
import * as RiIcons from 'react-icons/ri';
import { MdHomeFilled } from "react-icons/md"
import { Box, Flex, Stack } from "@chakra-ui/react";
// Components
import SidebarHeader from "components/sidebar/SidebarHeader";
import React from "react";
import SidebarElement from "./SidebarElement.js";

/**
 * 
 * Props list
 *  - routes: routes generated by index.js; contains the email and name info
 *  - onClose: props for children
 * 
 */
export default function SidebarContent({ routes, onClose }) {

  console.log('Sidebar Content > routes: ', routes);

  // A function variable to retrieve the name from the currently active route
  const getActiveRoute = (routes) => {
    let activeRoute = "Default";
    for (let i = 0; i < routes.length; i++) {
      if (routes[i].collapse) {
        let collapseActiveRoute = getActiveRoute(routes[i].items);
        if (collapseActiveRoute !== activeRoute) return collapseActiveRoute;
      } else if (routes[i].category) {
        let categoryActiveRoute = getActiveRoute(routes[i].items);
        if (categoryActiveRoute !== activeRoute) return categoryActiveRoute; 
      } else {
        if (window.location.href.indexOf(routes[i].layout + routes[i].path + routes[i].pathId) !== -1)
          return routes[i].name;
      }
    }
    return activeRoute;
  };
  
  // Variables for ForLoop
  var idxAList = 0;
  var idxCList = 0;
  var forend = 0;
  var Account_subNav=[];
  var Contact_subNav=[];
  var SidebarDataList=[];
  
  // To generate data for the account list and the contact list
  for(var i = 0; i < routes.length; i++){
    // If routes[i] has the path information, it is the element for AccountList
    if(routes[i].path !== undefined){
      Account_subNav[idxAList] = {
        title: routes[i].name,
        path: routes[i].layout+routes[i].path+routes[i].pathId,
        icon: <MdHomeFilled />,
        address: routes[i].account_address
      }
      idxAList += 1;       
    }
    // If ( routes[i].path === undefined ) && ( i !== 0 )
    // i.e., routes[i].path === undefined  :  ContactList element doesn't have the path info
    //       i !== 0  :  extract contact information only when the AccountList element exists
    else if (i !== 0) { 
      outer : for(var j =0; j< routes.length; j ++) {
        if( routes[j][Account_subNav[idxCList].title] !== undefined)
          Contact_subNav[routes[j][Account_subNav[idxCList].title][0].sourceName]
          = routes[j][Account_subNav[idxCList].title];
        if(j === routes.length -1 ) {
          j = 0;          
          if(idxCList === idxAList-1) break outer;
          else idxCList += 1;
        }
      }
    }
    forend += 1;    
  }

  // Define the data for sidebar only when the for loop ends
  if(forend === routes.length && routes.length !== 0){
    SidebarDataList = [
      {
        title: 'Account List',
        iconClosed: <RiIcons.RiArrowDownSFill />,
        iconOpened: <RiIcons.RiArrowUpSFill />,
    
        subNav: Account_subNav
      },
      {
        title: 'Contact List',
        iconClosed: <RiIcons.RiArrowDownSFill />,
        iconOpened: <RiIcons.RiArrowUpSFill />,
    
        subNav: Contact_subNav[getActiveRoute(routes)]
      }
    ]
  }

  return (
    <Flex direction='column' height='100%' pt='25px' borderRadius='30px'>
      <SidebarHeader />
      <Stack direction='column' mb='auto' mt='20px'>
        <Box ps='20px' pe={{ md: "16px", "2xl": "1px" }}>
          {SidebarDataList.map((item) => {
              return <SidebarElement onClose={onClose} item={item} />
          })}
        </Box>
      </Stack>
    </Flex>
  );
}
