// External Libraries
import { configureStore, createSlice } from '@reduxjs/toolkit'

// To control the mode depending on the screen size
let sizeMode = createSlice({
  name : 'sizeMode',
  initialState : {
    size: ''
  },
  reducers : {
    changeSizeMode(state, newMode){
      let keyInput = newMode.payload;
      state['size'] = keyInput;
    }
  }
})

// To control the modal state (krcBuy, krcTransfer, krcSell, krwInput)
let modalState = createSlice({
  name : 'modalState',
  initialState :
    {
      krcBuy: false,
      krcTransfer: false,
      krcSell: false,
      krwInput: false,
      krdcDepositAdmin: false,
      krwDepositAdmin: false,
    },
  reducers : {
    changeModalState(state, type){
      let keyInput = type.payload;
      state[keyInput] = !state[keyInput];
    }
  }
})

// To manage the account asset info
let accountAssetInfo = createSlice({
  name : 'accountAssetInfo',
  initialState :
    { 
      address: '',
      name: '',
      krcAmountHold: 0,
      krcAmountSent: 0,
      krwAmount: 0,
    },
    reducers : {
      changeAccountAssetInfo(state, input){
        if (input !== undefined) {
          let keyInput = input.payload["key"];
          let valInput = input.payload["value"];
          state[keyInput] = valInput;
        }
      }
  }
})

// Register states created in store.js
export default configureStore({
  reducer: {
    modalState: modalState.reducer,
    accountAssetInfo: accountAssetInfo.reducer,
    sizeMode: sizeMode.reducer
  }
})

// Export state change functions
export let { changeModalState } = modalState.actions
export let { changeAccountAssetInfo } = accountAssetInfo.actions
export let { changeSizeMode } = sizeMode.actions