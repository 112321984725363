
export const assetChartOptions = {
  labels: ["HOLD", "SENT"],
  colors: ["#4318FF", "#977FFF"],
  chart: {
    width: "70px",
    offsetY: "20",
  },
  states: {
    hover: {
      filter: {
        type: "none",
      },
    },
  },
  legend: {
    show: false,
  },
  dataLabels: {
    enabled: false,
  },
  hover: { mode: null },
  plotOptions: {
    donut: {
      expandOnClick: false,
      donut: {
        labels: {
          show: false,
        },
      },
    },
  },
  fill: {
    colors: ["#4318FF", "#977FFF"],
  },
  tooltip: {
    enabled: true,
    theme: "dark",
  },
};

export const assetChartData = [40000, 30000];