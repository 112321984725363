// React
import React, { useRef } from "react";
// External Libraries
import { useGlobalFilter, usePagination, useSortBy, useTable, useFilters } from "react-table";
import { CopyIcon } from '@chakra-ui/icons';
import { BsInfoCircle } from "react-icons/bs";
import { MdCheckCircle, MdCancel } from "react-icons/md";
import { Flex, Table, Icon, Tbody, Td, Text, Th, Thead, Tr, useColorModeValue, IconButton, Popover, PopoverTrigger, PopoverContent, PopoverHeader, PopoverBody, PopoverArrow, PopoverCloseButton, Center, } from "@chakra-ui/react";
// Components
import Card from "components/card/Card";
import TxnTableController from "./TxnTableController";

/**
 * 
 * Props list
 *  - pageNum, setPageNum: variables to control the page of TxnTable
 *  - columns, data: variables for contents of TxnTable
 *  - cFlag, dFlag: variables to control the contents TxnTable depending on the menu tab
 *    1) cFlag : String value; TxnTable will show all columns except for cFlag
 *    2) dFlag : Integer value; TxnTable will show all data except for dFlag index in each list
 * 
 */
export default function TxnTableContent({ pageNum, setPageNum, columns, data, cFlag, dFlag }) {
    
    // Variables to set a focus towards the controller and popover, respectively
    const controllerRef = useRef();
    const popoverRef = useRef();

    // Varaibles for TxnTableTest (react-table)
    const {
      getTableProps,
      getTableBodyProps,
      headerGroups,
      page,
      prepareRow,
      initialState,
      canPreviousPage,
      canNextPage,
      pageOptions,
      pageCount,
      gotoPage,
      nextPage,
      previousPage,
      setPageSize,
      state : {pageIndex, pageSize}
    } = useTable(
        { columns, data },
        useGlobalFilter,
        useSortBy,
        usePagination
    );
    initialState.pageSize = 10;
    
    // Variables for CSS Styling
    const textColor = useColorModeValue("secondaryGray.900", "white");
    const borderColor = useColorModeValue("gray.200", "whiteAlpha.100");
    
    // A varaible for the Copy&Paste functionality
    const handleClipBoard = async (text) => {
        try {
          await navigator.clipboard.writeText(text);
        } catch (error) {
          console.log(error);
        }
      };
    

    
    // Variables for the NoData case
    var noDataText = (dFlag === 0)? 'No Data Available' : '';
    var noDataMargin = (dFlag === 0)? '70px' : '0px';
    
    return (
        <Flex
          direction='column'
          justify='center'
          align='center'
          px='20px'>
           <Card
            backgroundColor='rgb(255, 255, 255)'
            w='100%'
            mt='40px'
            mb='5px'
            direction='column'
            overflowX={{ base: "scroll", sm: "scroll", lg: "scroll" }}>
                
            <Table variant='simple' color='gray.500' mb='0px' page={pageNum} onPageChange={(pageNum) => setPageNum(pageNum)}>
            <Thead>
                {headerGroups.map((headerGroup, index) => (
                <Tr {...headerGroup.getHeaderGroupProps()} key={index}>
                    {headerGroup.headers.map((column, index) => (
                    
                    // Exclude the column with the name "cFlag"
                    column.render("Header") !== cFlag
                    ? <Th
                        {...column.getHeaderProps(column.getSortByToggleProps())}
                        key={index}
                        borderColor={borderColor}>
                            <Flex
                                justify='center'
                                align='center'
                                fontSize={{ sm: "10px", lg: "12px" }}
                                color='gray.400'
                                mb='5px'>
                                {column.render("Header")}
                            </Flex>
                        </Th>
                    : null
                    ))}
                </Tr>
                ))}
            </Thead>
            <Tbody {...getTableBodyProps()}>
                {page.map((row, index) => {
                    prepareRow(row);
                        return (
                        <Tr {...row.getRowProps()} key={index}>
                            {row.cells.splice(0,dFlag).map((cell, index) => {
                            
                            // Variable for each <Td> in TxnTable
                            let data = "";
                            
                            if (cell.column.Header === "DATE") {
                                data = (
                                    <Text color={textColor} fontSize='sm' fontWeight='600' align='center'>
                                        {cell.value}
                                    </Text>
                                );
                            } else if (cell.column.Header === "TYPE") {
                                data = (
                                    <Text color={textColor} fontSize='sm' fontWeight='800' align='center'>
                                        {cell.value}
                                    </Text>
                                );
                            } else if (cell.column.Header === "ACCOUNT") {
                                
                                // Variables to separate address and contact information from account value
                                const idxAccount = cell.value.indexOf('^');
                                const address = cell.value.slice(0,idxAccount);
                                const contact = cell.value.slice(idxAccount+1);
                                data = (
                                    <Flex
                                    w='100%'
                                    h='100%'
                                    mb='0px'
                                    pb='10px'
                                    flexDirection="row"
                                    justifyContent ='center'
                                    alignItems='center'>
                                        <Text color={ textColor} fontSize='sm' fontWeight='600' align='center'>
                                            {contact}
                                        </Text>
                                        <Popover
                                            initialFocusRef={popoverRef}
                                            orientation='horizontal'
                                            placement='right'
                                            closeOnBlur={true}
                                            closeOnEsc={true}
                                            >
                                            <PopoverTrigger>
                                                <IconButton
                                                    aria-label='More Address Info'
                                                    icon={<Center w='75%' h='100%'><BsInfoCircle color="gray.500"/></Center>}
                                                    p='0px'
                                                    isActive={false}
                                                    variant='unstyled'
                                                    size='sm'
                                                    isRound = {false}/>
                                            </PopoverTrigger>
                                            <PopoverContent color='white' bg='blue.800' borderColor='blue.800' marginLeft='30px' width='290px'>
                                                <PopoverHeader pt={4} fontSize='18px' fontWeight='300' border='0' pl='20px'>
                                                    {contact}
                                                    <IconButton
                                                    aria-label='More Address Info'
                                                    icon={<Center w='80%' h='100%' pb={1}><CopyIcon color="white"/></Center>}
                                                    onClick={()=>{handleClipBoard(address)}}
                                                    p='0px'
                                                    isActive={false}
                                                    variant='unstyled'
                                                    size='sm'
                                                    isRound = {false} />
                                                </PopoverHeader>
                                                <PopoverArrow />
                                                <PopoverCloseButton />
                                                <PopoverBody fontWeight='200'fontSize='md' pl='20px' pb='20px'>
                                                    <Text w='90%'>{address}</Text>
                                                </PopoverBody>
                                            </PopoverContent>
                                        </Popover>
                                    </Flex>
                                );
                            
                            } else if (cell.column.Header === "REMARK") {
                                data = (
                                    <Text color={textColor} fontSize='sm' fontWeight='600' align='center'>
                                        {cell.value}
                                    </Text>
                                );
                            }
                            else if (cell.column.Header === "AMOUNT") {
                                data = (
                                    <Text color={ cell.value.charAt(0) === '-'
                                                    ? "blue.500"
                                                    : "red.500"
                                        } fontSize='sm' fontWeight='600' align='center'>
                                        {cell.value}
                                    </Text>
                                );
                
                            } else if (cell.column.Header === "STATUS") {
                                data = (
                                <Flex align='center'>
                                    <Icon
                                    w='24px'
                                    h='24px'
                                    me='5px'
                                    align='center'
                                     color={
                                         cell.value === "OK"
                                         ? "green.500"
                                         : cell.value === "FAIL"
                                         ? "red.500"
                                         : null
                                        }
                                        as={
                                            cell.value === "OK"
                                            ? MdCheckCircle
                                            : cell.value === "FAIL"
                                            ? MdCancel
                                            : null
                                        }

                                    />
                                    <Text color={textColor} fontSize='sm' fontWeight='700' align='center'>
                                    {cell.value}
                                    </Text>
                                </Flex>
                                );
                            } else if (cell.column.Header === "BALANCE") {
                                data = (
                                <Text 
                                color={ textColor } 
                                fontSize='sm' fontWeight='600' align='center'>
                                    {cell.value}
                                </Text>
                                );
                                
                            }
                            return (
                                <Td
                                    {...cell.getCellProps()}
                                    key={index}
                                    fontSize={{ sm: "14px" }}
                                    maxH='30px !important'
                                    py='15px'
                                    minW={{ sm: "150px", md: "200px", lg: "auto" }}
                                    borderColor='transparent'>
                                    {data}
                                </Td>
                            );
                        })} 
                        </Tr>
                        )}
                    )}
                </Tbody>
            </Table>
            <Text color={textColor} fontSize='xl' fontWeight='500' align='center' justify='center' my={noDataMargin}>
                {noDataText}
            </Text>
            <br />
            <TxnTableController
                pageCount={pageCount}
                gotoPage={gotoPage}
                previousPage={previousPage}
                pageIndex={pageIndex}
                nextPage={nextPage}
                canPreviousPage={canPreviousPage}
                canNextPage={canNextPage}
                pageOptions={pageOptions}
                initialFocusRef={controllerRef}
                pageSize={pageSize}
                setPageSize={setPageSize} />
        </Card>
       
    </Flex>
    );
}