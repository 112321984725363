// React
import React, {useState, useEffect} from "react";
// External Libraries
import axios from 'axios';
import { ChevronDownIcon } from '@chakra-ui/icons';
import { useDispatch, useSelector } from "react-redux"
import { Button, Flex, Menu, MenuButton, MenuItem, MenuList, Text, useColorModeValue } from "@chakra-ui/react";
// Components
import SidebarResponsive from "components/sidebar/SidebarResponsive";
// Variables
import { changeModalState } from "../../store"

/**
 * 
 * Props list
 *  - routes: routes generated by index.js; contains the email and name info
 *  - accountName: the accout name of the address; only for the mobile mode
 * 
 */
export default function NavbarLinks({ routes, accountName }) {

  // A variable to use function variables from store.js
  const dispatch = useDispatch();

  // Variables to set the mode depending on the screen size
  const [mode, setMode] = useState('');
  let sizeMode = useSelector((state) => { return state.sizeMode } );
  
  // Variables for CSS Styling
  let menuBg = useColorModeValue("white", "navy.800");
  const textColor = useColorModeValue("secondaryGray.900", "white");
  const borderColor = useColorModeValue("#E6ECFA", "rgba(135, 140, 189, 0.3)");
  const shadow = useColorModeValue(
    "14px 17px 40px 4px rgba(112, 144, 176, 0.18)",
    "14px 17px 40px 4px rgba(112, 144, 176, 0.06)"
  );
  
  // A function variable for the logout functionality
  function logout(){
    axios.get('/logout').then((response) => {
        window.location.replace("/");    
      })
  }

  // To reflect the change in the mode (depending on the screen size)
  useEffect(()=>{
    setMode(sizeMode.size);
  },[sizeMode.size]);

  return (
    <Flex
      w={{ sm: "100%", md: "auto" }}
      alignItems='center'
      flexDirection='row'
      bg={menuBg}
      flexWrap="unset"
      borderRadius='20px'
      boxShadow={shadow}
      padding='5px'
      pl='10px'
      justify='center'>
      
      <Menu>
        <SidebarResponsive routes={routes} me='5px'/>
        <MenuButton as={Button} me='5px' ml='10px'>
          <Flex direction='row'overflow='hidden' align='center'>
            <Text fontSize={{ base: '10.0px', md: '15px', lg: '17px' }} mr='2px'>KRW</Text>
            <ChevronDownIcon mt='2px'/>
          </Flex>
        </MenuButton>
        <MenuList >
          <MenuItem minH='40px' onClick = {()=>{
             // If '예치하기' button is clicked, change the krwInput Modal state
              dispatch(changeModalState('krwInput'));
            }}>
            <Text
                fontFamily = "DM Sans"
                fontSize='ms'
                fontWeight='500'
                color={textColor}
                ps='20px'
                pb='5px'
                w='100%'
                borderBottom='1px solid'
                borderColor={borderColor}>
                  예치하기
              </Text>
          </MenuItem>
        </MenuList>
      </Menu>

      <Menu>
        <MenuButton as={Button} me='5px'>
        <Flex direction='row'overflow='hidden' align='center'>
            <Text fontSize={{ base: '10.0px', md: '15px', lg: '17px' }} mr='2px'>KRDC</Text>
            <ChevronDownIcon mt='3px' mr='5px'/>
          </Flex>
        </MenuButton>
        <MenuList>
          <MenuItem minH='48px'  onClick= {()=>{
              // If '구매하기' button is clicked, change the krcBuy Modal state
              dispatch(changeModalState('krcBuy'));
            }}>
            <Text
                fontFamily = "DM Sans"
                fontSize='ms'
                fontWeight='500'
                color={textColor}
                ps='20px'
                pb='5px'
                w='100%'
                borderBottom='1px solid'
                borderColor={borderColor}>
                  구매하기
              </Text>
          </MenuItem>
          <MenuItem minH='40px' onClick= {()=>{
              // If '송금하기' button is clicked, change the krcTransfer Modal state
              dispatch(changeModalState('krcTransfer'));
            }
            }> 
            <Text
                fontFamily = "DM Sans"
                fontSize='ms'
                fontWeight='500'
                color={textColor}
                ps='20px'
                pb='5px'
                w='100%'
                borderBottom='1px solid'
                borderColor={borderColor}>
                  송금하기
              </Text>
          </MenuItem>
          <MenuItem minH='40px' onClick= {()=>{
             // If '상환하기' button is clicked, change the krcSell Modal state
              dispatch(changeModalState('krcSell'));
            }
            }>
            <Text
                fontFamily = "DM Sans"
                fontSize='ms'
                fontWeight='500'
                color={textColor}
                ps='20px'
                pb='5px'
                w='100%'
                borderBottom='1px solid'
                borderColor={borderColor}>
                  상환하기
              </Text>
          </MenuItem>
        </MenuList>
      </Menu>
      
      <Menu>
        <MenuButton
          as={Button}
          me='5px'
          color="rgb(90, 81, 245)"
        >
        <Flex direction='row'overflow='hidden' align='center' >
            <Text fontSize={{ base: '11px', md: '15px', lg: '17px' }} mr='5px' fontWeight='700'>
              {
                routes !== undefined && routes.length > 0
                ? // routes !== undefined && routes.length > 0
                  mode === 'pc'
                  ? routes[0].email // routes !== undefined && routes.length > 0 && mode === 'pc'
                  : // routes !== undefined && routes.length > 0 && mode !== 'pc'
                    mode === 'mobile'
                    ? accountName // routes !== undefined && routes.length > 0 && mode === 'mobile'
                    : null // routes !== undefined && routes.length > 0 && mode !== 'pc' && mode !== 'mobile'
                : "error" // routes === undefined || routes.length <=> 0
              }
            </Text>
          </Flex>
        </MenuButton>
        <MenuList
          boxShadow={shadow}
          p='0px'
          mt='10px'
          borderRadius='20px'
          bg={menuBg}
          border='none'>
          <Flex w='100%' mb='0px'>
            <Text
              ps='20px'
              pt='16px'
              pb='10px'
              w='100%'
              borderBottom='1px solid'
              borderColor={borderColor}
              fontSize='sm'
              fontWeight='700'
              color={textColor}>
              🔒&nbsp; Hey,  &nbsp;
              {
                routes !== undefined && routes.length > 0
                ? routes[0].name
                : "error"
              }
            </Text>
          </Flex>
          <Flex flexDirection='column' p='10px'>
            <MenuItem
              _hover={{ bg: "none" }}
              _focus={{ bg: "none" }}
              color='red.400'
              borderRadius='8px'
              px='14px'>
              <Text fontSize='sm' fontWeight="600" onClick={logout}>Log out</Text>
            </MenuItem>
          </Flex>
        </MenuList>
      </Menu>
    </Flex>
  );
}
