// React
import React, {useState} from "react";
// External Libraries
import axios from 'axios';
import { NavLink,useHistory } from "react-router-dom";
import {  MdOutlineRemoveRedEye } from "react-icons/md";
import { RiEyeCloseLine } from "react-icons/ri";
import { Box, Button, Flex, FormControl, FormLabel, Heading, Icon, Input,InputGroup,InputRightElement,Text,useColorModeValue,} from "@chakra-ui/react";
// Components
import DefaultLogin from "layouts/login/Default";
// Assets
import illustration from "assets/img/login/auth.png";

/**
 * 
 * Props list - none
 */
 export default function SignIn() {

  // style variables
  const textColor = useColorModeValue("navy.700", "white");
  const textColorSecondary = "gray.400";
  const textColorDetails = useColorModeValue("navy.700", "secondaryGray.600");
  const textColorBrand = useColorModeValue("brand.500", "white");
  const brandStars = useColorModeValue("brand.500", "brand.400");

  // input data passsword view variables
  const [show, setShow] = React.useState(false);
  const handleClick = () => setShow(!show);

  // input data variables
  const [inputs, setInputs] = useState({
    email: "",
    password: "",
  });
  const { email, password } = inputs;

  // When the user enters data, the value is changed to the entered value.      
  function onChange(e) {
    const { value, name } = e.target;
    setInputs({
      ...inputs,
      [name]: value,
    });

  }
  // route Change variables
  const history = useHistory();

  // async api call
  async function handleSubmit(e) {  
    
    e.preventDefault(); 

    // server api call
    axios.post('/login',{
      email : email,
      pwd : password
    }).then((response) => {                       // api return
      if(response.data === "user"){                // api success
        history.push('/main');                    // change route(user view)
      }else if (response.data === "admin"){
        history.push('/console'); 
      }else if (response.data === "email authentication"){
        alert("이메일 인증을 하지 않으셨습니다.");
      }else if (response.data === "login fail"){
        alert("이메일과 패스워드가 일치하지 않습니다.");
      } // if else end
    })  
    .catch(function(){                            // api call failed
      console.log("실패..")
    })   
  }// fn handleSubmit end
    
  return (
    <DefaultLogin illustrationBackground={illustration} image={illustration}>
      <Flex
        maxW={{ base: "100%", md: "max-content" }}
        w='100%'
        mx={{ base: "auto", lg: "0px" }}
        me='auto'
        h='100%'
        alignItems='start'
        justifyContent='center'
        mb={{ base: "30px", md: "60px" }}
        px={{ base: "25px", md: "0px" }}
        mt={{ base: "40px", md: "14vh" }}
        flexDirection='column'>
                  <Box me='auto' border={1}>

       



<Heading color={textColorBrand} fontSize='25px' mb='10px' >
  VENETA ACCOUNT 테스트넷으로 
</Heading>
<Heading color={textColorBrand} fontSize='25px' mb='10px' >
 ACCOUNT 간의 송금 테스트를  체험할 수 있습니다.
</Heading>
<Heading color={textColorBrand} fontSize='25px' mb='10px' >

</Heading>
<Text
  mb='36px'
  ms='4px'
  color={textColorSecondary}
  fontWeight='400'
  fontSize='md'>* VENETA Multi-Issuer 테스트넷, Docs 사이트 오픈 예정
</Text>
</Box>
        <Box me='auto'>

       



          <Heading color={textColor} fontSize='36px' mb='10px' >
            Sign In
          </Heading>
          <Text
            mb='36px'
            ms='4px'
            color={textColorSecondary}
            fontWeight='400'
            fontSize='md'>
              Enter your email and password to sign in!
          </Text>
        </Box>
        <Flex
          zIndex='1'
          direction='column'
          w={{ base: "100%", md: "420px" }}
          maxW='100%'
          background='transparent'
          borderRadius='15px'
          mx={{ base: "auto", lg: "unset" }}
          me='auto'
          mb={{ base: "20px", md: "auto" }}>
          <form onSubmit={handleSubmit}>
            <FormControl>             
              <FormLabel
                display='flex'
                ms='4px'
                fontSize='sm'
                fontWeight='500'
                color={textColor}
                mb='8px'>
                  Email <Text color={brandStars}>*</Text>
              </FormLabel>
              <Input
                isRequired={true}
                variant='auth'
                fontSize='sm'
                ms={{ base: "0px", md: "0px" }}
                type='text'
                placeholder='test@veneta.io'
                mb='24px'
                fontWeight='500'
                size='lg'
                id="email"
                name="email"
                value={email}
                onChange={onChange}/>
              <FormLabel
                ms='4px'
                fontSize='sm'
                fontWeight='500'
                color={textColor}
                display='flex'>
                  Password< Text color={brandStars}>*</Text>
              </FormLabel>
              <InputGroup size='md'>
                <Input
                  isRequired={true}
                  fontSize='sm'
                  placeholder='Min. 8 characters'
                  mb='24px'
                  size='lg'
                  type={show ? "text" : "password"}
                  variant='auth'
                  id="password"
                  name="password"
                  value={password}
                  onChange={onChange}/>
                <InputRightElement display='flex' alignItems='center' mt='4px'>
                  <Icon
                    color={textColorSecondary}
                    _hover={{ cursor: "pointer" }}
                    as={show ? RiEyeCloseLine : MdOutlineRemoveRedEye}
                    onClick={handleClick}/>
                </InputRightElement>
              </InputGroup>
              <Button
                fontSize='sm'
                variant='brand'
                fontWeight='500'
                w='100%'
                h='50'
                mb='24px'
                type="submit">
                Sign In
              </Button>
            </FormControl>
          </form>
          <Flex
            flexDirection='column'
            justifyContent='center'
            alignItems='start'
            maxW='100%'
            mt='0px'>
            <Text color={textColorDetails} fontWeight='400' fontSize='14px'>
              Not registered yet?
                <NavLink to='/auth/sign-up'>
                  <Text
                    color={textColorBrand}
                    as='span'
                    ms='5px'
                    fontWeight='500'>
                      Sign Up
                  </Text>
                </NavLink>
            </Text>
          </Flex>
        </Flex>        
      </Flex>
    </DefaultLogin>
  );
}


