// External Libraries
import React from 'react';
import { InputGroup, Input, InputLeftAddon } from "@chakra-ui/react";


import $ from "jquery";
window.$ = $;

export default function DefaultColumnFilter ({
    column: {
      Header,
      filterValue,
      setFilter,
      preFilteredRows: { length },
    },
  }) {

  return (
    <InputGroup mb='15px'>
      <InputLeftAddon children={Header} bg='transparent' fontWeight='400'/>
      <Input
        value={filterValue || ""}
        onChange={e => {
          setFilter(e.target.value || undefined)
        }}
        placeholder={`Enter the ${Header}`}
        color='white'
        />
    </InputGroup>
  )
}