import React, { useEffect, useRef, useState } from "react";
import { useAsyncDebounce } from 'react-table';
import { Flex } from "@chakra-ui/react";
import {
  Container,
  SearchBox,
  IconRightArrow,
  IconMagnifyingGlass
} from "./stylesFilterSearchbar";

function Search({
    globalFilter,
    setGlobalFilter,
    popover
  }) {
  const targetRef = useRef(null);
  const [isHovered, setIsHovered] = useState(false);
  const [isFocused, setIsFocused] = useState(false);
  const showSearchInput = isHovered || isFocused;

  const [value, setValue] = useState(globalFilter);
  const onChange = useAsyncDebounce(value => {
    setGlobalFilter(value || undefined)
  }, 200);

  useEffect(() => {
    targetRef.current.value = "";
  }, [showSearchInput]);

  return (
    <Container
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
      onFocus={() => setIsFocused(true)}
      onBlur={() => setIsFocused(false)}
      hover={showSearchInput}>
      <SearchBox
        ref={targetRef}
        showSearchInput={showSearchInput}
        onChange={ (e) => {
          setValue(e.target.value);
          onChange(e.target.value);
        }}/>
      
      { showSearchInput ? <IconRightArrow /> : <IconMagnifyingGlass /> }
    </Container>
  );
}

export default Search;
