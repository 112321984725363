// React
import React from "react";
// External Libraries
import ReactApexChart from "react-apexcharts";

/**
 * 
 * Props list
 *  - chartData: Refers to the data for the chart
 *  - chartOptions: Refers to the styling options for the chart
 * 
 */
export default function DonutChart({chartData , chartOptions})  {
  return (
    <>
      {
        chartData[0] === 0 && chartData[1] === 0
        ? <text><br/>NO DATA</text>
        : <ReactApexChart
            options={chartOptions}
            series={chartData}
            type='donut'
            width='100%'
            height='55%'/>
      }
    </>
  );
}

