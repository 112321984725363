// React
import React from "react";
// External Libraries
import { HSeparator } from "components/separator/Separator";
import { ChakraProvider, Center, Flex, theme, Container, Stack, Text } from "@chakra-ui/react";

export default function SidebarHeader() {
  return (
    <Flex align='center' direction='column'>
      <ChakraProvider theme={theme}>
    <Container>
      <Stack>
        <Center>
          <Text as='samp' fontSize='2xl' pt="20px" pb="25px" > <strong> VENETA   ACCOUNT<br/> </strong><Text as='samp' fontSize='2xl'>TESTNET</Text> </Text>

        </Center>
      </Stack>
    </Container>
  </ChakraProvider>
      <HSeparator mb='20px' />
    </Flex>
  );
}
