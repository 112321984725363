// React
import React from "react";
// External Libraries
import { useSelector } from "react-redux"
import { Text, useColorModeValue, Spacer } from "@chakra-ui/react";
// Components
import Card from "components/card/Card.js";
import ChartLabel from "components/charts/ChartLabel";
import PieChart from "components/charts/PieChart"
// Variables
import { assetChartOptions } from "variables/assetChart/assetTotal";

/**
 * 
 * Props list
 *  - title: string for the chart title
 * 
 */
export default function AssetChartLeft({ title }) {
  // A varible to represent current account info (from store.js)
  let accountAssetInfo = useSelector((state) => { return state.accountAssetInfo } );
  // Variables for the PieChart
  const assetChartData = [accountAssetInfo.krcAmountHold * 1000, accountAssetInfo.krwAmount];
  const sum = accountAssetInfo.krcAmountHold * 1000 + accountAssetInfo.krwAmount;
  
  return (
    <Card align='center' direction='column' w='100%' h='100%' justify='center' p='0px'>
      <Text color={useColorModeValue("secondaryGray.900", "white")} fontSize='lg' fontWeight='600' pt='23px'>
        {title}
      </Text>
      <PieChart
        h='100%'
        w='100%'
        chartData={assetChartData}
        chartOptions={assetChartOptions}
        align='center'
        justify='center' />
      <Card
        bg='{cardColor}'
        flexDirection='row'
        w='100%'
        my='20px'
        py='10px'>
        <Spacer />
        { assetChartOptions.labels.map((item,index)=>{
          return <ChartLabel label={item} data={assetChartData[index]} sum={sum} color={assetChartOptions.colors[index]}/>;
        })}
        <Spacer />
      
      </Card>
    </Card>
  );
// }
}
