// React
import React, { useEffect,useState } from "react";
// External Libraries
import axios from 'axios';
import { MdContactPage } from "react-icons/md";
import { Redirect, Route, Switch, useHistory } from "react-router-dom";
import { Portal, Box, useDisclosure } from "@chakra-ui/react";
// Components
import Navbar from "components/navbar/Navbar.js";
import Sidebar from "components/sidebar/Sidebar.js";
import Footer from "components/footer/Footer.js";
import MainDashboard from "views/service";
// Contexts
import { SidebarContext } from "views/service/contexts/SidebarContext";

/**
 * 
 * Props list
 * - rest: for children
 */
export default function Dashboard({ ...rest }) {
  


  // A variable for the navigation functionality
  const history = useHistory();
  // Variables to check whether the login is successfully done
  const [loginCheck, setLoginCheck ] = useState("");
  // Variables for routes
  const [routes, setRoutes] = useState([]);
  var addroutes = [];
  // states and functions
  const [toggleSidebar, setToggleSidebar] = useState(false);
  // A variable for useDisclosure()
  const { onOpen } = useDisclosure();

  // A function variable for logout functionality
  function logout(){
    axios.get('/logout').then((response) => {
        window.location.replace("/");    
    })
  }

  // Function variables for routes

  const getRoute = () => {
    return window.location.pathname !== "/main/full-screen-maps";
  };

  const getActiveRoute = (routes) => {
    let activeRoute = "Default";
    if(routes.length>0 && loginCheck.check) activeRoute = routes[1].path+routes[1].pathId;
    for (let i = 0; i < routes.length; i++) {
      if (routes[i].collapse) {
        let collapseActiveRoute = getActiveRoute(routes[i].items);
        if (collapseActiveRoute !== activeRoute) return collapseActiveRoute;
      } else if (routes[i].category) {
        let categoryActiveRoute = getActiveRoute(routes[i].items);
        if (categoryActiveRoute !== activeRoute) return categoryActiveRoute;
      } else {
        if (
          window.location.href.indexOf(routes[i].layout + routes[i].path + routes[i].pathId) !== -1
        ) return routes[i].name;
      }
    }
    return activeRoute;
  };

  const getRoutes = (routes) => {
    return routes.map((prop, key) => {
      if (prop.layout === "/main") {
        return (
          <Route
          path={prop.layout + prop.path + prop.pathId}
          key={key}
          render={() => <prop.component routes={routes}/>}/>            
        );
      }
      if (prop.collapse) {
        return getRoutes(prop.items);
      }
      if (prop.category) {
        return getRoutes(prop.items);
      } else {
        return null;
      }
    });
  };

  // Function variables for Navbar

  const getActiveNavbar = (routes) => {
    let activeNavbar = false;
    for (let i = 0; i < routes.length; i++) {
      if (routes[i].collapse) {
        let collapseActiveNavbar = getActiveNavbar(routes[i].items);
        if (collapseActiveNavbar !== activeNavbar) {
          return collapseActiveNavbar;
        }
      } else if (routes[i].category) {
        let categoryActiveNavbar = getActiveNavbar(routes[i].items);
        if (categoryActiveNavbar !== activeNavbar) {
          return categoryActiveNavbar;
        }
      } else {
        if (
          window.location.href.indexOf(routes[i].layout + routes[i].path) !== -1
          ) {
            return routes[i].secondary;
          }
        }
      }
      return activeNavbar;
    };

    const getActiveNavbarText = (routes) => {
      let activeNavbar = false;
      for (let i = 0; i < routes.length; i++) {
        if (routes[i].collapse) {
          let collapseActiveNavbar = getActiveNavbarText(routes[i].items);
          if (collapseActiveNavbar !== activeNavbar) {
            return collapseActiveNavbar;
          }
        } else if (routes[i].category) {
          let categoryActiveNavbar = getActiveNavbarText(routes[i].items);
          if (categoryActiveNavbar !== activeNavbar) {
            return categoryActiveNavbar;
          }
        } else {
          if (
            window.location.href.indexOf(routes[i].layout + routes[i].path) !== -1
            ) {
              return routes[i].messageNavbar;
            }
          }
        }
        return activeNavbar;
      };

    // Layout direction (language)
    document.documentElement.dir = "ltr";
    
    // To redirect
    useEffect(() => {
      fetch('/mainDashboard')
      .then(message => message.json())   
      .then(message => {
        setLoginCheck(message.loginCheck);
        if(message.errorCheck.putType === 'error'){
          history.push(
            {
              pathname: '/auth/error',
              state: {errorCheck : message.errorCheck} 
            });
          }else if(message.loginCheck.useremail === 'admin@veneta' ){       // api return data = error => server return error code
            alert("잘못된 접근 입니다. ");
            history.push('/console');
          } // if else end
      })
    },[]);
        
    // To get user info
    useEffect(() => {
      if(loginCheck.useremail ===  'admin@veneta' ){              // admin login true
        history.push('/console');                                // view console page 
      }else if(loginCheck.check){  
        fetch('/get_user_info')
        .then(response => response.text())
        .then(message => {
          message = JSON.parse(message);
          if(message.userInfo === undefined || message.userAccountList === undefined || message.accountContactList === undefined){
            
            logout();
          }else{
            var firstObj = {};
            //userInfo 
            addroutes.push({
              email:message.userInfo.email,
              pwd:"************",
              name:message.userInfo.name
            },);
                  
            // userInfo 의 account list 의 length 만큼 반복   
            for(var i=0; i < message.userAccountList.length; i++){
              //user 별 accountInfo
              addroutes.push({
                name: message.userAccountList[i].account_name,
                layout: "/main",
                path: "/accountInfo",
                pathId: "/"+message.userAccountList[i].account_name,
                icon: <MdContactPage width='20px' height='20px' color='inherit' />,
                component: MainDashboard,
                account_address: message.userAccountList[i].account_address
              },); 
              //accountInfo 별 contact key 설정
              firstObj[message.userAccountList[i].account_name]=[];
            };   
            //userInfo의  contact list 만큼 반복
            for(var i=0; i < message.accountContactList.length; i++){
              // accountInfo 의 contact list 의 length 만큼 반복   
              for(var j=0; j < message.accountContactList[i].length; j++){
                //accountInfo 별 contact list 의 Object List 지정
                var forValueList = firstObj[message.userAccountList[i].account_name];            
                // accountInfo 의 account_name 이 key 가 된 Object 에 
                //  해당 contact list 를 push
                
                forValueList.push({
                  title: message.accountContactList[i][j].contact_name,
                  sourceName : message.userAccountList[i].account_name,
                  cName: message.accountContactList[i][j].contact_address,
                  icon: <MdContactPage width='15px' height='15px' color='inherit' />,
                })
                
                firstObj[message.userAccountList[i].account_name] =forValueList
                }          
              }
            addroutes.push(firstObj);   
          }
      
        }).then(()=>{
          setRoutes(addroutes);
        });
      } else if(loginCheck !== '' && loginCheck !== undefined){
        history.push("/auth");
      }
    },[loginCheck]);

    // Return statements
    
    if(!routes.length > 0 ) return null;
    else {
      var aaa;
      if (routes.length !== 0 ) {
        return (
          <Box>
            <SidebarContext.Provider value={{ toggleSidebar, setToggleSidebar}}>
              <Sidebar routes={routes} display='none' {...rest} />
              <Box
                float='right'
                minHeight='100vh'
                height='100%'
                overflow='auto'
                position='relative'
                maxHeight='100%'
                w={{ base: "100%", xl: "calc( 100% - 290px )" }}
                maxWidth={{ base: "100%", xl: "calc( 100% - 290px )" }}
                transition='all 0.33s cubic-bezier(0.685, 0.0473, 0.346, 1)'
                transitionDuration='.2s, .2s, .35s'
                transitionProperty='top, bottom, width'
                transitionTimingFunction='linear, linear, ease'>
                <Portal>
                  <Box>
                    <Navbar
                      onOpen={onOpen}
                      routes={routes}/>
                  </Box>
                </Portal>
                {
                  getRoute()
                  ? // getRoute() === true
                  (
                    <Box
                      mx='auto'
                      p={{ base: "20px", md: "30px" }}
                      pe='20px'
                      minH='100vh'
                      pt='50px'>
                      <Switch>
                        {
                          getActiveRoute(routes).includes('/accountInfo')
                          ? aaa = '/main'+getActiveRoute(routes)
                          : aaa = '/main/defult'
                        }
                        {getRoutes(routes)}
                        <Redirect from='/' to={aaa} routes={routes}/>
                      </Switch>
                    </Box>
                  )
                  : //getRoute() === false
                    null
                }
                <Box>
                  <Footer />
                </Box>
              </Box>
              </SidebarContext.Provider>
            </Box>
        );
    } else { // routes.length === 0
      return(<></>);
    }
  }
}
