// React
import React, {useState, useEffect, useRef} from "react";
// External Libraries
import axios from 'axios';
import { useDispatch } from "react-redux";
import { Box, SimpleGrid, useDimensions } from "@chakra-ui/react";
// Components
import AssetCard from "views/service/components/AssetCard";
import AssetChart from "views/service/components/AssetChart";
import AssetTable from "views/service/components/AssetTable";
import AssetTableMobile from "views/service/components/AssetTableMobile";
import TxnTable from "views/service/components/TxnTable";
import TxnCardGroup from "views/service/components/TxnCardGroup";
import KrcSell from "variables/modal/KrcSell";
import Modal from "components/modal/Modal";
// Variables
import { colDataComplex } from "variables/assetTable/assetTableColumn.js";
import { changeAccountAssetInfo, changeSizeMode } from "../../store"

export default function MainDashboard({ routes }) {
  
  // A variable to use function variables from store.js
  const dispatch = useDispatch();
  
  // Variables to check the return from transactions
  const [checkAxios , setCheckAxios] = useState(false);
  
  // Variables to check the current window size (mobile/pc)
  const mainRef = useRef();
  const dimensions = useDimensions(mainRef, true);
  const [size, setSize] = useState('');
  
  // Variables to set the data for children components
  const [txnTableData, setTxnTableData] = useState([]);
  const [accountContactList,setAccountContactList] = useState([]);
  const [assetChartData, setAssetChartData] = useState([]);
  const [assetTableData, setAssetTableData] = useState([]); 
  const sum = assetChartData.reduce((partialSum, a) => partialSum + a, 0);
  
    // A function variable to get the current route info
    const getActiveRoute = (routes) => {
      let activeRoute = "Default";
      if(routes.length>0 ){        
        activeRoute = routes[1].path+routes[1].pathId;        
      }
      for (let i = 0; i < routes.length; i++) {
        if (routes[i].collapse) {
          let collapseActiveRoute = getActiveRoute(routes[i].items);
          if (collapseActiveRoute !== activeRoute) {
            return collapseActiveRoute;
          }
        } else if (routes[i].category) {
          let categoryActiveRoute = getActiveRoute(routes[i].items);
          if (categoryActiveRoute !== activeRoute) {
            return categoryActiveRoute;
          }
        } else {
          if (
            window.location.href.indexOf(routes[i].layout + routes[i].path + routes[i].pathId) !== -1
          ) {
            return routes[i].name;
          }
        }
      }
      return activeRoute;
    };

    const getAccountAddressRoute = (routes) => {
      let activeRoute = "Default";
      for (let i = 0; i < routes.length; i++) {
        if (routes[i].collapse) {
          let collapseActiveRoute = getActiveRoute(routes[i].items);
          if (collapseActiveRoute !== activeRoute) {
            return collapseActiveRoute;
          }
        } else if (routes[i].category) {
          let categoryActiveRoute = getActiveRoute(routes[i].items);
          if (categoryActiveRoute !== activeRoute) {
            return categoryActiveRoute;
          }
        } else {
          if (
            window.location.href.indexOf(routes[i].layout + routes[i].path + routes[i].pathId) !== -1
          ) {
            return routes[i].account_address;
          }
        }
      }
      return activeRoute;
    };

  // A function variable for the logout functionality
  function logout(){
    axios.get('/logout').then((response) => {
        window.location.replace("/");    
      })
  }

  // Switch the pc/mobile mode for AssetTable/AssetTableMobile & TxnTable/TxnCardsGroup
  useEffect(() => {
    if (dimensions) {
      let width = dimensions.borderBox.width;
      switch (true) {
        case width < 1024:
          setSize('mobile');
          break;
        case width >= 1024:
          setSize('pc');
          break;
      }
    }
  }, [dimensions]);
  
  // Switch the pc/mobile mode for Navbar
  useEffect(() => {
    if (dimensions) {
      let width = dimensions.borderBox.width;
      switch (true) {
        case width < 520:
          dispatch(changeSizeMode('mobile'));
          break;
        case width >= 520:
          dispatch(changeSizeMode('pc'));
          break;
      }
    }
  }, [dimensions]);

  // Update the contact list
  useEffect(() => {
    if(routes.length > 0){    
      for(var i = 0; i < routes.length; i++){
        if(routes[i].name == undefined && routes[i].email == undefined ){
          setAccountContactList(routes[i][getActiveRoute(routes)]);
        }
      }
    }
  },[routes]);

  // Update the transaction information
  useEffect(() => {
    fetch('/get_transaction_info?account_address='+getAccountAddressRoute(routes))
    .then(response => response.text())
    .then(message => {
      message = JSON.parse(message);

      // A variable to store the fetched txn data
      var addTrans = [];
      
      if( message.accountTransactionList === undefined ){
        
        logout();
      } else { // message.accountTransactionList !== undefined
        
        // Variables to retrieve current user info from routes
        var forAccountAddress = getAccountAddressRoute(routes);
        var forAccountAddress_name = getActiveRoute(routes);
        
        for (var i = 0; i < message.accountTransactionList.length; i++) {
          
          // Variables for specific data for each transaction
          var amount, balance, account, date, contact, viewAccount, tradeType;

          if(forAccountAddress === message.accountTransactionList[i].target_account_address){
            viewAccount = message.accountTransactionList[i].source_account_address;
          } else{
            viewAccount = message.accountTransactionList[i].target_account_address;
          }

          if(forAccountAddress === message.accountTransactionList[i].target_account_address){
            amount = "+ " + (message.accountTransactionList[i].amount).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',') || 0;
            balance = message.accountTransactionList[i].target_result_amount;
          } else{
            amount = "- " + (message.accountTransactionList[i].amount).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',') || 0;
            balance =message.accountTransactionList[i].source_result_amount;
          }

          if(accountContactList.length === 0 ){
          
            account = viewAccount;
            if (viewAccount.length < 30) {
              account = "사용자 계좌";
            } else if (viewAccount === '0x84d65f333a37674c0487155cf4c02914a63e6b52'){
              account = "VENETA";
            }
    
            contact = message.accountTransactionList[i].target_account_address.substring(0,6) + "...";
          
          } else {
            
            for (var con = 0; con < accountContactList.length; con++) {

              if (viewAccount === accountContactList[con].cName) {
                
                account = viewAccount;
                contact = accountContactList[con].title;
                break;
              
              } else if (message.accountTransactionList[i].transaction_code === 'INPUT_CASH'){
                
                account = "사용자 계좌";
                contact = "사용자 계좌";
                break;
              
              } else if (viewAccount === forAccountAddress){
                
                account =viewAccount;
                contact = forAccountAddress_name;
                break;

              } else if (viewAccount === '0x84d65f333a37674c0487155cf4c02914a63e6b52'){
                account = "VENETA";
                contact = "VENETA";
                break;
              } else {
                
                account =viewAccount;
                contact =viewAccount.substring(0,6)+"..."; 
              }

            }

            date = message.accountTransactionList[i].date;
            date = date.replaceAll('-', '.');
            date = date.substring(0,16);

          }
          
          tradeType = message.accountTransactionList[i].transaction_type === 'KRC'?'KRDC':'KRW';
          
          addTrans.push({
            "date": date,
            "type": message.accountTransactionList[i].transaction_type,
            "account":account+"^"+contact,
            "remark":message.accountTransactionList[i].transaction_code,
            "amount":amount +' '+tradeType,
            "balance":(balance.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',') || 0)+' '+tradeType,
            "status": message.accountTransactionList[i].status,
          })
        }
        setTxnTableData(addTrans);    
      }
    });
  },[accountContactList, checkAxios]);

  useEffect(() => {
    fetch('/get_account_info?account_address='+getAccountAddressRoute(routes))
    .then(response => response.text())
    .then(message => {
      message = JSON.parse(message);
      if(message.accountCashInfo === undefined || message.accountCoinInfo === undefined || message.accountSentCoinInfo === undefined){
        
        logout();
      }else{
        
        // Update variables for the AssetChart and AssetTable
        setAssetChartData([(message.accountCoinInfo.amount*1000),message.accountCashInfo.amount])
        setAssetTableData([{"name":"KRW", "balance": (message.accountCashInfo.amount).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ','),"ratio": message.accountCashInfo.amount,"deposit": "     " },
                          { "name":"KRDC",
                            "balance": (message.accountCoinInfo.amount).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ','),
                            "ratio": (message.accountCoinInfo.amount*1000),
                            "deposit": "     "}]);

        // Update the accountAssetInfo (store.js)
        dispatch(changeAccountAssetInfo({'key': 'krwAmount', 'value': message.accountCashInfo.amount}));
        dispatch(changeAccountAssetInfo({'key': 'krcAmountHold', 'value': message.accountCoinInfo.amount}));
        dispatch(changeAccountAssetInfo({'key': 'krcAmountSent', 'value': message.accountSentCoinInfo.amount}));
        dispatch(changeAccountAssetInfo({'key': 'address', 'value': getAccountAddressRoute(routes)}));
        dispatch(changeAccountAssetInfo({'key': 'name', 'value': getActiveRoute(routes)}));
      
        if(checkAxios === true){
          setCheckAxios(false);
        }
      }
    });
  },[routes,checkAxios]);

  return (
    <Box ref = {mainRef} pt={{ base: "50px", md: "80px", xl: "80px" }} mt="70px" mb='30px'  mx={{ base: "10px", md: "30px", xl: "35px" }}>
      <Modal
        modalMaskClosable={true}
        modalContent = <KrcSell titleText = "KRDC 상환하기" setCheckAxios={setCheckAxios} />
        modalType= "krcSell" />
      <SimpleGrid columns={{ base: 1, md: 1, xl: 2 }} gap={{ base: "10px", md: "20px", xl: "45px" }} mb='40px'>
        <Box mt='5px' py='0px'>
          <AssetCard
            size={size}
            qrSize={(size === 'mobile') ? 75 : 95}
            address={getAccountAddressRoute(routes)}
            contactList = {accountContactList}
            setCheckAxios= {setCheckAxios} />
        </Box>
        <Box mt='5px' py='0px'>
          <AssetChart/>
        </Box>

      </SimpleGrid>
      
      {
        size === 'mobile'
        ? // AssetTable (Mobile)
          <>
            <SimpleGrid columns={{ base: 1, md: 1, xl: 1 }} gap={{ base: "10px", md: "20px", xl: "45px" }} mb='40px'>
              <AssetTableMobile
                columnsData={colDataComplex}
                tableData={assetTableData}
                setCheckAxios={setCheckAxios}/>
            </SimpleGrid>
          </>
        :  // AssetTable (PC)
          <>
            <SimpleGrid columns={{ base: 1, md: 1, xl: 1 }} gap={{ base: "10px", md: "20px", xl: "45px" }} mb='40px'>
              <AssetTable
                columnsData={colDataComplex}
                tableData={assetTableData}
                sum={sum}
                setCheckAxios={setCheckAxios} />
            </SimpleGrid>
          </>
      }

      {
        size === 'mobile'
        ? // TxnTable (Mobile)
          <TxnCardGroup txnTableData = {txnTableData}/>
        : // TxnTable (PC)
          <TxnTable txnTableData ={txnTableData}/>
      }
    </Box>
  );
}
