// React
import React, { useEffect,useState } from "react";
// External Libraries
import { Route, Switch,useHistory } from "react-router-dom";
import { Icon,Box, useColorModeValue} from "@chakra-ui/react";
import { MdLock} from "react-icons/md";
// Components
import SignInCentered from "views/login";
// Contexts
import { SidebarContext } from "views/login/contexts/SidebarContext";

/**
 * 
 * Props list - none
 */
export default function Auth() {

  // routes variables
  const [routes, setRoutes] = useState([]);

  // route Change variables
  const history = useHistory();

  // session status
  const [loginCheck, setLoginCheck ] = useState("");      
  const [separateCheck, setSeparateCheck] = useState(""); 
  // style variables
  const authBg = useColorModeValue("white", "navy.900");

  // layout direction (language)
  document.documentElement.dir = "ltr";
  
  const [message, setMessage] = useState("");

  // api call
  useEffect(() => {
    fetch('/mainDashboard')
    .then(message => message.json())                        // api return data Json parsing
    .then(message =>{                                      // api return                   
      setSeparateCheck(message.separateCheck);          
      setLoginCheck(message.loginCheck);
      if(message.errorCheck.putType === 'error' ){       // api return data = error => server return error code
        history.push({                                  // Change to error page  
          pathname: '/auth/error',
          state: {errorCheck : message.errorCheck} 
        });
      }else if(message.loginCheck.useremail === 'admin@veneta' ){       // api return data = error => server return error code
        history.push('/console');
      } // if else end
    });
  },[]); // rerender to  message change -> refresh OR new session

  // page branch
  useEffect(() => {
    if(loginCheck.useremail ===  'admin@veneta' ){              // admin login true
      history.push('/console');                                // view console page 
     } else if(loginCheck.check){                                   // login true
      history.push('/main');                                 // view main page 
    }else if(separateCheck.putType === 'email'){            // access to verification email
      history.push({                                        // view authauthenication
        pathname: '/auth/auth-authentication',
        state: {separateCheck : separateCheck} 
      });
    }else{                                                  // no login && not verfication 
      setRoutes([{                                          // Create login Route 
        name: "Sign In",
        layout: "/auth",
        path: "/sign-in",
        pathId: "",
        icon: <Icon as={MdLock} width='20px' height='20px' color='inherit' />,
        component: SignInCentered,
      }]);
    }// if else end
  },[loginCheck,separateCheck])                             // session status change

  // states and functions
  const [toggleSidebar, setToggleSidebar] = useState(false);

  // functions for changing the states from components
  const getRoute = () => {
    return window.location.pathname !== "/auth/full-screen-maps";
  };

  // current route info 
  const getRoutes = (routes) => {
    return routes.map((prop, key) => {
      if (prop.layout === "/auth") {
        return (
          <Route
            path={prop.layout + prop.path + prop.pathId}
            component={prop.component}
            key={key}/>
        );
      } // if end
      if (prop.collapse) {
        return getRoutes(prop.items);
      } // if end
      if (prop.category) {
        return getRoutes(prop.items);
      } else {
        return null;
      } // if else end
    });
  };

  return (
    <Box>
      <SidebarContext.Provider
        value={{
          toggleSidebar,
          setToggleSidebar,
        }}>
        <Box
          bg={authBg}
          float='right'
          minHeight='100vh'
          height='100%'
          position='relative'
          w='100%'
          transition='all 0.33s cubic-bezier(0.685, 0.0473, 0.346, 1)'
          transitionDuration='.2s, .2s, .35s'
          transitionProperty='top, bottom, width'
          transitionTimingFunction='linear, linear, ease'>
          {getRoute() ? (
            <Box mx='auto' minH='100vh'>
              <Switch>
                {getRoutes(routes)}
              </Switch>
            </Box>
          ) : null}
        </Box>
      </SidebarContext.Provider>
    </Box>
  );
}
