// External Libraries
import React from 'react';
import { Input } from "reactstrap"
import { Flex, Text, FormErrorText, ButtonGroup, Button, IconButton, useColorModeValue, Popover, PopoverTrigger, PopoverContent, PopoverHeader, PopoverBody, PopoverFooter, PopoverArrow, PopoverCloseButton, FormControl, FormLabel, NumberInput, NumberInputField, NumberInputStepper, NumberIncrementStepper, NumberDecrementStepper } from "@chakra-ui/react";


import $ from "jquery";
window.$ = $;

export default function DefaultColumnFilter ({
    column: {
      filterValue,
      setFilter,
      preFilteredRows: { length },
    },
  }) {
    // return (
      // <Input
      //   value={filterValue || ""}
      //   onChange={e => {
      //     setFilter(e.target.value || undefined)
      //   }}
      //   placeholder={`search (${length}) ...`}
        
      // />

    // )

  return (
  <FormControl>
    <FormLabel>Email address</FormLabel>
    <Input
        value={filterValue || ""}
        onChange={e => {
          setFilter(e.target.value || undefined)
        }}
        placeholder={`search (${length}) ...`}
        
      />
    {/* <FormHelperText>We'll never share your email.</FormHelperText> */}
  </FormControl>
  )
}