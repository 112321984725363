// React
import React, { useEffect,useState } from "react";
// External Libraries
import { Box } from "@chakra-ui/react";
import AssetTableAdmin from "views/admin/asset/components/AssetTableAdmin";
import {assetTableAdminColumn} from "views/admin/asset/variables/assetTableAdminColumn";

export default function AdminAssetDashboard() {

  // session status
  const [venetaKrw, setVenetaKrw ] = useState("");
  const [venetaKrdc, setVenetaKrdc ] = useState("");
  // api status
  const [apiStatus, setApiStatus ] = useState(false);            
  // api call
  useEffect(() => {
    fetch('/admin/accountInfo')
    .then(message => message.json())                        // api return data Json parsing
    .then(message =>{                                      // api return                   
      setVenetaKrw(message.KRW);
      setVenetaKrdc(message.KRDC);
      console.log(message.KRW);
    });
    
  },[apiStatus]); // rerender to  message change -> refresh OR new session

  var assetTableData = [
    {
      "name":"KRW",
      "balance": venetaKrw,
      "ratio": venetaKrw,
      "deposit": "     "
    },
    {
      "name":"KRDC",
      "balance": venetaKrdc,
      "ratio": venetaKrdc*1000,
      "deposit": "     "
    }
  ];

  return(
    <Box>
      <AssetTableAdmin
        columnsData={assetTableAdminColumn}
        tableData={assetTableData}
        sum={venetaKrw+(venetaKrdc*1000)}
        setApiStatus={setApiStatus} 
        />
    </Box>
  )
}
