// React
import React, { useState } from "react";
// External Libraries
import { MdSettings } from "react-icons/md";
import { HiChevronDoubleLeft, HiChevronDoubleRight, HiChevronLeft, HiChevronRight } from "react-icons/hi";
import { Flex, Text, ButtonGroup, Button, IconButton, useColorModeValue, Popover, PopoverTrigger, PopoverContent, PopoverHeader, PopoverBody, PopoverFooter, PopoverArrow, PopoverCloseButton, FormControl, FormLabel, NumberInput, NumberInputField, NumberInputStepper, NumberIncrementStepper, NumberDecrementStepper } from "@chakra-ui/react";
// Components
import UserTablePageRadio from "./UserTablePageRadio";
import { HSeparator } from "components/separator/Separator";

/**
 * 
 * Props list
 *  - pageCount, goToPage, previousPage, pageIndex, 
 *    nextPage, canPreviousPage, canNextPage, pageOptions
 *    : variables to control the table pagination
 *  - nextPage, initialFocusRef: props for children
 */
export default function UserTableController({ setPageSize, initialFocusRef, pageCount, gotoPage, previousPage, pageIndex, nextPage, canPreviousPage, canNextPage, pageOptions }) {
  
  // Variables to control the page of txnTable
  const [goPage, setGoPage] = useState(1);
  const onCheckEnter = (e) => {
      if(e.key === 'Enter') {
          gotoPage(goPage);
      }
  }
  
  // A Variable for CSS Styling
  const bgShadow = useColorModeValue(
      "14px 17px 40px 4px rgba(112, 144, 176, 0.08)",
      "unset"
    );

  return (
    <Flex
      direction='column'
      justify='center'
      align='center'>
      <ButtonGroup size='md' isAttached variant='outline' my='10px' boxShadow={bgShadow}>
        <IconButton aria-label='Go to the first page' icon={<HiChevronDoubleLeft />} onClick={() => gotoPage(0)} disabled={!canPreviousPage} />
        <IconButton aria-label='Go to previous page' icon={<HiChevronLeft />} onClick={() => previousPage(0)} disabled={!canPreviousPage} />
        <Button>{" "} <Text as='span' fontWeight='800' mr='8px' color='blue.900'>PAGE</Text> {pageIndex + 1} / {pageOptions.length}{" "}</Button>
        <Popover
            initialFocusRef={initialFocusRef}
            placement='top'
            closeOnBlur={true}>
          <PopoverTrigger>
            <IconButton aria-label='More' icon={<MdSettings />} color='blue.600' />
          </PopoverTrigger>
          <PopoverContent color='white' bg='blue.800' borderColor='blue.800'>
            
            <PopoverHeader pt={4} fontWeight='bold' border='0'>
              <Flex direction='row' align='center' justify='center' my='5px'>
                <MdSettings />
                <Text as='span' ml='6px'fontSize='17px'>QUICK MANAGEMENT</Text>
              </Flex>
            </PopoverHeader>
            
            <PopoverArrow />
            <PopoverCloseButton />
        
            <PopoverBody>

              <Flex direction='column'>
                <HSeparator/>
                <HSeparator/>
                <HSeparator/>
                
                <Flex
                  direction='row'
                  justify='center'
                  align='center'
                  my='15px'>
            
                  <FormControl onKeyPress={onCheckEnter}>
                    <FormLabel>
                        <Text
                            as="span"
                            fontSize = '13px'
                            fontWeight='800'
                            align='center'
                            mr="10px">
                            GO TO PAGE :
                        </Text>
                    </FormLabel>
                    <NumberInput max={pageCount} min={1}>
                      <NumberInputField
                          fontSize='13px'
                          fontWeight='600'
                          color='white'
                          placeholder="PRESS THE ENTER KEY."
                          onChange={(e) => {
                              const page = e.target.value ? Number(e.target.value) - 1 : 0;
                              setGoPage(page);
                          }} />
                      <NumberInputStepper>
                        <NumberIncrementStepper />
                        <NumberDecrementStepper />
                      </NumberInputStepper>
                    </NumberInput>
                  </FormControl>
                </Flex>
                
                <HSeparator/>

                <UserTablePageRadio
                  initialFocusRef={initialFocusRef}
                  setPageSize={setPageSize} />
              
              </Flex>

            </PopoverBody>
          
            <PopoverFooter
              border='0'
              display='flex'
              alignItems='center'
              justifyContent='center'
              pb={4} />
          </PopoverContent>
        </Popover>

        <IconButton aria-label='Go to next page' icon={<HiChevronRight />} onClick={() => nextPage(0)} disabled={!canNextPage} />
        <IconButton aria-label='Go to the last page' icon={<HiChevronDoubleRight />} onClick={() => gotoPage(pageCount - 1)} disabled={!canNextPage} />
    
      </ButtonGroup>
    </Flex>
  );
}