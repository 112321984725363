// React
import React from "react";
// External Libraries
import QRCode from "react-qr-code";
import { useSelector } from "react-redux"
import { CopyIcon } from '@chakra-ui/icons'
import { Flex, Box, IconButton, Text, Spacer, useClipboard } from "@chakra-ui/react";
// Components
import Card from "components/card/Card.js";
import AssetCardInside from "views/service/components/AssetCardInside"
// Assets
import gradient2 from "assets/img/service/gradient2.png";

/**
 * 
 * Props list
 *  - size: Refers to the screen size (pc/mobile)
 *  - qrSize: Refers to the size of QR code
 *  - accountContactList, setCheckAxios: for children
 * 
 */
export default function AssetCard( { size, qrSize, address, setCheckAxios, contactList }) {

  // A function variable to control the clipboard
  const { onCopy } = useClipboard(address);
  // A varible to represent current account info (from store.js)
  let accountAssetInfo = useSelector((state) => { return state.accountAssetInfo } );

  return (
    <Card
      overflow='auto'
      backgroundImage={gradient2}
      backgroundRepeat='no-repeat'
      bgSize='cover'
      alignSelf='center'
      bgPosition='10%'
      mx='auto'
      p='20px'>
      <Flex direction='column' color='white' h='100%' w='100%' justifyContent= 'space-between' overflow='auto'>
        <Flex>
          <Box w='70%' pr='15px'>
            <Flex direction='column'>
              <Flex justify='space-between' align='center' mb='0px' mt ='30px' pb='2px'>
                <Text fontSize={{ base: "2xl", md: "20pt"}} fontWeight='700'>
                  {accountAssetInfo.name}
                </Text>
              </Flex>
                <Flex direction='row' justify='space=between' align='center'>
                  <IconButton aria-label='Copy' icon={<CopyIcon />} size='xs' colorScheme='whiteAlpha' ml='1px' mr='3px' onClick={onCopy} borderRadius='5'/>
                  <Text isTruncated fontSize={{ base: "15px", md: "lg"}} ml = '2px' opacity="35%">
                    {accountAssetInfo.address}
                  </Text>  
                </Flex>
            </Flex>
          </Box>
          <Spacer />
          <Box pr="15px" pt="30px" overflow='auto'>
            <QRCode value={accountAssetInfo.address} bgColor="transparent" fgColor="rgb(255, 255, 255, 0.9)" size={qrSize}/>
          </Box>   
        </Flex>
        <Box mt="15px">
          <AssetCardInside
            size={size}
            accountContactList={contactList}
            setCheckAxios={setCheckAxios}/>
        </Box>   
      </Flex>
    </Card>
  );
}
