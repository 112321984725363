// React
import React, { useEffect,useState } from "react";
import axios from 'axios';
// Components
import ConsoleDashboard from "views/admin";
import AdminAssetDashboard from "views/admin/asset";
import AdminTransactionDashboard from "views/admin/transaction";
import AdminUserDashboard from "views/admin/user";
import Footer from "components/footer/Footer.js";
import SidebarConsole from "views/admin/components/SidebarConsole";
import { MdAssignment, MdAssignmentInd, MdAssignmentTurnedIn } from "react-icons/md"
import NavbarConsole from "views/admin/components/NavbarConsole"
import { Redirect, Route, Switch, useHistory,useLocation } from "react-router-dom";
import { Portal, Box, useDisclosure } from "@chakra-ui/react";

// Contexts
import { SidebarContext } from "views/admin/contexts/SidebarContext";


export default function Admin() {
  const location = useLocation();

  // route Change variables
  const history = useHistory();

  // session status
  const [loginCheck, setLoginCheck ] = useState("");      
  const [separateCheck, setSeparateCheck] = useState(""); 

  // api call
  useEffect(() => {
    fetch('/mainDashboard')
    .then(message => message.json())                        // api return data Json parsing
    .then(message =>{                                      // api return                   
      setSeparateCheck(message.separateCheck);          
      setLoginCheck(message.loginCheck);
      if(message.errorCheck.putType === 'error' ){       // api return data = error => server return error code
        history.push({                                  // Change to error page  
          pathname: '/auth/error',
          state: {errorCheck : message.errorCheck} 
        });
      }else if(message.loginCheck.useremail === 'admin@veneta' ){       // api return data = error => server return error code
        history.push('/console');
      }else if(message.loginCheck.useremail !== 'admin@veneta' && message.loginCheck){
        alert("잘못된 접근 입니다.");
        history.push('/main')
      } // if else end
    });
  },[]); // rerender to  message change -> refresh OR new session




  const [toggleSidebar, setToggleSidebar] = useState(false);
  var routes = [
    {
      name: "Asset Management",
      layout: "/console",
      path: "/asset",
      icon: <MdAssignment />,
      component: AdminAssetDashboard
    },
    {
      name: "Transaction History",
      layout: "/console",
      path: "/transaction",
      icon: <MdAssignmentTurnedIn />,
      component: AdminTransactionDashboard
    },
    {
      name: "User List",
      layout: "/console",
      path: "/user",
      icon: <MdAssignmentInd/>,
      component: AdminUserDashboard
    }
  ];
  const getRoute = () => {
    return window.location.pathname !== "/main/full-screen-maps";
  };
  // A function variable for logout functionality
  function logout(){
    axios.get('/logout').then((response) => {
        window.location.replace("/");    
    })
  }  const { onOpen } = useDisclosure();

  const getRoutes = (routes) => {
    return routes.map((prop, key) => {
      if (prop.layout === "/console") {
        return (
          <Route
          path={prop.layout + prop.path }
          key={key}
          render={() => <prop.component routes={routes}/>}/>            
        );
      }
      if (prop.collapse) {
        return getRoutes(prop.items);
      }
      if (prop.category) {
        return getRoutes(prop.items);
      } else {
        return null;
      }
    });
  };
  const getActiveRoute = (routes) => {
    let activeRoute = "Default";
    if(routes === undefined || routes === null ) return null;
    else if(routes.length > 0 ) activeRoute = routes[1].path+routes[1].pathId;
    for (let i = 0; i < routes.length; i++) {
      if (routes[i].collapse) {
        let collapseActiveRoute = getActiveRoute(routes[i].items);
        if (collapseActiveRoute !== activeRoute) return collapseActiveRoute;
      } else if (routes[i].category) {
        let categoryActiveRoute = getActiveRoute(routes[i].items);
        if (categoryActiveRoute !== activeRoute) return categoryActiveRoute;
      } else {
        if (
          window.location.href.indexOf(routes[i].layout + routes[i].path + routes[i].pathId) !== -1
        ) return routes[i].name;
      }
    }
    return activeRoute;
  };
  // Layout direction (language)
  document.documentElement.dir = "ltr";
  var routeName;
  return (
    <>

    {loginCheck
      ?
      <Box>
        <SidebarContext.Provider value={{toggleSidebar, setToggleSidebar}}>
          <SidebarConsole routes={routes} display='none' />
          <Box
                float='right'
                minHeight='100vh'
                height='100%'
                overflow='auto'
                position='relative'
                maxHeight='100%'
                w={{ base: "100%", xl: "calc( 100% - 290px )" }}
                maxWidth={{ base: "100%", xl: "calc( 100% - 290px )" }}
                transition='all 0.33s cubic-bezier(0.685, 0.0473, 0.346, 1)'
                transitionDuration='.2s, .2s, .35s'
                transitionProperty='top, bottom, width'
                transitionTimingFunction='linear, linear, ease'>
                  <Portal>
                    <Box>
                      <NavbarConsole/>
                    </Box>
                  </Portal>
                  {
                  getRoute()
                  ? // getRoute() === true
                  (
                    <Box
                      mx='auto'
                      p={{ base: "20px", md: "30px" }}
                      pe='20px'
                      minH='100vh'
                      ml='30px'
                      my='130px'>
                      <Switch>
                        {routeName = '/console'+getActiveRoute(routes)}
                        {getRoutes(routes)}
                        <Redirect from='/console' to='/console/asset'/>
                      </Switch>
                    </Box>
                  )
                  : //getRoute() === false
                    null
                }
                </Box>
                <Box>
                  <Footer />
                </Box>
                
        </SidebarContext.Provider>
      </Box>
    :null
  }
  </>
  );
}
