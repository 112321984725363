// React
import React, { useMemo, useState } from "react";
// External Libraries
import { Flex, Text } from "@chakra-ui/react";
// Components
import Card from "components/card/Card";
import UserTableContent from "./UserTableContent";
import FilterSearchbar from "components/searchbar/FilterSearchbar"
// Assets
import gradient1 from "assets/img/service/gradient1.jpg";
// Variables
import { columnsDataUser } from "variables/admin/columnsDataUser";

/**
 * 
 * Props list
 *  - txnTableData: transaction data for TxnTable
 * 
 */
export default function UserTable({ txnTableData,setApiStatus }) {

  // Variables for the table (react-table)
  const columns = useMemo(() => columnsDataUser, [columnsDataUser]);
  const tableData = useMemo(() => txnTableData, [txnTableData]);

  const [searchbarFlist, setSearchbarFlist] = useState([]);

  return (
    // <Card
    //   direction='row'
    //   w='100%'
    //   overflowX={{ base: "scroll", sm: "scroll", lg: "hidden" }}
    //   backgroundImage={gradient1}
    //   backgroundRepeat='no-repeat'
    //   bgSize='cover'
    //   alignSelf='center'
    //   bgPosition='10%'>
    //   <Flex direction="column" justify='space-between'>
    //     <Flex direction='row' px='20px' pl='35px' justify='space-between' mt='35px' mb='0px' align='center'>
    //       <Text
    //         color='whiteAlpha.900'
    //         fontSize={{ base: "18px", sm: "20px", lg: "25px" }}
    //         fontWeight='700'
    //         lineHeight='100%'
    //         overflowX='hidden'
    //         overflowY='hidden'>
    //         User Overview
    //       </Text>
    //       <FilterSearchbar />
    //     </Flex>
        <UserTableContent
          columns = {columns}
          data = {tableData}
          setSearchbarFlist = {setSearchbarFlist}
          setApiStatus={setApiStatus} />
    //    </Flex>
    // </Card>
  );
}