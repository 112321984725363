// External Libraries
import * as RiIcons from 'react-icons/ri';
import { MdHomeFilled, MdAssignmentInd } from "react-icons/md"
import { Box, Flex, Stack } from "@chakra-ui/react";
// Components
import SidebarConsoleHeader from "./SidebarConsoleHeader";
import React from "react";
import SidebarConsoleElement from "./SidebarConsoleElement.js";

/**
 * 
 * Props list
 *  - routes: routes generated by index.js; contains the email and name info
 *  - onClose: props for children
 * 
 */
export default function SidebarConsoleContent({ routes, onClose }) {

  console.log('Sidebar Content > routes: ', routes);

  // Variables for ForLoop
  var idxAList = 0;
  var forend = 0;
  var subNavList=[];
  var SidebarDataList=[];
  
  // To generate data for the account list and the contact list
  for(var i = 0; i < routes.length; i++){
    // If routes[i] has the path information, it is the element for AccountList
    // if(routes[i].path !== undefined){
    //   subNavList[idxAList] = {
    //     title: routes[i].name,
    //     path: routes[i].layout+routes[i].path,
    //     icon: routes[i].icon
    //   }
    //   idxAList += 1;       
    // }
    // forend += 1;
    
    
    if(routes[i].path !== undefined){
      SidebarDataList[idxAList] = {
        title: routes[i].name,
        path: routes[i].layout+routes[i].path,
        icon: routes[i].icon,
                iconClosed: <RiIcons.RiArrowDownSFill />,
        iconOpened: <RiIcons.RiArrowUpSFill />,
        subNav: []
      }
      idxAList += 1;       
    }
    forend += 1; 
  }

  // Define the data for sidebar only when the for loop ends
  if(forend === routes.length && routes.length !== 0){
    // SidebarDataList = [
    //   {
    //     title: 'Account List',
    //     iconClosed: <RiIcons.RiArrowDownSFill />,
    //     iconOpened: <RiIcons.RiArrowUpSFill />,
    //     subNav: subNavList
    //   }
    // ]
  }

  return (
    <Flex direction='column' height='100%' pt='25px' borderRadius='30px'>
      <SidebarConsoleHeader />
      <Stack direction='column' mb='auto' mt='20px'>
        <Box ps='20px' pe={{ md: "16px", "2xl": "1px" }} pt='10px'>
          {SidebarDataList.map((item) => {
              return <SidebarConsoleElement onClose={onClose} item={item} />
          })}
        </Box>
      </Stack>
    </Flex>
  );
}
