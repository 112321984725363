// React
import React, { useState, useEffect } from "react";
// External Libraries
import { useSelector } from "react-redux";
import { Box, Flex, Text, useColorModeValue } from "@chakra-ui/react";
// Components
import NavbarLinks from "components/navbar/NavbarLinks";

/**
 * 
 * Props list
 *  - routes: for children components
 * 
 */
export default function Navbar({ routes }) {

  // A variable that contains name information of the address
  let accountAssetInfo = useSelector((state) => { return state.accountAssetInfo } );

  // Variables to set the mode of Navbar depending on the window size
  const [mode, setMode] = useState('');
  let sizeMode = useSelector((state) => { return state.sizeMode } );

  // Variables for CSS Styling
  let mainText = useColorModeValue("navy.700", "white");
  let navbarPosition = "fixed";
  let navbarFilter = "none";
  let navbarBackdrop = "blur(20px)";
  let navbarShadow = "none";
  let navbarBg = useColorModeValue("rgba(244, 247, 254, 0.2)", "rgba(11,20,55,0.5)");
  let navbarBorder = "transparent";
  let secondaryMargin = "0px";
  let paddingX = "15px";
  let gap = "0px";

  // Control the Navbar depending on the window size
  useEffect(()=>{
    setMode(sizeMode.size);
  },[sizeMode.size]);

  return (
    mode === 'pc'
    ? // for the 'pc' mode
      <Box
        position={navbarPosition}
        boxShadow={navbarShadow}
        bg={navbarBg}
        borderColor={navbarBorder}
        filter={navbarFilter}
        backdropFilter={navbarBackdrop}
        backgroundPosition='center'
        backgroundSize='cover'
        borderRadius='16px'
        borderWidth='1.5px'
        borderStyle='solid'
        transitionDelay='0s, 0s, 0s, 0s'
        transitionDuration=' 0.25s, 0.25s, 0.25s, 0s'
        transition-property='box-shadow, background-color, filter, border'
        transitionTimingFunction='linear, linear, linear, linear'
        alignItems={{ xl: "center" }}
        display="flex"
        minH='75px'
        justifyContent={{ base:"center", xl: "center" }}
        lineHeight='25.6px'
        mx='auto'
        mt={secondaryMargin}
        pb='8px'
        right={{ base: "12px", md: "30px", lg: "30px", xl: "30px" }}
        px={{
          sm: paddingX,
          md: "10px",
        }}
        ps={{
          xl: "12px",
        }}
        pt='8px'
        top={{ base: "30px", md: "16px", xl: "18px" }}
        w={{
          base: "calc(100vw - 6%)",
          md: "calc(100vw - 8%)",
          lg: "calc(100vw - 6%)",
          xl: "calc(100vw - 350px)",
          "2xl": "calc(100vw - 365px)",
        }}>
        <Flex
          w='100%'
          flexDirection="row"
          alignItems={{ base: "center" }}
          mb={gap}>
          <Box mb={{ sm: "8px", md: "0px" }} order={1}>
              <Text
                ml={{ base: "8px", md: "20px", xl: "40px" }}
                mr={{ base: "15px", md: "0px", xl: "0px" }}
                fontSize={{ base: "23px", md: "33px", xl: "35px" }}
                color={mainText}
                fontWeight='bold'>
                {accountAssetInfo.name}
              </Text>
          </Box>
          <Box ms='auto' w={{ sm: "100%", md: "unset" }} order={2}>
            <NavbarLinks routes={routes}/>
          </Box>
        </Flex>
      </Box>
    : // for the 'mobile' mode
      <Box
        position={navbarPosition}
        boxShadow={navbarShadow}
        bg={navbarBg}
        borderColor={navbarBorder}
        filter={navbarFilter}
        backdropFilter={navbarBackdrop}
        backgroundPosition='center'
        backgroundSize='cover'
        borderRadius='16px'
        borderWidth='1.5px'
        borderStyle='solid'
        transitionDelay='0s, 0s, 0s, 0s'
        transitionDuration=' 0.25s, 0.25s, 0.25s, 0s'
        transition-property='box-shadow, background-color, filter, border'
        transitionTimingFunction='linear, linear, linear, linear'
        alignItems={{ base: "center" }}
        display="flex"
        minH='75px'
        justifyContent={{ base:"center", xl: "center" }}
        lineHeight='25.6px'
        mx='auto'
        mt={secondaryMargin}
        pb='8px'
        right={{ base: "12px", md: "30px", lg: "30px", xl: "30px" }}
        ps={{
          xl: "12px",
        }}
        px='5px'
        pt='8px'
        top={{ base: "30px", md: "16px", xl: "18px" }}
        w={{
          base: "calc(100vw - 10%)",
          md: "calc(100vw - 10%)",
          lg: "calc(100vw - 10%)",
          xl: "calc(100vw - 350px)",
          "2xl": "calc(100vw - 365px)",
        }}>
        <Flex
          w='100%'
          flexDirection="column"
          alignItems={{ base: "center" }}
          m='auto'
          >
          <Box w={{ base: "100%", md: "unset" } } m='auto' order={1}>
          <NavbarLinks
            routes={routes}
            accountName = {accountAssetInfo.name}/>
          </Box>      
        </Flex>
      </Box>
  );
}
