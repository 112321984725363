// React
import React, {useState} from "react";
// External Libraries
import axios from 'axios';
import { NavLink,useHistory } from "react-router-dom";
import { Box, Button, Flex, FormControl, FormLabel, Heading, Icon,Input, InputGroup, InputRightElement, Text, useColorModeValue,} from "@chakra-ui/react";
import { MdOutlineRemoveRedEye } from "react-icons/md";
import { RiEyeCloseLine } from "react-icons/ri";
// Components
import DefaultLogin from "layouts/login/Default";
// Assets
import illustration from "assets/img/login/auth.png";

/**
 * 
 * Props list - none
 */
export default function SignUp() {

  // route Change variables
  const history = useHistory();

  // style variables
  const textColor = useColorModeValue("navy.700", "white");
  const textColorSecondary = "gray.400";
  const textColorDetails = useColorModeValue("navy.700", "secondaryGray.600");
  const textColorBrand = useColorModeValue("brand.500", "white");
  const brandStars = useColorModeValue("brand.500", "brand.400");

  // input data passsword view variables
  const [show, setShow] = React.useState(false);
  const [show2, setShow2] = React.useState(false);
  const handleClick = () => setShow(!show);
  const handleClick2 = () => setShow2(!show2);

  // input data variables
  const [inputs, setInputs] = useState({
    email: "",
    name: "",
    password: "",
    passwordConfirm: "",
  });
  const { email, name, password, passwordConfirm } = inputs;

  // When the user enters data, the value is changed to the entered value.      
  function onChange(e) {
    const { value, name } = e.target;
    setInputs({
      ...inputs,
      [name]: value,
    });
  }

  // Check input data 
  const isValidPassword = password.length >= 8
  const isValidEmail = email.includes('@') && email.includes('.');


  // view chage variables for input component
  const [submitLoading, setSubmitLoading] = useState(true);

  // async api call
  async function handleSubmit(e) {

    e.preventDefault();
    // input data Check 
    if(!isValidEmail){
      alert("Check Your Email");
    }else if (!isValidPassword){
      alert("Check Your Password");
    }else if(password !== passwordConfirm){
      alert("비밀번호가 일치 하지 않습니다.");
    }else{

      // input style change
      setSubmitLoading(false);

      //server api call
      axios.post('/reg',{
        email : email,
        name : name,
        pwd : password
      }).then((response) => {                       // api return
        if(response.data === 1){                    // api success
          alert("회원가입 신청이 완료되었습니다.\n"+
            "\n"+
            "이메일로 본인확인 메일이 발송 되었습니다.\n"+
            "본인 확인 후 회원 가입이 최종 완료됩니다."        
          );
          setSubmitLoading(true);
          history.push('/auth');                    // Change route( user view)
        }else if(response.data === 0) {             // api another success code  
          alert("회원가입이 실패하였습니다.\n"+
          "이메일이 중복입니다.");
          setSubmitLoading(true);
        }else{                                      // api faled
          alert("예기치 않은 오류로 회원가입이 실패하였습니다.");
          setSubmitLoading(true);
        } // api return if else end
      })
      .catch(function(){                             // api call failed
        console.log("실패..");
        setSubmitLoading(true);
      })
    }// variable Check if else end
  } // fn handleSubmit end
  return (
    <DefaultLogin illustrationBackground={illustration} image={illustration}>
      <Flex
        maxW={{ base: "100%", md: "max-content" }}
        w='100%'
        mx={{ base: "auto", lg: "0px" }}
        me='auto'
        h='100%'
        alignItems='start'
        justifyContent='center'
        mb={{ base: "30px", md: "60px" }}
        px={{ base: "25px", md: "0px" }}
        mt={{ base: "40px", md: "14vh" }}
        flexDirection='column'>
        <Box me='auto'>
          <Heading color={textColor} fontSize='36px' mb='10px'>
            Sign Up
          </Heading>
          <Text
            mb='36px'
            ms='4px'
            color={textColorSecondary}
            fontWeight='400'
            fontSize='md'>
              Enter your email adn name and password to sign up!
          </Text>
        </Box>
        <Flex
          zIndex='1'
          direction='column'
          w={{ base: "100%", md: "420px" }}
          maxW='100%'
          background='transparent'
          borderRadius='15px'
          mx={{ base: "auto", lg: "unset" }}
          me='auto'
          mb={{ base: "20px", md: "auto" }}>
          <form onSubmit={handleSubmit}>
            <FormControl>             
              <FormLabel
                display='flex'
                ms='4px'
                fontSize='sm'
                fontWeight='500'
                color={textColor}
                mb='8px'>
                  Email <Text color={brandStars}>*</Text>
              </FormLabel>
              <Input
                isRequired={true}
                variant='auth'
                fontSize='sm'
                ms={{ base: "0px", md: "0px" }}
                type='text'
                placeholder='test@veneta.io'
                mb='24px'
                fontWeight='500'
                size='lg'
                id="email"
                name="email"
                value={email}
                onChange={onChange}/>
              <FormLabel
                display='flex'
                ms='4px'
                fontSize='sm'
                fontWeight='500'
                color={textColor}
                mb='8px'>
                  Name <Text color={brandStars}>*</Text>
              </FormLabel>
              <Input
                isRequired={true}
                variant='auth'
                fontSize='sm'
                ms={{ base: "0px", md: "0px" }}
                type='text'
                placeholder='Veneta'
                mb='24px'
                fontWeight='500'
                size='lg'
                id="name"
                name="name"
                value={name}
                onChange={onChange}/>
              <FormLabel
                ms='4px'
                fontSize='sm'
                fontWeight='500'
                color={textColor}
                display='flex'>
                  Password <Text color={brandStars}>*</Text>
              </FormLabel>
              <InputGroup size='md'>
                <Input
                  isRequired={true}
                  fontSize='sm'
                  placeholder='Min. 8 characters'
                  mb='24px'
                  size='lg'
                  type={show ? "text" : "password"}
                  variant='auth'
                  id="password"
                  name="password"
                  value={password}
                  onChange={onChange}/>
                <InputRightElement display='flex' alignItems='center' mt='4px'>
                  <Icon
                    color={textColorSecondary}
                    _hover={{ cursor: "pointer" }}
                    as={show ? RiEyeCloseLine : MdOutlineRemoveRedEye}
                    onClick={handleClick}/>
                </InputRightElement>
              </InputGroup>
              <FormLabel
                ms='4px'
                fontSize='sm'
                fontWeight='500'
                color={textColor}
                display='flex'>
                  Password Confirm <Text color={brandStars}>*</Text>
              </FormLabel>
              <InputGroup size='md'>
                <Input
                  isRequired={true}
                  fontSize='sm'
                  placeholder='Min. 8 characters'
                  mb='24px'
                  size='lg'
                  type={show2 ? "text" : "password"}
                  variant='auth'
                  id="passwordConfirm"
                  name="passwordConfirm"
                  value={passwordConfirm}
                  onChange={onChange}/>
                <InputRightElement display='flex' alignItems='center' mt='4px'>
                  <Icon
                    color={textColorSecondary}
                    _hover={{ cursor: "pointer" }}
                    as={show2 ? RiEyeCloseLine : MdOutlineRemoveRedEye}
                    onClick={handleClick2}/>
                </InputRightElement>
              </InputGroup>
                {
                  password !== '' && name !== '' && email !== '' && passwordConfirm !== ''
                  ?
                    submitLoading
                    ?
                      <Button colorScheme="blue" height='50' width='100%' type="submit" >                
                        <Text ml='4px'>Sign Up</Text>
                      </Button>
                    :
                      <Button colorScheme="blue" height='50' width='100%' variant='outline' isLoading loadingText='진행중..'/>
                  :
                    <Button colorScheme="blue" height='50' width='100%' disabled='disabled'>                
                      <Text ml='4px'> Inputs Your Data  </Text>
                    </Button>
                }
            </FormControl>
          </form>
          <Flex
            flexDirection='column'
            justifyContent='center'
            alignItems='start'
            maxW='100%'
            mt='0px'>
            <Text color={textColorDetails} fontWeight='400' fontSize='14px'>
              Already have an account?
              <NavLink to='/'>
                <Text
                  color={textColorBrand}
                  as='span'
                  ms='5px'
                  fontWeight='500'>
                    Sign In
                </Text>
              </NavLink>
            </Text>
          </Flex>
        </Flex>
      </Flex>
    </DefaultLogin>
  );
}