// React
import React, { useRef } from "react";
// External Libraries
import { useGlobalFilter, usePagination, useSortBy, useTable, useFilters } from "react-table";
import { Flex, Table, VisuallyHidden, Tbody, Td, Text, Th, Thead, Tr, useColorModeValue, Spacer, IconButton, Popover, PopoverTrigger, PopoverContent, PopoverHeader, PopoverBody, PopoverArrow, PopoverCloseButton, Center} from "@chakra-ui/react";
// Components
import Card from "components/card/Card";
// Assets
import gradient1 from "assets/img/service/gradient1.jpg";
import ColumnSearchbar from "components/searchbar/ColumnSearchbar"
// External Libraries
import { BsInfoCircle } from "react-icons/bs";
import { CopyIcon } from '@chakra-ui/icons'
import AdminTxnTableController from "./AdminTxnTableController";
import FilterSearchbar from "components/searchbar/FilterSearchbar"
import FilterPopover  from "./FilterPopover";
import $ from "jquery";
window.$ = $;

export default function AdminTxnTableContent({ pageNum, setPageNum, columns, data }) {
    
    // Variables to set a focus towards the controller and popover, respectively
    const controllerRef = useRef();
    var filterList = [];

    // Varaibles for TxnTableTest (react-table)
    const {
      getTableBodyProps,
      headerGroups,
      page,
      prepareRow,
      initialState,
      canPreviousPage,
      canNextPage,
      pageOptions,
      pageCount,
      gotoPage,
      nextPage,
      previousPage,
      setPageSize,
      state,
      preGlobalFilteredRows,
      setGlobalFilter,
      state : {pageIndex, pageSize}
    } = useTable(
        { columns, data, defaultColumn: {Filter: ColumnSearchbar} },
        useFilters,
        useGlobalFilter,
        useSortBy,
        usePagination
    );
    initialState.pageSize = 10;
    // A variable to handle Copy&Paste functionality
    const handleClipBoard = async (text) => {
        try {
            await navigator.clipboard.writeText(text);
        } catch (error) {
            console.log(error);
        }
        };
    // Variables for CSS Styling
    const textColor = useColorModeValue("secondaryGray.900", "white");
    const borderColor = useColorModeValue("gray.200", "whiteAlpha.100");
    const initialFocusRef = useRef();

    return (
        <Card
        direction='row'
        w='100%'
        overflowX={{ base: "scroll", sm: "scroll", lg: "hidden" }}
        backgroundImage={gradient1}
        backgroundRepeat='no-repeat'
        bgSize='cover'
        alignSelf='center'
        bgPosition='10%'
        >
        <Flex direction="column" justify='space-between'>
          <Flex direction='row' px='20px' pl='35px' justify='space-between' mt='35px' mb='0px' align='center'>
            <Text
              isTruncated
              color='whiteAlpha.900'
              fontSize={{ base: "18px", sm: "20px", lg: "25px" }}
              fontWeight='700'
              lineHeight='100%'
              overflowX='hidden'
              overflowY='hidden'>
              Transaction Overview
            </Text>
            <Spacer/>
            <FilterSearchbar
                preGlobalFilteredRows={preGlobalFilteredRows}
                globalFilter={state.globalFilter}
                setGlobalFilter={setGlobalFilter}/>
            <FilterPopover filterList={filterList}/>   
          </Flex>
        <Flex
          direction='column'
          justify='center'
          align='center'
          px='20px'>
           <Card
            backgroundColor='rgb(255, 255, 255)'
            w='100%'
            mt='40px'
            mb='5px'
            direction='column'
            overflowX={{ base: "scroll", sm: "scroll", lg: "scroll" }}
            minHeight='50vh'>
            <Table variant='simple' color='gray.500' mb='0px' page={pageNum} onPageChange={(pageNum) => setPageNum(pageNum)}>
            <Thead>
                {headerGroups.map((headerGroup, index) => (
                <Tr {...headerGroup.getHeaderGroupProps()} key={index}>
                    {
                        headerGroup.headers.map((column, index) => (
                            column.render("Header") !== 'TACCOUNT'
                            ? column.render("Header") !== 'SACCOUNT'
                            ? column.render("Header") !== 'TYPE'
                            ? <Th
                                {...column.getHeaderProps(column.getSortByToggleProps())}
                                key={index}
                                borderColor={borderColor}>
                                <Flex
                                    justify='center'
                                    align='center'zw
                                    fontSize={{ sm: "10px", lg: "12px" }}
                                    color='gray.400'
                                    >
                                    {column.render("Header")}
                                </Flex>
                                <VisuallyHidden>{filterList.push(column.render("Filter"))}</VisuallyHidden>
                            </Th>
                            : null
                            : null
                            : null
                    ))}
                </Tr>
                ))}
            </Thead>
            <Tbody {...getTableBodyProps()}>
                {page.map((row, index) => {
                    prepareRow(row);
                    let unit, senderAddress, receiverAddress = "";
                    
                        return (
                        <Tr {...row.getRowProps()} key={index}>
                        {
                            row.cells.map((cell, index) => {
                                
                            if (cell.column.Header === "DATE") {
                                return (
                                    <Td
                                        key={index}
                                        maxH='30px !important'
                                        py='10px'
                                        minW={{ sm: "150px", md: "200px", lg: "auto" }}
                                        borderColor='transparent'
                                        justify='center'
                                        align='center'>
                                        <Text color={textColor} fontSize='sm' fontWeight='500' align='center'>
                                            
                                            {
                                                cell.value.length > 0
                                                ? cell.value.slice(0,16)
                                                : cell.value
                                            }
                                        </Text>
                                    </Td>
                                );
                            } else if (cell.column.Header === "ACCOUNTS") {
                                var idx = cell.value.indexOf('→');
                                senderAddress = cell.value.slice(0,idx);
                                receiverAddress = cell.value.slice(idx+1);

                                var saddr = (senderAddress.slice(0,2) === '0x')
                                            ? `${senderAddress.slice(0,7)+'...'}`
                                            : `${senderAddress}`;
                                
                                var raddr = (receiverAddress.slice(0,2) === '0x')
                                ? `${receiverAddress.slice(0,7)+'...'}`
                                : `${receiverAddress}`;

                                return (
                                    <Td
                                        key={ index }
                                        maxH='30px !important'
                                        py='10px'
                                        minW={{ sm: "150px", md: "200px", lg: "auto" }}
                                        borderColor='transparent'
                                        justify='center'
                                        align='center'>
                                        <Text color={textColor} fontSize='sm' fontWeight='500' align='center'>
                                            {`${saddr} → ${raddr}`}
                                            <Popover
                                                initialFocusRef={initialFocusRef}
                                                orientation='horizontal'
                                                placement='top'
                                                closeOnBlur={true} >
                                                <PopoverTrigger>
                                                    <IconButton
                                                        aria-label='More Address Info'
                                                        icon={<Center w='75%' h='100%' pb={1}><BsInfoCircle color="gray.500"/></Center>}
                                                        p='0px'
                                                        isActive={false}
                                                        variant='unstyled'
                                                        size='sm'
                                                        isRound = {false}
                                                        flip={false}/>
                                                </PopoverTrigger>
                                                <PopoverContent color='white' bg='blue.800' borderColor='blue.800' marginLeft='30px' width='230px'>
                                                    <PopoverHeader pt={4} fontSize='18px' fontWeight='300' border='0' pl='20px'>
                                                        Full Address Info
                                                    </PopoverHeader>
                                                    <PopoverArrow />
                                                    <PopoverCloseButton />
                                                    <PopoverBody fontWeight='200'fontSize='md' pl='20px' pb='20px'>
                                                        <Text w='90%'>
                                                            <Text fontWeight='900'>
                                                                sender
                                                                <IconButton
                                                                    aria-label='More Address Info'
                                                                    icon={<Center w='80%' h='100%' pb={1}><CopyIcon color="white"/></Center>}
                                                                    onClick={()=>handleClipBoard(senderAddress)}
                                                                    p='0px'
                                                                    isActive={false}
                                                                    variant='unstyled'
                                                                    size='sm'
                                                                    isRound = {false} />
                                                            </Text>
                                                            {senderAddress}
                                                        </Text>
                                                        <Text w='90%'>
                                                            <Text fontWeight='900'>
                                                                reciever
                                                                <IconButton
                                                                    aria-label='More Address Info'
                                                                    icon={<Center w='80%' h='100%' pb={1}><CopyIcon color="white"/></Center>}
                                                                    onClick={()=>handleClipBoard(receiverAddress)}
                                                                    p='0px'
                                                                    isActive={false}
                                                                    variant='unstyled'
                                                                    size='sm'
                                                                    isRound = {false} />
                                                            </Text> 
                                                            {receiverAddress}
                                                        </Text>
                                                    </PopoverBody>
                                                </PopoverContent>
                                            </Popover>
                                        </Text>

                                    </Td>
                                );
                            } else if (cell.column.Header === "TYPE") {
                                unit = ` ${cell.value}`;
                            }else if (cell.column.Header === "AMOUNT") {
                                return (
                                    <Td
                                        key={index}
                                        maxH='30px !important'
                                        py='10px'
                                        minW={{ sm: "150px", md: "200px", lg: "auto" }}
                                        borderColor='transparent'
                                        justify='center'
                                        align='center'>
                                        <Text color={textColor} fontSize='sm' fontWeight='500' align='center'>
                                            {cell.value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')}{unit}
                                        </Text>
                                    </Td>
                                );
                            } else if (cell.column.Header === "CODE") {
                                return (
                                    <Td
                                        key={index}
                                        maxH='30px !important'
                                        py='10px'
                                        minW={{ sm: "150px", md: "200px", lg: "auto" }}
                                        borderColor='transparent'
                                        justify='center'
                                        align='center'>
                                        <Text color={textColor} fontSize='sm' fontWeight='500' align='center'>
                                            {cell.value}
                                        </Text>
                                    </Td>
                                );
                            } else if (cell.column.Header === "STATUS") {
                                return (
                                    <Td
                                        key={index}
                                        maxH='30px !important'
                                        py='10px'
                                        minW={{ sm: "150px", md: "200px", lg: "auto" }}
                                        borderColor='transparent'
                                        justify='center'
                                        align='center'>
                                        <Text color={textColor} fontSize='sm' fontWeight='500' align='center'>
                                            {cell.value}
                                        </Text>
                                    </Td>
                                );
                            }
                        })} 
                        </Tr>
                        )}
                    )}
                </Tbody>
            </Table>
            <br />
            <AdminTxnTableController
                pageCount={pageCount}
                gotoPage={gotoPage}
                previousPage={previousPage}
                pageIndex={pageIndex}
                nextPage={nextPage}
                canPreviousPage={canPreviousPage}
                canNextPage={canNextPage}
                pageOptions={pageOptions}
                initialFocusRef={controllerRef}
                pageSize={pageSize}
                setPageSize={setPageSize} />
        </Card>
       
    </Flex>
    </Flex></Card>
    );
}