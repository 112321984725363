// React
import React, { useState } from "react";
// External Libraries
import { Text, Button, FormControl, FormLabel, RadioGroup, Radio, Stack } from "@chakra-ui/react";

/**
 * 
 * Props list
 *  - setPageSize: a variable to control the table pagination
 *  - initialFocusRef: a focus variable to TxnTable by useRef
 * 
 */
export default function UserTablePageRadio({ setPageSize, initialFocusRef }) {
  // Variables to set the number of transactions visible in one page
  const [radio, setRadio] = useState('1');

  return (
    <Stack direction='column' justify='center' align='center'>
    <FormControl as='fieldset' w='100%' my='12px'>
      <FormLabel as='legend'>
        <Text
            as="span"
            fontSize = '13px'
            fontWeight='800'
            align='center'
            mr="10px">
            HOW MANY ROWS / PAGE ?
        </Text>
      </FormLabel>
      <RadioGroup defaultValue='10' justify={['left','center']} align='' onChange={setRadio} value={radio} pb='0px'>
          <Stack direction={['column', 'row']} spacing='15px' ml='12px' mt='5px'>
            {
              ['10', '20', '30', '40', '50'].map((n) => {
                return <Radio value={n}> {n}</Radio>
              })
            }
          </Stack>
      </RadioGroup>
  </FormControl>
    <Button size='sm' colorScheme='blue' ref={initialFocusRef} width='98%' borderRadius='5px' pt='0px'
      onClick={()=>{setPageSize(Number(radio))}}>
      SUBMIT
    </Button>
  </Stack>
  );
}