// React
import React, { useState } from 'react';
// External Libraries
import { Button, Flex, FormControl, FormLabel, Icon, Input, Text, useColorModeValue, InputGroup, InputRightElement, Stack, Badge} from "@chakra-ui/react";
import axios from 'axios';
import { MdClose } from "react-icons/md";
import { useDispatch, useSelector } from "react-redux"
// Components
import Card from "components/card/Card";
// Variables
import { changeModalState } from "../../store"
// Assets
import gradient1 from "assets/img/service/gradient1.jpg";

/**
 * 
 * Props list
 *  - titleText     : 모달버튼 상단 텍스트
 *  - setApiStatus : api 호출 성공 여부
 */
 export default function KrwDepositAdmin({titleText,setApiStatus, venetaKrw}) {

  // A variable to use function variables from store.js
  const dispatch = useDispatch();

  // Variables for Card CSS Styling
  const textColor = useColorModeValue("navy.700", "white");
  const bgButton = useColorModeValue("secondaryGray.300", "whiteAlpha.100");
  const bgHover = useColorModeValue(
    { bg: "secondaryGray.400" },
    { bg: "whiteAlpha.50" }
  );
  const bgFocus = useColorModeValue(
    { bg: "secondaryGray.300" },
    { bg: "whiteAlpha.100" }
  );

  // input data
  const [inputs, setInputs] = useState({inputkrw: "",totalKrw: ""});
  
  // Cash Desposit input data
  const { inputkrw, totalKrw } = inputs;

  // input style change variable
  const [readOnly, setReadOnly] = useState(false);
  const [btnText, setBtnText] = useState("확인");
  const [inputColor, setInputColor] = useState("none");

  // view chage variables for input component
  const [submitLoading, setSubmitLoading] = useState(true);

  // When the user enters data, the value is changed to the entered value. 
  function onChange(e) {

    // number format String ( add 3 digits , ) 
    const  pureKrw = e.target.value.replace(/[^0-9]/g, "").replaceAll(',', '') ;
    const  replaceKrw  = e.target.value.replace(/[^0-9]/g, "").replaceAll(',', '').toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');

    // Check and transform for parsint
    var parseKrw = pureKrw;
    if(pureKrw === ''){
      parseKrw = 0;
    }
    // totalKrw formatting   
    var  AddpureKrw = '='+' '+(parseInt(parseKrw) + parseInt(venetaKrw)).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',') || 0;

    // inputs value change
    setInputs({
      inputkrw: replaceKrw,     
        totalKrw: AddpureKrw
    });
  } // fn onChange end 
 
  // input value Check
  function confirm(e){
    e.preventDefault();    
    if(inputkrw.replace(/[^0-9]/g, "").replaceAll(',', '')  < 1000){ // input min Check
      alert("예치 최소 금액은 1,000원 부터 입니다.");
      document.getElementById("inputkrw").focus();
    }else if(inputkrw === undefined || inputkrw === ''){ // input null Check
      alert("예치 할 금액을 확인해 주세요.");
      document.getElementById("inputkrw").focus();
    }else{ 
      if(e.target.textContent == '예치하기'){
        handleSubmit(e);
      }else{
        // input style change
        setReadOnly(true);
        setBtnText("예치하기");
        setInputColor("crimson");
      }// if else end in else
    } // if else end
  }// fn confirm end

  // async api call
  async function handleSubmit(e) { 
    
    e.preventDefault(); 

    // input style change
    setSubmitLoading(false);

    // server api call
    axios.put('/admin/krwDeposit',{
      amount : inputkrw.replaceAll(',', ''),        // ( int )    inputCash data
    }).then((response) => {     // api return  
      if(response.data === 1){  // api success
        // modal close , button style chage to defult
        cancel(e);
        // axios succ return -> account info api rerender
        setApiStatus(true);
      }else{                    // api failed
        alert("현금 채우기 실패");
        // modal close , button style chage to defult
        cancel(e);
      }
    })
    .catch(function(){
      console.log("실패..");
    })    
  }// fn handleSubmit end

  // modal close fn
  function cancel(e){

    e.preventDefault();

    // input style change defult
    setSubmitLoading(true);
    dispatch(changeModalState('krwDepositAdmin'));
    setReadOnly(false);
    setBtnText("확인");
    setInputColor("none");
    setInputs({         
      inputkrw: "",     
      totalKrw: ""
    });

  }// fn cancel end

  return (         
    <Card
      direction='row'
      w='100%'
      overflowX={{ sm: "scroll", lg: "hidden" }}
      backgroundImage={gradient1}
      backgroundRepeat='no-repeat'
      bgSize='cover'
      alignSelf='center'
      bgPosition='10%'>
        <Flex direction="column" justify='space-between'>
          <Flex direction='row' pl='30px' pr='13px' justify='space-between' mt='0px' mb='0px' align='center'>
            <Text
              color='whiteAlpha.900'
              mt="20px"
              fontSize='22px'
              fontWeight='700'
              lineHeight='100%'>
                {titleText}
            </Text> 
              {submitLoading
                ?
                  <Button
                  align='center'
                  justifyContent='center'
                  w='37px'
                  h='37px'
                  lineHeight='100%'
                  onClick={(e) => {
                    cancel(e)
                  }}
                  borderRadius='12px'
                  bg={bgButton}
                  _hover={bgHover}
                  _focus={bgFocus}
                  _active={bgFocus}>
                        <Icon as={MdClose} color='blue.800' w='30px' h='30px' />
                    </Button>
                :
                  <Button
                  align='center'
                  justifyContent='center'
                  w='37px'
                  h='37px'
                  lineHeight='100%'
                  borderRadius='12px'
                  bg={bgButton}
                  _hover={bgHover}
                  _focus={bgFocus}
                  _active={bgFocus}>
                      <Icon as={MdClose} color='blue.800' w='30px' h='30px' />
                  </Button>
              }
          </Flex>
          <Flex
            direction='row'
            justify='space-between'
            align='center'>
              <Card
                backgroundColor='rgb(255, 255, 255)'
                my='15px'
                w='100%'
                mt='27px'
                mb='2px'
                //  mr='10px'
                direction='column'
                overflowX={{ sm: "scroll", lg: "hidden" }}>
                  <Text>
                    <form onSubmit={handleSubmit}>
                      <FormControl>  
                        <FormLabel
                          display='flex'
                          ms='4px'
                          fontSize='sm'
                          fontWeight='500'
                          color={textColor}
                          mb='8px'>
                            KRW 예치금액
                        </FormLabel>
                        <InputGroup> 
                        {!readOnly
                          ?
                            <Input
                              isRequired={true}
                              fontSize='sm'
                              ms={{ base: "0px", md: "0px" }}
                              type='text'
                              placeholder='Min 1,000'
                              mb='24px'
                              fontWeight='500'
                              size='lg'
                              id="inputkrw"
                              name="inputkrw"
                              value={inputkrw}
                              onChange={onChange}
                              readOnly={readOnly}
                              isInvalid
                              errorBorderColor={inputColor}/>
                          :
                            <Input 
                              isRequired={true}
                              fontSize='sm'
                              ms={{ base: "0px", md: "0px" }}
                              type='text'
                              placeholder='Min 1,000'
                              mb='24px'
                              border='0'
                              fontWeight='500'
                              size='lg'              
                              id="inputkrw"
                              name="inputkrw"
                              value={inputkrw}
                              onChange={onChange}
                              readOnly={readOnly}
                              isInvalid
                              errorBorderColor='none'/>
                        }         
                          <InputRightElement width="4.5rem" borderRadius="16px" mt='1%'>
                            <Stack direction='column' >
                              <Badge colorScheme='pink' justify='center'>KRW</Badge>
                            </Stack>
                          </InputRightElement>
                        </InputGroup>
                        <FormLabel
                          ms='4px'
                          fontSize='sm'
                          fontWeight='500'
                          color={textColor}
                          display='flex'>
                            KRW 잔액
                        </FormLabel>
                        <InputGroup>               
                          <Input
                            fontSize='sm'
                            placeholder='총 KRW 잔액'
                            mb='24px'
                            size='lg'
                            border='0'
                            type="text"
                            id="totalKrw"
                            name="totalKrw"
                            value={totalKrw}
                            onChange={onChange}
                            disabled ="true"
                            isInvalid
                            errorBorderColor='none'/>
                          <InputRightElement width="4.5rem" borderRadius="16px"  mb='10px'>
                            <Stack direction='column' >
                              <Badge colorScheme='pink' justify='center'>KRW</Badge>
                            </Stack>
                          </InputRightElement>
                        </InputGroup>         
                        {submitLoading
                          ?
                            <>
                              <Button
                                fontSize='sm'
                                variant='brand'
                                fontWeight='500'
                                w='100%'
                                h='50'
                                mb='24px'
                                onClick={(e) => confirm(e)}>
                                  {btnText}
                              </Button>
                              <Button
                                fontSize='sm'
                                fontWeight='500'
                                w='100%'
                                h='50'
                                mb='24px'
                                variant='outline'
                                colorScheme='black'
                                color = 'silver'
                                onClick={(e) => cancel(e)}>
                                  취소
                              </Button>
                            </>
                          :
                            <>
                              <Button
                                fontSize='sm'
                                variant='outline'
                                colorScheme='blue'
                                fontWeight='500'
                                w='100%'
                                h='50'
                                mb='24px'
                                isLoading
                                loadingText='진행중..'/>
                              <Button
                                fontSize='sm'
                                fontWeight='500'
                                w='100%'
                                h='50'
                                mb='24px'
                                variant='outline'
                                colorScheme='black'
                                color = 'silver'>
                              취소
                            </Button>
                            </>
                        }
                      </FormControl>
                    </form>
                  </Text>
              </Card>
          </Flex>
        </Flex>
    </Card>
  );
}

