// React
import React from "react";
// External Libraries
import { Box, Flex, Text, useColorModeValue, Spacer } from "@chakra-ui/react";

/**
 * 
 * Props list
 *  - label: Refers to the name of each item
 *  - data: Refers to the data of each item
 *  - sum: Refers to the sum of data from all items
 *  - color: Refers to the color of label
 * 
 */
export default function ChartLabel({ label, data, sum, color }) {
  return (
    <Flex direction='row' align='center' justify='center' ml={{ base: "10px", lg: "6px", xl: "6px" }}>
        <Box h='8px' w='8px' bg={color} borderRadius='80%' mr='5px'/>
        <Text
          fontSize = {{ base: "xs", lg: 'xs', xl: 'xs' }}
          color='secondaryGray.700'
          fontWeight='700'
          mr='3px'>
            {label}
        </Text>
        <Spacer />
        <Text fontSize= {{ base: "xs", lg: 'xs', xl: 'xs' }} color={useColorModeValue("secondaryGray.600", "white")} fontWeight='600'>
          {Math.round(data / sum * 100) || 0}%
        </Text>
        <Spacer />
    </Flex>
  );
}
