// React
import React, { useState } from 'react';
// External Libraries
import { Button, Flex, FormControl, FormLabel, Icon, Input, InputGroup, InputRightElement, Text, useColorModeValue, Stat, StatLabel, StatNumber, StatGroup, StatHelpText, Stack, Badge} from "@chakra-ui/react";
import axios from 'axios';
import { MdClose } from "react-icons/md";
import { useDispatch, useSelector } from "react-redux"
// Components
import Card from "components/card/Card";
// Variables
import { changeModalState } from "../../store"
// Assets
import gradient1 from "assets/img/service/gradient1.jpg";

/**
 * 
 * Props list
 *  - titleText           : 모달버튼 상단 텍스트
 *  - accountContactList  : account 의 contact list
 *  - setCheckAxios       : api 호출 성공 여부
 */
export default function KrcTransfer({ titleText, accountContactList, setCheckAxios} ) {
  
  // A variable to use function variables from store.js
  const dispatch = useDispatch()

  // A varible to represent the state of each accountAssetInfo (from store.js)
  let accountAssetInfo = useSelector((state) => { return state.accountAssetInfo } );

  // Variables for Card CSS Styling
  const textColor = useColorModeValue("navy.700", "white");
  const bgButton = useColorModeValue("secondaryGray.300", "whiteAlpha.100");
  const bgHover = useColorModeValue(
    { bg: "secondaryGray.400" },
    { bg: "whiteAlpha.50" }
  );
  const bgFocus = useColorModeValue(
    { bg: "secondaryGray.300" },
    { bg: "whiteAlpha.100" }
  );

  // input data
  const [inputs, setInputs] = useState({
    transferkrc: "",
    target_account_address: "",
  });

  // input variable
  const { transferkrc, target_account_address } = inputs;

   // input style change variable
   const [readOnly, setReadOnly] = useState(false);
   const [btnText, setBtnText] = useState("확인");
   const [inputColor, setInputColor] = useState("none");
 
   // view chage variables for input component
   const [submitLoading, setSubmitLoading] = useState(true);

   
  // When the user enters data, the value is changed to the entered value. 
  function onChange(e) {

    // focus input value
    const { value, name } = e.target;
    
    if(name === "transferkrc"){ // transferkrc input

      // number format ( , -> '' / numbering)
      const  pureKrc = e.target.value.replace(/[^0-9]/g, "").replaceAll(',', '');

      // inputs value change
      setInputs({
        transferkrc: pureKrc.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ','),
        target_account_address :target_account_address
      });

    }else if (name === "target_account_address"){   // target_account_address input

      // inputs value change
      setInputs({
        transferkrc: transferkrc,
        target_account_address :e.target.value
      });

    }else{  

      // inputs value change
      setInputs({
        ...inputs,
        [name]: value,
      });

    } // if else end

  } // fn onChange end

  // input value Check
  function confirm(e){
    e.preventDefault();   
    if(transferkrc.replaceAll(',', '')  > accountAssetInfo.krcAmountHold){  // input max Check
      alert("송금 할 수 있는 최대 금액을 초과 하였습니다.");
      document.getElementById("transferkrc").focus();
    }else if (target_account_address === undefined || target_account_address === null || target_account_address === ''){ // input null Check
      alert('받는사람을 확인 해주세요');
      document.getElementById("target_account_address").focus();
    }else if (transferkrc === undefined || transferkrc === ''){ // input null Check
      alert('송금 금액을 확인해 주세요');
      document.getElementById("transferkrc").focus();
    }else if (target_account_address.length > 42 || target_account_address.substring(0,2) !== '0x'){  // input text format Check
      alert('받는사람의 주소의 형식을 확인해 주세요');
      document.getElementById("target_account_address").focus();      
    }else{      
      if(e.target.textContent == '송금하기'){
        handleSubmit(e);
      }else{
        // input style change
        setReadOnly(true);
        setBtnText("송금하기");
        setInputColor("crimson");
      } // if else end in else
    } // if else end
  }// fn confirm end

  // input value to max 
  function autoMax(){
    // inputs value to max change
    setInputs({
      transferkrc: (accountAssetInfo.krcAmountHold).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',') || 0,  // account total coin amount to String   
      target_account_address: target_account_address
    });
  }// fn autoMax end
  
  // async api call
  async function handleSubmit(e) {
    e.preventDefault();
     // input style change
    setSubmitLoading(false);
    // server api call
    axios.put('/transfer',{
      amount : transferkrc.replaceAll(',', ''),             // ( int )    input Coin
      account_address : accountAssetInfo.address,           // ( String ) current user's account_address
      trade_type : "KRC",                                   // ( String ) KRC
      target_account_address :target_account_address        // ( String ) targer account address
    }).then((response) => {         // api return
      if(response.data === 1){      // api success
        // modal close , button style chage to defult
        cancel(e);
        // axios succ return -> account info api rerender
        setCheckAxios(true);
      }else{                        // api failed
        alert("KRDC Transfer 시 예기치 않은 오류가 발생했습니다.");
        // modal close , button style chage to defult
        cancel(e);
      } // if else end
    })  // api return then end
    .catch(function(){            // api call failed
      console.log("실패..");
    }) 
  }// fn handleSubmit end

  // modal close fn
  function cancel(e){

    e.preventDefault();

    // input style change defult
    setSubmitLoading(true);
    dispatch(changeModalState('krcTransfer'));
    setReadOnly(false);
    setBtnText("확인");
    setInputColor("none");
    setInputs({
      transferkrc: "",     
      target_account_address: ""
    });
  }// fn cancel end

  return (         
    <Card
      direction='row'
      w='100%'
      overflowX={{ sm: "scroll", lg: "hidden" }}
      backgroundImage={gradient1}
      backgroundRepeat='no-repeat'
      bgSize='cover'
      alignSelf='center'
      bgPosition='10%'>
        <Flex direction="column" justify='space-between'>
          <Flex direction='row' pl='30px' pr='13px' justify='space-between' mt='0px' mb='0px' align='center'>
            <Text
              color='whiteAlpha.900'
              mt="20px"
              fontSize='22px'
              fontWeight='700'
              lineHeight='100%'>
                {titleText}
            </Text> 
              {submitLoading
                ?
                  <Button
                    align='center'
                    justifyContent='center'
                    w='37px'
                    h='37px'
                    lineHeight='100%'
                    onClick={(e) => {
                      cancel(e)
                    }}
                    borderRadius='12px'
                    bg={bgButton}
                    _hover={bgHover}
                    _focus={bgFocus}
                    _active={bgFocus}>
                      <Icon as={MdClose} color='blue.800' w='30px' h='30px' />
                  </Button>
                :
                  <Button
                    align='center'
                    justifyContent='center'
                    w='37px'
                    h='37px'
                    lineHeight='100%'
                    borderRadius='12px'
                    bg={bgButton}
                    _hover={bgHover}
                    _focus={bgFocus}
                    _active={bgFocus}>
                      <Icon as={MdClose} color='blue.800' w='30px' h='30px' />
                  </Button>
              }                  
          </Flex>
          <Flex
            direction='row'
            justify='space-between'
            align='center'>
              <Card
                backgroundColor='rgb(255, 255, 255)'
                my='15px'
                w='100%'
                mt='27px'
                mb='2px'
                direction='column'
                overflowX={{ sm: "scroll", lg: "hidden" }}>
                  <Text>
                    <Stat>
                      <StatNumber>KRDC 잔액</StatNumber>
                    </Stat>
                    <br/>
                    <StatGroup>
                      <Stat>
                        <StatLabel>KRDC</StatLabel>
                        <StatNumber>{(accountAssetInfo.krcAmountHold).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',') || 0}</StatNumber>
                      </Stat>
                        <Stat>
                          <StatLabel>KRW</StatLabel>
                          <StatHelpText></StatHelpText>                
                          <StatLabel>{(accountAssetInfo.krcAmountHold*1000).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',') || 0}</StatLabel>
                      </Stat>
                    </StatGroup>
                    <br/>
                    <form onSubmit={handleSubmit}>
                      <FormControl>             
                        <FormLabel
                          display='flex'
                          ms='4px'
                          fontSize='sm'
                          fontWeight='500'
                          color={textColor}
                          mb='8px'>
                            송금액
                        </FormLabel>
                        {!readOnly
                          ?
                            <InputGroup>
                              <Input
                                isRequired={true}
                                fontSize='sm'
                                ms={{ base: "0px", md: "0px" }}
                                type='text'
                                placeholder={(accountAssetInfo.krcAmountHold).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',') || 0}
                                mb='24px'
                                fontWeight='500'
                                size='lg'
                                autocomplete='off'
                                id="transferkrc"
                                name="transferkrc"
                                value={transferkrc}  
                                onChange={onChange}
                                readOnly={readOnly}
                                isInvalid
                                errorBorderColor={inputColor}/>
                              <InputRightElement width="40%" borderRadius="16px" >
                                <Button variant="outline" h="1.75rem" size="sm" onClick={autoMax} borderRadius="10px" backgroundColor='#dee2e6'>
                                  MAX
                                </Button>
                                <Stack direction='column' ml='2%'>
                                  <Badge colorScheme='yellow' justify='center'>KRDC</Badge>
                                </Stack>
                              </InputRightElement>
                            </InputGroup>
                          :
                            <InputGroup>
                              <Input
                                isRequired={true}
                                fontSize='sm'
                                ms={{ base: "0px", md: "0px" }}
                                type='text'
                                placeholder={(accountAssetInfo.krcAmountHold).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',') || 0}
                                mb='24px'
                                fontWeight='500'
                                size='lg'
                                autocomplete='off'
                                id="transferkrc"
                                name="transferkrc"
                                value={transferkrc}
                                onChange={onChange}
                                readOnly={readOnly}
                                isInvalid
                                border='0'
                                errorBorderColor='none'/>
                              <InputRightElement  width="4.5rem" borderRadius="16px"  mb='10px' >
                                <Stack direction='column' ml='2%'>
                                  <Badge colorScheme='yellow' justify='center'>KRDC</Badge>
                                </Stack>
                              </InputRightElement>
                            </InputGroup>
                        } 
                        <FormLabel
                          ms='4px'
                          fontSize='sm'
                          fontWeight='500'
                          color={textColor}
                          display='flex'>
                            받는 사람
                        </FormLabel>
                        {!readOnly
                          ?
                            <Input
                              isRequired={true}
                              fontSize='sm'
                              placeholder='받는사람 address or name'
                              mb='24px'
                              size='lg'
                              type="text"
                              id="target_account_address"
                              name="target_account_address"
                              value={target_account_address}
                              onChange={onChange}
                              autoComplete='off'
                              list="accountContactList" 
                              readOnly={readOnly}
                              isInvalid
                              errorBorderColor={inputColor}/>    
                          :
                            <Input
                              isRequired={true}
                              fontSize='sm'
                              placeholder='받는사람 address or name'
                              mb='24px'
                              size='lg'
                              type="text"
                              id="target_account_address"
                              name="target_account_address"
                              value={target_account_address}
                              onChange={onChange}
                              autoComplete='off'
                              list="accountContactList" 
                              readOnly={readOnly}
                              isInvalid
                              border='0'
                              errorBorderColor='none'/>
                        } {/* !readOnly end */}
                          <datalist id="accountContactList" onChange={onChange}>
                            {accountContactList.map((option) => (
                                <option
                                  value={option.cName}
                                  key={option.title}
                                  name = {option.title}>
                                    {option.title}
                                </option>
                                ))}
                          </datalist> 
                        {submitLoading
                          ?
                            <>
                              <Button
                                fontSize='sm'
                                variant='brand'
                                fontWeight='500'
                                w='100%'
                                h='50'
                                mb='24px'
                                onClick={(e) => confirm(e)}>
                                  {btnText}
                              </Button>
                              <Button
                                fontSize='sm'
                                fontWeight='500'
                                w='100%'
                                h='50'
                                mb='24px'
                                variant='outline'
                                colorScheme='black'
                                color = 'silver'
                                onClick={(e) => cancel(e)}>
                                  취소
                              </Button>
                            </>
                          :
                            <>
                              <Button
                                fontSize='sm'
                                variant='outline'
                                colorScheme='blue'
                                fontWeight='500'
                                w='100%'
                                h='50'
                                mb='24px'
                                isLoading
                                loadingText='진행중..'/>
                              <Button
                                fontSize='sm'
                                fontWeight='500'
                                w='100%'
                                h='50'
                                mb='24px'
                                variant='outline'
                                colorScheme='black'
                                color = 'silver'>
                                  취소
                              </Button>
                            </>
                        } {/*  submitLoading end */}
                      </FormControl>
                    </form>
                  </Text>
              </Card>
          </Flex>
        </Flex>
    </Card>
  );
}