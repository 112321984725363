// React
import React from "react";
// External Libraries
import { useHistory,useLocation } from "react-router-dom";
import {Box, Button,Flex,Heading,Text,useColorModeValue } from "@chakra-ui/react";
// Components
import DefaultLogin from "layouts/login/Default";
// Assets
import illustration from "assets/img/login/auth.png";

/**
 * 
 * Props list - none
 */
export default function ErrorView() {

  // style variables
  const textColor = useColorModeValue("navy.700", "white");

  // route Change variables
  const history = useHistory();

  // state info 
  const location = useLocation();
  const item = location.state.errorCheck;


  return (
    <DefaultLogin illustrationBackground={illustration} image={illustration}>
      <Flex
        maxW={{ base: "100%", md: "max-content" }}
        w='100%'
        mx={{ base: "auto", lg: "0px" }}
        me='auto'
        h='100%'
        alignItems='start'
        justifyContent='center'
        mb={{ base: "30px", md: "60px" }}
        px={{ base: "25px", md: "0px" }}
        mt={{ base: "40px", md: "14vh" }}
        flexDirection='column'>
        <Box me='auto'>
          <Heading color={textColor} fontSize='36px' mb='10px' as="u">
            ERROR PAGE {item.errorCheck}
          </Heading>
          <hr/>
        </Box>
        <Flex
          zIndex='1'
          direction='column'
          w={{ base: "100%", md: "420px" }}
          maxW='100%'
          background='transparent'
          borderRadius='15px'
          mx={{ base: "auto", lg: "unset" }}
          me='auto'
          mb={{ base: "20px", md: "auto" }}>
        </Flex>
        <Text
            backgroundColor='white'
            color='gray'
            as='span'
            ms='5px'
            fontWeight='500'
            fontSize='26px'>
              Identification has been completed<br/><br/>
              You can use the <strong>VenetaKRDC</strong> service<br/><br/>
              Click the button below to go to the login page<br/><br/>
        </Text>
        <br/><br/>
        <Button
              fontSize='sm'
              variant='brand'
              fontWeight='500'
              w='100%'
              h='50'
              mb='24px'
              onClick={() => history.push('/auth')}>
                Move to Login Page
            </Button>
      </Flex>
    </DefaultLogin>
  );
}

