// External Libraries
import { Box, useStyleConfig } from "@chakra-ui/react";

/**
 * 
 * Props list
 *  - children: inner component of Card
 *  - rest: props for CSS Styling
 * 
 */
export default function Card({ children, ...rest }) {
  return (
    <Box __css={useStyleConfig("Card")} {...rest}>
      {children}
    </Box>
  );
}
