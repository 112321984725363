import reactDom from "react-dom";
/**
 * 
 * Props list
 *  - children: Refers to Modal and its related components to be mounted
 * 
 */
const ModalPortal = ({ children }) => {
  // element with the id "modal"
  const el = document.getElementById("modal");
  return reactDom.createPortal(children, el);
};

export default ModalPortal;