// React
import React, { useState } from 'react';
// External Libraries
import { Button, Flex, FormControl, FormLabel, Icon, Input, InputGroup, InputRightElement, Text, useColorModeValue, Stat, StatHelpText, Stack, Badge } from "@chakra-ui/react";
import { MdClose } from "react-icons/md";
import axios from 'axios';
import { useDispatch, useSelector } from "react-redux"
// Components
import Card from "components/card/Card";
// Variables
import { changeModalState } from "../../store"
// Assets
import gradient1 from "assets/img/service/gradient1.jpg";

/**
 * 
 * Props list
 *  - titleText           : 모달버튼 상단 텍스트
 *  - setCheckAxios       : api 호출 성공 여부
 */
 export default function KrcBuy({ titleText,setCheckAxios}) {

  // A variable to use function variables from store.js
  const dispatch = useDispatch();

  // A varible to represent the state of each accountAssetInfo (from store.js)
  let accountAssetInfo = useSelector((state) => { return state.accountAssetInfo } );

  // Variables for Card CSS Styling
  const textColor = useColorModeValue("navy.700", "white");
  const bgButton = useColorModeValue("secondaryGray.300", "whiteAlpha.100");
  const bgHover = useColorModeValue(
    { bg: "secondaryGray.400" },
    { bg: "whiteAlpha.50" }
  );
  const bgFocus = useColorModeValue(
    { bg: "secondaryGray.300" },
    { bg: "whiteAlpha.100" }
  );
  
  // input data
  const [inputs, setInputs] = useState({
    krcBuykrw: "",
    exchangeKrc: "",
  });

  // input variable
  const { krcBuykrw, exchangeKrc } = inputs;

  // input style change variable
  const [readOnly, setReadOnly] = useState(false);
  const [btnText, setBtnText] = useState("확인");
  const [inputColor, setInputColor] = useState("none");

  // view chage variables for input component
  const [submitLoading, setSubmitLoading] = useState(true);

  // When the user enters data, the value is changed to the entered value. 
  function onChange(e) {
    // number format ( , -> '' / numbering)
    var  purekrc = e.target.value.replace(/[^0-9]/g, "").replaceAll(',', '') ;

    // number format String ( add 3 digits , ) 
    const  replacekrc  = e.target.value.replace(/[^0-9]/g, "").replaceAll(',', '').toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
    
    // exChange CASH to COIN ( exchangeKrc )
    purekrc =  '='+' '+(purekrc/1000).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')

    // Check and transform for parsint
    if(e.target.value === undefined || e.target.value === ''){
      purekrc = '';
    }

    // inputs value change
    setInputs({
      krcBuykrw: replacekrc,     
      exchangeKrc: purekrc
    });
  }// fn onChange end

  // input value Check
  function confirm(e){    
    e.preventDefault();
    if(krcBuykrw.replace(/[^0-9]/g, "").replaceAll(',', '')  > accountAssetInfo.krwAmount){  // input max Check
      alert("구매할 수 있는 최대 금액을 초과 하였습니다.");
      document.getElementById("krcBuykrw").focus();
    }else if(krcBuykrw.replace(/[^0-9]/g, "").replaceAll(',', '')  < 1000){  // input min Check
      alert("구매 최소 금액은 1,000원 부터 입니다.");
      document.getElementById("krcBuykrw").focus();
    }else if(krcBuykrw === undefined || krcBuykrw === ''){ // input null Check
      alert("구매 할 금액을 확인해 주세요.");
      document.getElementById("krcBuykrw").focus();
    }else if(parseFloat(krcBuykrw.replaceAll(',', '')) % 1000 !== 0){
      alert("구매 단위는 1,000원 입니다.");
      document.getElementById("krcBuykrw").focus();
    }else{
      if(e.target.textContent == '구매하기'){
        handleSubmit(e);
      }else{
        // input style change
        setReadOnly(true);
        setBtnText("구매하기");
        setInputColor("crimson");
      }// if else end in else
    } // if else end
  }// fn confirm end

  // input value to max 
  function autoMax(){
    // inputs value to max change
    setInputs({
      krcBuykrw: (accountAssetInfo.krwAmount).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',') || 0,                // Total Cash amount     
      exchangeKrc: '='+' '+(accountAssetInfo.krwAmount/1000).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',') || 0  // Total Cash amount / 1000 = > Coin amount
    });
  }// fn autoMax end

  // async api call
  async function handleSubmit(e) {    
    e.preventDefault();
     // input style change
     setSubmitLoading(false);
     // server api call
    axios.put('/buy',{
      amount : exchangeKrc.replaceAll(',', '').replaceAll('=', ''),       // (int )     input Cash
      account_address : accountAssetInfo.address,                         // ( String ) current user's account_address
      coin_type : "KRC"                                                   // ( String ) KRC
    }).then((response) => {           // api return
      if(response.data === 1){        // api success
        // modal close , button style chage to defult
        cancel(e);
        // axios succ return -> account info api rerender
        setCheckAxios(true);
      }else{
        alert("KRDC 구매 실패");
        // modal close , button style chage to defult
        cancel(e);
      } // if else end
    })  // api return then end
    .catch(function(){            // api call failed
      console.log("실패..");
    }) 
  }// fn handleSubmit end
    
  // modal close fn
  function cancel(e){

    e.preventDefault();

    // input style change defult
    setSubmitLoading(true);
    dispatch(changeModalState('krcBuy'));
    setReadOnly(false);
    setBtnText("확인");
    setInputColor("none");
    setInputs({
      krcBuykrw: "",     
      exchangeKrc: ""
    });
  }// fn cancel end
  return (         
    <Card
      direction='row'
      w='100%'
      overflowX={{ sm: "scroll", lg: "hidden" }}
      backgroundImage={gradient1}
      backgroundRepeat='no-repeat'
      bgSize='cover'
      alignSelf='center'
      bgPosition='10%'>
        <Flex direction="column" justify='space-between'>
          <Flex direction='row' pl='30px' pr='13px' justify='space-between' mt='0px' mb='0px' align='center'>
            <Text
              color='whiteAlpha.900'
              mt="20px"
              fontSize='22px'
              fontWeight='700'
              lineHeight='100%'>
                {titleText}
            </Text>
              {submitLoading
                ?
                  <Button
                    align='center'
                    justifyContent='center'
                    w='37px'
                    h='37px'
                    lineHeight='100%'
                    onClick={(e) => {
                      cancel(e)
                    }}
                    borderRadius='12px'
                    bg={bgButton}
                    _hover={bgHover}
                    _focus={bgFocus}
                    _active={bgFocus}>
                      <Icon as={MdClose} color='blue.800' w='30px' h='30px' />
                  </Button>
                :
                  <Button
                    align='center'
                    justifyContent='center'
                    w='37px'
                    h='37px'
                    lineHeight='100%'
                    borderRadius='12px'
                    bg={bgButton}
                    _hover={bgHover}
                    _focus={bgFocus}
                    _active={bgFocus}>
                      <Icon as={MdClose} color='blue.800' w='30px' h='30px' />
                  </Button>
              } {/* submitLoading end */}
          </Flex>
          <Flex
            direction='row'
            justify='space-between'
            align='center'>
              <Card
                backgroundColor='rgb(255, 255, 255)'
                my='15px'
                w='100%'
                mt='27px'
                mb='2px'
                //  mr='10px'
                direction='column'
                overflowX={{ sm: "scroll", lg: "hidden" }}>
                  <Text>
                    <form onSubmit={handleSubmit}>
                      <FormControl>             
                        <FormLabel
                          display='flex'
                          ms='4px'
                          fontSize='sm'
                          fontWeight='500'
                          color={textColor}
                          mb='8px'>
                            구매금액
                        </FormLabel>
                        {!readOnly
                          ?           
                            <InputGroup> 
                              <Input
                                isRequired={true}
                                fontSize='sm'
                                ms={{ base: "0px", md: "0px" }}
                                type='text'
                                placeholder={(accountAssetInfo.krwAmount).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',') || 0}
                                mb='24px'
                                fontWeight='500'
                                size='lg'
                                id="krcBuykrw"
                                name="krcBuykrw"
                                value={krcBuykrw}
                                onChange={onChange}
                                readOnly={readOnly}
                                isInvalid
                                errorBorderColor={inputColor}/>
                              <InputRightElement width="40%" borderRadius="16px" >
                                <Button variant="outline" h="1.75rem" size="sm" onClick={autoMax} borderRadius="10px" backgroundColor='#dee2e6'>
                                  MAX
                                </Button>
                                <Stack direction='column' ml='2%'>
                                  <Badge colorScheme='pink' justify='center'>KRW</Badge>
                                </Stack>
                              </InputRightElement>
                            </InputGroup>
                          :
                            <InputGroup> 
                              <Input
                              isRequired={true}
                              fontSize='sm'
                              ms={{ base: "0px", md: "0px" }}
                              type='text'
                              placeholder={(accountAssetInfo.krwAmount).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',') || 0}
                              mb='24px'
                              fontWeight='500'
                              border='0'
                              size='lg'
                              id="krcBuykrw"
                              name="krcBuykrw"
                              value={krcBuykrw}
                              onChange={onChange}
                              readOnly={readOnly}
                              isInvalid
                              errorBorderColor='none'/>
                              <InputRightElement  width="4.5rem" borderRadius="16px"  mb='10px' >
                                <Stack direction='column' ml='2%'>
                                  <Badge colorScheme='pink' justify='center'>KRW</Badge>
                                </Stack>
                              </InputRightElement>
                            </InputGroup>
                        } {/* !readOnly end */}
                        <FormLabel
                          ms='4px'
                          fontSize='sm'
                          fontWeight='500'
                          color={textColor}
                          display='flex'>
                            KRDC
                        </FormLabel>
                        <InputGroup>
                          <Input
                            isRequired={true}
                            fontSize='sm'
                            placeholder='구매 KRDC '
                            mb='24px'
                            size='lg'
                            type="text"
                            id="exchangeKrc"
                            name="exchangeKrc"
                            value={exchangeKrc}
                            onChange={onChange}
                            readOnly ="true"
                            isInvalid
                            border='0'
                            errorBorderColor='none'/>
                          <InputRightElement width="4.5rem" borderRadius="16px"  mb='10px' >
                            <Stack direction='column' >
                              <Badge colorScheme='yellow' justify='center'>KRDC</Badge>
                            </Stack>
                          </InputRightElement>
                        </InputGroup>
                        {readOnly
                          ?
                            <Stat>
                              <StatHelpText>본 항목은 KRDC구매 전자계약에 준합니다.</StatHelpText>
                            </Stat>              
                          :
                            <Stat>
                              <StatHelpText></StatHelpText>
                            </Stat> 
                        } {/* readOnly end */}
                        {submitLoading
                          ?
                            <>
                              <Button
                                fontSize='sm'
                                variant='brand'
                                fontWeight='500'
                                w='100%'
                                h='50'
                                mb='24px'
                                onClick={(e) => confirm(e)}>
                                  {btnText}
                              </Button>
                              <Button
                                fontSize='sm'
                                //variant='brand'
                                fontWeight='500'
                                w='100%'
                                h='50'
                                mb='24px'
                                variant='outline'
                                colorScheme='black'
                                color = 'silver'
                                onClick={(e) => cancel(e)}>
                                  취소
                              </Button>
                            </>
                          :
                            <>
                              <Button
                                fontSize='sm'
                                variant='outline'
                                colorScheme='blue'
                                fontWeight='500'
                                w='100%'
                                h='50'
                                mb='24px'
                                isLoading
                                loadingText='진행중..'/>
                              <Button
                                fontSize='sm'
                                //variant='brand'
                                fontWeight='500'
                                w='100%'
                                h='50'
                                mb='24px'
                                variant='outline'
                                colorScheme='black'
                                color = 'silver'>
                               취소
                              </Button>
                            </>
                        } {/* submitLoading end */}
                      </FormControl>
                    </form>
                  </Text>
              </Card>
          </Flex>
        </Flex>
    </Card>
  );
}
