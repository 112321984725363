// React
import React, { useRef } from "react";
// External Libraries
import { Route, Switch,useHistory } from "react-router-dom";
import { useGlobalFilter, usePagination, useSortBy, useTable, useFilters } from "react-table";
import { Flex, Table, Button, Spacer, Tbody, Td, Text, Th, Thead, Tr, useColorModeValue, VisuallyHidden,IconButton, Popover, PopoverTrigger, PopoverContent, PopoverHeader, PopoverBody, PopoverArrow, PopoverCloseButton, Center,
    Accordion, AccordionIcon, AccordionItem, AccordionButton, Box, AccordionPanel} from "@chakra-ui/react";
import axios from 'axios';
// Components
import Card from "components/card/Card";
import AccordionTable from "./AccordionTable";
import UserTableController from "./UserTableController";
import ColumnSearchbar from "components/searchbar/ColumnSearchbar"
import FilterPopover  from "./FilterPopover";
import { columnsDataAccordion } from "variables/admin/columnsDataAccordion";
import FilterSearchbar from "components/searchbar/FilterSearchbar"
import { MdClose } from "react-icons/md";
// Assets
import gradient1 from "assets/img/service/gradient1.jpg";

export default function UserTableContent({ columns, data,setApiStatus }) {
    const history = useHistory();
    // Variables to set a focus towards the controller and popover, respectively
    const controllerRef = useRef();
    var filterList = [];
    const initialFocusRef = useRef();

    // Varaibles for TxnTableTest (react-table)
    const {
      getTableBodyProps,
      headerGroups,
      page,
      prepareRow,
      initialState,
      canPreviousPage,
      canNextPage,
      pageOptions,
      pageCount,
      gotoPage,
      nextPage,
      previousPage,
      setPageSize,
      state,
      preGlobalFilteredRows,
      setGlobalFilter,
      state : {pageIndex, pageSize}
    } = useTable(
        { columns, data, defaultColumn: {Filter: ColumnSearchbar} },
        useFilters,
        useGlobalFilter,
        useSortBy,
        usePagination
    );
    initialState.pageSize = 10;
    
    // Variables for CSS Styling
    const textColor = useColorModeValue("secondaryGray.900", "white");
    const borderColor = useColorModeValue("gray.200", "whiteAlpha.100");

    // async api call
    async function handleSubmit(e) {
        console.log("handle submit: ", e.target.value);    
        e.preventDefault();
        if(e.target.value === '' || e.target.value === undefined){
            alert("email의 값이 없음");
        }else{
            // server api call
            axios.put('/admin/userDelete',{
                email : e.target.value
            }).then((response) => {           // api return
                if(response.data === 1){        // api success
                    alert('삭제 성공'); 
                }else{
                    alert('삭제 실패');
                } // if else end
                document.getElementById(e.target.value).click();
                setApiStatus(true);
            })  // api return then end
            .catch(function(){            // api call failed
                console.log("실패..");
            }) 
        }
    }// fn handleSubmit end  
    
    return (
        <Card
            direction='row'
            w='100%'
            overflowX={{ base: "scroll", sm: "scroll", lg: "hidden" }}
            backgroundImage={gradient1}
            backgroundRepeat='no-repeat'
            bgSize='cover'
            alignSelf='center'
            bgPosition='10%'>
        <Flex direction="column" justify='space-between'>
        <Flex direction='row' justify='space-between'align='center'  mt='35px' mb='0px' px='20px' pl='35px'>
            <Text
                isTruncated
                color='whiteAlpha.900'
                fontSize={{ base: "18px", sm: "20px", lg: "25px" }}
                fontWeight='700'
                lineHeight='100%'
                overflowX='hidden'
                overflowY='hidden'
                w='30%'>
                User Overview
            </Text>
            <Spacer />
            <FilterSearchbar
                preGlobalFilteredRows={preGlobalFilteredRows}
                globalFilter={state.globalFilter}
                setGlobalFilter={setGlobalFilter}/>
            <FilterPopover filterList={filterList}/>   
            </Flex>
        <Flex
          direction='column'
          justify='center'
          align='center'
          px='20px'>
           <Card
            backgroundColor='rgb(255, 255, 255)'
            w='100%'
            mt='40px'
            mb='5px'
            direction='column'
            overflowX={{ base: "scroll", sm: "scroll", lg: "scroll" }}>
            
            <Table variant='simple' color='gray.500' mb='0px' >
            <Thead>
                {headerGroups.map((headerGroup, index) => (
                <Tr {...headerGroup.getHeaderGroupProps()} key={index}>
                    {headerGroup.headers.map((column, index) => (
                    <Th
                        {...column.getHeaderProps(column.getSortByToggleProps())}
                        key={index}
                        borderColor={borderColor}>
                        <Flex
                            justify='center'
                            align='center'zw
                            fontSize={{ sm: "10px", lg: "12px" }}
                            color='gray.400'
                            >
                            {column.render("Header")}
                        </Flex>
                        <VisuallyHidden>{filterList.push(column.render("Filter"))}</VisuallyHidden>
                    </Th>
                    ))}
                </Tr>
                ))}
            </Thead>
            <Tbody {...getTableBodyProps()}>
                {page.map((row, index) => {
                    prepareRow(row);
                        return (
                        <Tr {...row.getRowProps()} key={index}>
                            {row.cells.map((cell, index) => {
                            
                            // Variable for each <Td> in TxnTable
                            let data = "";
                            
                            if (cell.column.Header === "NAME") {
                                data = (
                                    <Text color={textColor} fontSize='sm' fontWeight='500' align='center'>
                                        {cell.value}
                                        <Popover
                                                initialFocusRef={initialFocusRef}
                                                orientation='horizontal'
                                                placement='top'
                                                closeOnBlur={true} >
                                                <PopoverTrigger>
                                                    <IconButton
                                                        aria-label='More Address Info'
                                                        icon={<Center w='75%' h='100%' pb={1}><MdClose color="hotpink"/></Center>}
                                                        p='0px'
                                                        isActive={false}
                                                        variant='unstyled'
                                                        size='sm'
                                                        isRound = {false}
                                                        flip={false}/>
                                                </PopoverTrigger>
                                                <PopoverContent color='white' bg='blue.800' borderColor='blue.800' marginLeft='30px' width='230px'>
                                                    <PopoverHeader pt={4} fontSize='15px' fontWeight='600' border='0'>
                                                      유저 삭제
                                                    </PopoverHeader>
                                                    <PopoverArrow />
                                                    <PopoverCloseButton id={cell.row.original.email} />
                                                    <PopoverBody fontWeight='300' fontSize='md' pb='20px'>
                                                        <Text w='90%' textAlign='center'>
                                                            유저&nbsp; : &nbsp; 
                                                            <Text as="span" fontWeight='600' textAlign='center'>
                                                                {cell.value}
                                                            </Text>
                                                            <br/>
                                                            정말로 삭제하시겠습니까?
                                                        </Text>
                                                        <br/>
                                                        <Button colorScheme='whiteAlpha' size='sm' type='submit'onClick={(e) => handleSubmit(e)} value={cell.row.original.email}> 확인했습니다 </Button>
                                                    </PopoverBody>
                                                </PopoverContent>
                                            </Popover>
                                    </Text>
                                );
                            } else if (cell.column.Header === "EMAIL") {
                                data = (
                                    <Text color={textColor} fontSize='sm' fontWeight='500' align='center'>
                                        {cell.value}
                                    </Text>
                                );
                            } else if (cell.column.Header === "DATE") {
                                data = (
                                    <Text color={textColor} fontSize='sm' fontWeight='500' align='center'>
                                        {cell.value.length > 0
                                           ? cell.value.slice(0,16)
                                           : cell.value
                                        }
                                    </Text>
                                );
                            } else if (cell.column.Header === "ACCOUNTS") {
                                data = (
                                    <Center>
                                    <Accordion allowMultiple borderColor='white' justify='center' align='center'>
                                        <AccordionItem justify='center' align='center'>
                                            <AccordionButton justify='center' align='center' p='1px' iconSpacing='2px'>
                                                
                                            <Box flex='1' textAlign='left' w='348.55px'>
                                                        <Text color='gray.700' fontSize='sm' fontWeight='600' align='center' justify='center' >
                                                            총 {cell.value.length}개의 계좌
                                                        </Text>
                                                    </Box>
                                                    <AccordionIcon />
                                                
                                            </AccordionButton>
                                            
                                            <AccordionPanel p={4}>
                                                <AccordionTable
                                                    columns = {columnsDataAccordion}
                                                    data = {cell.value}/>
                                            </AccordionPanel>
                                        </AccordionItem>
                                        </Accordion></Center>
                                );
                            }

                            return (
                                <Td
                                    key={index}
                                    maxH='30px !important'
                                    py='10px'
                                    minW={{ sm: "150px", md: "200px", lg: "auto" }}
                                    borderColor='transparent'
                                    justify='center'
                                    align='center'>
                                    {data}
                                </Td>
                            );
                        })} 
                        </Tr>
                        )}
                    )}
                </Tbody>
            </Table>
            <br />
            <UserTableController
                pageCount={pageCount}
                gotoPage={gotoPage}
                previousPage={previousPage}
                pageIndex={pageIndex}
                nextPage={nextPage}
                canPreviousPage={canPreviousPage}
                canNextPage={canNextPage}
                pageOptions={pageOptions}
                initialFocusRef={controllerRef}
                pageSize={pageSize}
                setPageSize={setPageSize} />
        </Card>

       
    </Flex>
    </Flex> </Card>
    );
}