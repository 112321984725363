// React
import React from "react";
// External Libraries
import { useGlobalFilter, usePagination, useSortBy, useTable } from "react-table";
import { Flex, Table, Tbody, Td, Text, Th, Thead, Tr, useColorModeValue, Tooltip} from "@chakra-ui/react";
// Components
import Card from "components/card/Card";

export default function AccordionTable({ columns, data }) {
    // Varaibles for TxnTableTest (react-table)
    const {
      getTableBodyProps,
      headerGroups,
      page,
      prepareRow,
      initialState,
      canPreviousPage,
      canNextPage,
      pageOptions,
      pageCount,
      gotoPage,
      nextPage,
      previousPage,
      setPageSize,
      state : {pageIndex, pageSize}
    } = useTable(
        { columns, data },
        useGlobalFilter,
        useSortBy,
        usePagination
    );
    initialState.pageSize = 10;
    
    // Variables for CSS Styling
    const textColor = useColorModeValue("secondaryGray.900", "white");
    const borderColor = useColorModeValue("gray.200", "whiteAlpha.100");
    
    return (
      <Card
        backgroundColor='rgb(255, 255, 255)'
        w='100%'
        direction='column'
        overflowX='scroll'
        p='0px'
        m='0px'>
        <Table variant='simple' color='gray.500' p='0px' m='0px' >
          <Thead>
            {headerGroups.map((headerGroup, index) => (
              <Tr {...headerGroup.getHeaderGroupProps()} key={index}>
                  {headerGroup.headers.map((column, index) => (
                  <Th
                    {...column.getHeaderProps(column.getSortByToggleProps())}
                    key={index}
                    borderColor={borderColor}
                    p = '3px'>
                      <Flex
                          justify='center'
                          align='center'
                          fontSize='10px'
                          fontWeight='500'
                          color='gray.500'
                          my='3px'>
                          {column.render("Header")}
                      </Flex>
                  </Th>
                  ))}
              </Tr>
            ))}
          </Thead>
        <Tbody {...getTableBodyProps()}>
            {page.map((row, index) => {
                prepareRow(row);
                  return (
                    <Tr {...row.getRowProps()} key={index}>
                        {row.cells.map((cell, index) => {
                        
                        // Variable for each <Td> in TxnTable
                        let data = "";
                        
                        if (cell.column.Header === "NAME") {
                            data = (
                                <Text color={textColor} fontSize='xs' fontWeight='300' align='center'>
                                    {cell.value}
                                </Text>
                            );
                        } else if (cell.column.Header === "ADDRESS") {
                            data = (
                                <Tooltip label={cell.value}>
                                    <Text color={textColor} fontSize='xs' fontWeight='300' align='center'>
                                        {cell.value.slice(0,5)+'...'}
                                    </Text>
                                </Tooltip>
                            );
                        } else if (cell.column.Header === "KRDC") {
                            data = (
                                <Text color={textColor} fontSize='xs' fontWeight='300' align='center'>
                                    {cell.value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                                </Text>
                            );
                        } else if (cell.column.Header === "KRW") {
                          data = (
                              <Text color={textColor} fontSize='xs' fontWeight='300' align='center'>
                                  {cell.value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                              </Text>
                          );
                      }
                        return (
                            <Td
                              key={index}
                              maxH='30px !important'
                              p='10px'
                              minW={{ xs: "150px", md: "200px", lg: "auto" }}
                              borderColor='transparent'>
                              {data}
                            </Td>
                        );
                    })} 
                    </Tr>
                    )}
                )}
            </Tbody>
        </Table>
      </Card>
    );
}