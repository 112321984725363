// React
import React from "react";

// External Libraries
import PropTypes from "prop-types";
import { Scrollbars } from "react-custom-scrollbars-2";
import { Box, useColorModeValue } from "@chakra-ui/react";

// Components
import SidebarConsoleContent from "./SidebarConsoleContent";
import { renderThumb, renderTrack, renderView } from "components/scrollbar/Scrollbar";

/**
 * 
 * Props list
 *  - routes: routes generated by index.js; contains the email and name info
 * 
 */
export default function SidebarConsole({ routes }) {

  // Variables for CSS Styling
  let variantChange = "0.2s linear";
  let shadow = useColorModeValue( "14px 17px 40px 4px rgba(112, 144, 176, 0.08)", "unset" );
  let sidebarBg = useColorModeValue("white", "navy.800");
  let sidebarMargins = "0px";

  return (
    <Box display={{ base:"none", sm: "none", xl: "block" }} position='fixed' minH='100%'>
      <Box
        bg={sidebarBg}
        transition={variantChange}
        w='300px'
        h='100vh'
        m={sidebarMargins}
        minH='100%'
        overflowX='hidden'
        boxShadow={shadow}>
        <Scrollbars
          autoHide
          renderTrackVertical={renderTrack}
          renderThumbVertical={renderThumb}
          renderView={renderView}>
          <SidebarConsoleContent routes={routes} />
        </Scrollbars>
      </Box>
    </Box>
  );
}

// Prop types
SidebarConsole.propTypes = {
  logoText: PropTypes.string,
  routes: PropTypes.arrayOf(PropTypes.object),
  variant: PropTypes.string,
};
