// React
import React, {useState, useEffect} from "react";
// External Libraries
import axios from 'axios';
import { useHistory } from "react-router-dom";
import { ChevronDownIcon } from '@chakra-ui/icons';
import { Button, Flex, Menu, MenuButton, MenuItem, MenuList, Text, useColorModeValue, Center } from "@chakra-ui/react";
import { MdAssignment, MdAssignmentInd, MdAssignmentTurnedIn } from "react-icons/md"
// Components
//import SidebarResponsive from "components/sidebar/SidebarResponsive";
// Variables
// import { changeModalState } from "../../store"

/**
 * 
 * Props list
 *  - routes: routes generated by index.js; contains the email and name info
 *  - accountName: the accout name of the address; only for the mobile mode
 * 
 */
export default function NavbarLinks({ }) {

  const history = useHistory();
  
  // Variables for CSS Styling
  let menuBg = useColorModeValue("white", "navy.800");
  const textColor = useColorModeValue("secondaryGray.900", "white");
  const borderColor = useColorModeValue("#E6ECFA", "rgba(135, 140, 189, 0.3)");
  const shadow = useColorModeValue(
    "14px 17px 40px 4px rgba(112, 144, 176, 0.18)",
    "14px 17px 40px 4px rgba(112, 144, 176, 0.06)"
  );
  
  // A function variable for the logout functionality
  function logout(){
    axios.get('/logout').then((response) => {
        window.location.replace("/");    
      })
  }

  return (
    <Flex
      w={{ sm: "100%", md: "auto" }}
      alignItems='center'
      flexDirection='row'
      bg={menuBg}
      flexWrap="unset"
      borderRadius='20px'
      boxShadow={shadow}
      padding='5px'
      pl='10px'
      justify='center'>
      
      <Menu>
        <MenuButton as={Button} me='5px' ml='10px'>
          <Flex direction='row'overflow='hidden' align='center'>
            <Text fontSize={{ base: '11px', md: '15px', lg: '17px' }} mr='5px' fontWeight='700'>
              Menu
            </Text>
            <Center mt='1px'><ChevronDownIcon color='#2D3748'/></Center>
          </Flex>
        </MenuButton>
        <MenuList
          boxShadow={shadow}
          p='0px'
          mt='10px'
          borderRadius='20px'
          bg={menuBg}
          border='none'>
          <Flex w='100%' mb='0px'>
            <Text
              ps='20px'
              pt='16px'
              pb='10px'
              w='100%'
              borderBottom='1px solid'
              borderColor={borderColor}
              fontSize='sm'
              fontWeight='700'
              color={textColor}>
              📈&nbsp; 관리자용 부가기능 &nbsp;
            </Text>
          </Flex>
          <Flex flexDirection='column' p='10px'>
            <MenuItem
              _hover={{ bg: "none" }}
              _focus={{ bg: "none" }}
              color='gray.700'
              borderRadius='8px'
              px='14px'
              my='10px'
              onClick={()=>history.push('/console/asset')}>
              <Flex flexDirection='row'>
                <Center mr='5px'><MdAssignment color='#4A5568'/></Center>
                <Text fontSize='sm' fontWeight="600" as="span" isTruncated>Asset Management</Text>
              </Flex>
            </MenuItem>
            <MenuItem
              _hover={{ bg: "none" }}
              _focus={{ bg: "none" }}
              color='gray.700'
              borderRadius='8px'
              px='14px'
              my='10px'
              onClick={()=>history.push('/console/transaction')}>
              <Flex flexDirection='row'>
                <Center mr='5px'><MdAssignmentInd color='#4A5568'/></Center>
                <Text fontSize='sm' fontWeight="600" as="span" isTruncated>Transaction History</Text>
              </Flex>
            </MenuItem>
            <MenuItem
              _hover={{ bg: "none" }}
              _focus={{ bg: "none" }}
              color='gray.700'
              borderRadius='8px'
              px='14px'
              my='10px'
              onClick={()=>history.push('/console/user')}>
              <Flex flexDirection='row'>
                <Center mr='5px'><MdAssignmentTurnedIn color='#4A5568'/></Center>
                <Text fontSize='sm' fontWeight="600" as="span" isTruncated>User List</Text>
              </Flex>
            </MenuItem>
          </Flex>
        </MenuList>
      </Menu>
      
      <Menu>
        <MenuButton as={Button} me='5px' ml='10px'>
          <Flex direction='row'overflow='hidden' align='center'>
            <Text fontSize={{ base: '11px', md: '15px', lg: '17px' }} mr='5px' fontWeight='700'>
              Admin
            </Text>
          </Flex>
        </MenuButton>
        <MenuList
          boxShadow={shadow}
          p='0px'
          mt='10px'
          borderRadius='20px'
          bg={menuBg}
          border='none'>
          <Flex w='100%' mb='0px'>
            <Text
              ps='20px'
              pt='16px'
              pb='10px'
              w='100%'
              borderBottom='1px solid'
              borderColor={borderColor}
              fontSize='sm'
              fontWeight='700'
              color={textColor}>
              🔒&nbsp; Welcome, Admin &nbsp;
            </Text>
          </Flex>
          <Flex flexDirection='column' p='10px'>
            <MenuItem
              _hover={{ bg: "none" }}
              _focus={{ bg: "none" }}
              color='red.400'
              borderRadius='8px'
              px='14px'>
              <Text fontSize='sm' fontWeight="600" onClick={logout}>Log out</Text>
            </MenuItem>
          </Flex>
        </MenuList>
      </Menu>
    </Flex>
  );
}
