// React
import React from "react";
// External Libraries
import { useSelector } from "react-redux"
import { Text, useColorModeValue, Spacer } from "@chakra-ui/react";
// Components
import Card from "components/card/Card.js";
import ChartLabel from "components/charts/ChartLabel";
import DonutChart from "components/charts/DonutChart"
// Variables
import { assetChartOptions } from "variables/assetChart/assetKRC";

/**
 * 
 * Props list
 *  - title: string for the chart title
 * 
 */
export default function AssetChartRight({ title }) {
  // A varible to represent current account info (from store.js)
  let accountAssetInfo = useSelector((state) => { return state.accountAssetInfo } );
  // Variables for the DonutChart
  const assetChartData = [accountAssetInfo.krcAmountHold, accountAssetInfo.krcAmountSent];
  const sum = accountAssetInfo.krcAmountHold + accountAssetInfo.krcAmountSent;

  return (
    <Card align='center' direction='column' w='100%' h='100%' justify='center' p='0px'>
      <Text color={useColorModeValue("secondaryGray.900", "white")} fontSize='lg' fontWeight='600' pt='23px'>
        {title}
      </Text>
      <DonutChart 
        chartData={assetChartData}
        chartOptions={assetChartOptions}/>
      <Card
        bg='{cardColor}'
        flexDirection='row'
        w='100%'
        my='20px'
        py='10px'>
        <Spacer />
        { assetChartOptions.labels.map((item,index)=>{
          return <ChartLabel label={item} data={assetChartData[index]} sum={sum} color={assetChartOptions.colors[index]}/>;
        })}
        <Spacer />
      
      </Card>
    </Card>
  );
}
