// React
import React, { useState } from "react";
// External Libraries
import { MdSettings, MdSavedSearch } from "react-icons/md";
import { Flex, Text, Spacer, Button, IconButton, useColorModeValue, Popover, PopoverTrigger, PopoverContent, PopoverHeader, PopoverBody, PopoverFooter, PopoverArrow, PopoverCloseButton, FormControl, FormLabel, NumberInput, NumberInputField, NumberInputStepper, NumberIncrementStepper, NumberDecrementStepper } from "@chakra-ui/react";
import {DetailSearch, DetailSearchBox} from 'components/searchbar/stylesFilterSearchbar'
import { HSeparator } from "components/separator/Separator";

export default function FilterPopover({ initialFocusRef, filterList  }) {
  

  const onCheckEnter = (e) => {
      if(e.key === 'Enter') {
        //   gotoPage(goPage);
      }
  }

  return (
    <Popover
        initialFocusRef={initialFocusRef}
        placement='bottom'
        closeOnBlur={true}
        flip={false}>
          <PopoverTrigger>
            <DetailSearchBox>
                <DetailSearch/>
            </DetailSearchBox>
          </PopoverTrigger>
          <PopoverContent color='white' bg='blue.800' borderColor='blue.800' mr='10px'>
            <PopoverHeader pt={4} fontWeight='bold' border='0'>
              <Flex direction='row' align='center' justify='center' my='5px'>
                <MdSavedSearch />
                <Text as='span' ml='6px'fontSize='17px'>고급검색기능</Text>
              </Flex>
            </PopoverHeader>
            <PopoverArrow />
            <PopoverCloseButton />
        
            <PopoverBody>

              <Flex direction='column' >
                <HSeparator/>
                <HSeparator/>
                <HSeparator/>
                
                <Flex
                  direction='column'
                  justify='center'
                  align='center'
                  my='15px'>

                  {filterList[0]}
                  <Spacer />
                  {filterList[1]}
                  <Spacer />
                  {filterList[2]}
                </Flex>
                
                <HSeparator/>
              
              </Flex>

            </PopoverBody>
          
            <PopoverFooter
              border='0'
              display='flex'
              alignItems='center'
              justifyContent='center'
              pb={4} />
          </PopoverContent>
        </Popover>

  );
}