// React
import React, { useEffect, useRef } from "react";
// External Libraries
import { BsInfoCircle } from "react-icons/bs";
import { CopyIcon } from '@chakra-ui/icons'
import { useClipboard, Box, Flex, Badge, Text, useColorModeValue, IconButton, Popover, PopoverTrigger, PopoverContent, PopoverHeader, PopoverBody, PopoverArrow, PopoverCloseButton, Center } from "@chakra-ui/react";

/**
 * 
 * Props list
 *  - date: date data in the form of yyyy.mm.dd hh:mm
 *  - account: account data in the form of {contactName^accountName}
 *  - amount: amount data in the unit of KRC/KRW
 *  - remark: transaction type data
 *  - balance: balance data in the unit of KRC/KRW
 * 
 */
export default function TxnCard({ date, account, amount, remark, balance }) {
    
    // A variable for useRef()
    const initialFocusRef = useRef();

    // Variables for Card CSS Styling
    let boxBg = useColorModeValue("white !important", "#111c44 !important");
    let secondaryBg = useColorModeValue("gray.50", "whiteAlpha.100");
    const bgShadow = useColorModeValue("14px 17px 40px 4px rgba(112, 144, 176, 0.3)", "unset");
    let mainText = useColorModeValue("gray.800", "white");
    
    // Variables for TxnCard data
    const idxAccount = account.indexOf('^');
    const address = account.slice(0,idxAccount);
    const contact = account.slice(idxAccount+1);
    
    // Variables for TxnCard data CSS Styling
    const color = amount.charAt(0) === '-' ? 'blue' : 'red';
    const badgeColor = amount.charAt(0) === '-' ? 'blue.400' : 'red.400';
    
    // A variable to handle Copy&Paste functionality
    const { hasCopied, onCopy } = useClipboard(address);
    
    // A functionality to tell user about the current status of copy&paste
    useEffect(()=>{
        if (hasCopied === true) alert("Successfully copied to the clipboard!");
    },[hasCopied]);
    
    if (account !== undefined) {
        return (
            <Flex
                borderRadius='20px'
                bg={boxBg}
                w='100%'
                my='8px'
                direction='column'
                shadow={bgShadow}>

                <Box p='20px' pb='10px'>
                    <Flex
                        w='100%'
                        mb='5px'
                        flexDirection={{
                            base: "row",
                            sm: "row",
                        }}>
                        <Text
                            fontSize='0.8em'
                            as='span'
                            my='auto'
                            fontWeight='600'
                            color={mainText}
                            textAlign='center'
                            me='auto'>
                            {date}
                        </Text>
                        <Badge
                            fontSize='0.8em'
                            fontWeight='500'
                            borderRadius='9px'
                            size='md'
                            colorScheme=''
                            color={badgeColor}
                            textAlign='center'
                            display='flex'
                            justifyContent='center'
                            alignItems='center'
                            me='0px'
                            mb='0px'>
                            {remark}
                        </Badge>
                    </Flex>

                    <Flex
                        w='100%'
                        h='100%'
                        mb='0px'
                        pb='10px'
                        flexDirection={{
                            base: "row",
                            sm: "row",
                        }}
                        justifyContent ='center'
                        alignItems='center'>
                        <Text
                            as='span'
                            my='0px'
                            py='0px'
                            fontWeight='300'
                            color={mainText}
                            textAlign='left'
                            fontSize={{base:'16px', sm: '26px'}}
                            me='auto'
                            >
                            {contact}
                            <Popover
                                initialFocusRef={initialFocusRef}
                                orientation='horizontal'
                                placement='top'
                                closeOnBlur={true} >
                                <PopoverTrigger>
                                    <IconButton
                                        aria-label='More Address Info'
                                        icon={<Center w='75%' h='100%' pb={1}><BsInfoCircle color="gray.500"/></Center>}
                                        p='0px'
                                        isActive={false}
                                        variant='unstyled'
                                        size='sm'
                                        isRound = {false}/>
                                </PopoverTrigger>
                                <PopoverContent color='white' bg='blue.800' borderColor='blue.800' marginLeft='30px' width='230px'>
                                    <PopoverHeader pt={4} fontSize='18px' fontWeight='300' border='0' pl='20px'>
                                        {contact}
                                        <IconButton
                                            aria-label='More Address Info'
                                            icon={<Center w='80%' h='100%' pb={1}><CopyIcon color="white"/></Center>}
                                            onClick={onCopy}
                                            p='0px'
                                            isActive={false}
                                            variant='unstyled'
                                            size='sm'
                                            isRound = {false} />
                                    </PopoverHeader>
                                    <PopoverArrow />
                                    <PopoverCloseButton />
                                    <PopoverBody fontWeight='200'fontSize='md' pl='20px' pb='20px'>
                                        <Text w='90%'>{address}</Text>
                                    </PopoverBody>
                                </PopoverContent>
                            </Popover>
                        </Text>
                        <Text
                            as='span' 
                            my='auto'
                            fontWeight='400'
                            color={color}
                            textAlign='right'
                            fontSize={{base:'12pt', sm: 'xl'}}
                            me='0px'>
                            {amount}
                        </Text>
                    </Flex>
                </Box>
                <Flex
                    bg={secondaryBg}
                    w='100%'
                    px='20px'
                    py={{base:'7pt', sm: '15px'}}
                    borderBottomLeftRadius='inherit'
                    borderBottomRightRadius='inherit'
                    height='100%'
                    direction='column'
                    justify='center'>
                    <Flex
                        w='100%'
                        flexDirection="row"
                        justify='right'>
                        <Text
                            as='span'
                            my='auto'
                            fontWeight='300'
                            color={mainText}
                            textAlign='right'
                            fontSize={{base:'12pt', sm: 'xl'}}
                            me='0px'
                            >
                            {balance}
                        </Text>
                    </Flex>
                </Flex>
            </Flex>
        );
    } else { // account === undefined
        return(<></>);
    }
}