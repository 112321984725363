// React
import React, { useEffect,useState,useMemo } from "react";
// External Libraries
import { useGlobalFilter, usePagination, useSortBy, useTable } from "react-table";
import { BiWon, BiCoin } from "react-icons/bi";
import { MdAddShoppingCart } from "react-icons/md";
import { Flex, Table, Progress, Icon, Tbody, Td, Text, Th, Thead, Tr, useColorModeValue } from "@chakra-ui/react";
// Components
import Card from "components/card/Card";
import ModalButton from "components/modal/ModalButton";
import KrdcDepositAdmin from "variables/modal/KrdcDepositAdmin";
import KrwDepositAdmin from "variables/modal/KrwDepositAdmin";

/**
 * 
 * Props list
 *  - columnsData: data for the column names of the table
 *  - tableData: data for the table
 *  - sum: data required for the ratio information
 *  - setApiStatus: data for Modal
 * 
 */
export default function AssetTable({ columnsData, tableData, sum, setApiStatus}) {
  console.log("td: ", tableData)
  // Varaibles for AssetTable (react-table)
  const data = useMemo(() => tableData, [tableData]);
  const columns = useMemo(() => columnsData, [columnsData]);
  const { getTableProps, getTableBodyProps, headerGroups, page, prepareRow, initialState }
    = useTable( { columns, data }, useGlobalFilter, useSortBy, usePagination );
  initialState.pageSize = 5;

  // Variables for CSS Styling
  const textColor = useColorModeValue("secondaryGray.900", "white");
  const borderColor = useColorModeValue("gray.200", "whiteAlpha.100");

  // Variables for ModalButton handling
  var modalContent, modalType, buttonTxt = "";
  useEffect(() => {    
    console.log("sjehfeksjhfkjeshfkes");
  },[tableData]);
  const modalContentKrw = <KrwDepositAdmin titleText = "VENETA KRW 충전하기" setApiStatus={setApiStatus} venetaKrw={tableData[0].balance}/>
  const modalContentKrc = <KrdcDepositAdmin titleText = "VENETA KRDC 충전하기" setApiStatus={setApiStatus} venetaKrdc={tableData[1].balance}/>



  return (
    <Card
      direction='column'
      w='100%'
      px='0px'
      overflowX={{ sm: "scroll", lg: "hidden" }}>
      <Flex px='25px' justify='space-between' mb='10px' align='center'>
        <Text
          mt="20px"
          color={textColor}
          fontSize='22px'
          fontWeight='700'
          lineHeight='100%'>
          VENETA ASSET
        </Text>
      </Flex>
      <Table {...getTableProps()} variant='simple' color='gray.500' mb='24px' mt="100px">
        <Thead>
          {headerGroups.map((headerGroup, index) => (
            <Tr {...headerGroup.getHeaderGroupProps()} key={index}>
              {headerGroup.headers.map((column, index) => (
                <Th
                  {...column.getHeaderProps(column.getSortByToggleProps())}
                  key={index}
                  borderColor={borderColor}>
                  <Flex
                    justify='center'
                    align='center'
                    fontSize={{ sm: "10px", lg: "12px" }}
                    color='gray.400'
                    mb='5px'>
                    {column.render("Header")}
                  </Flex>
                </Th>
              ))}
            </Tr>
          ))}
          
        </Thead>
        <Tbody {...getTableBodyProps()}>
          {page.map((row, index) => {
            prepareRow(row);
            return (
              <Tr {...row.getRowProps()} key={index}>
                {row.cells.map((cell, index) => {
                  let data = "";
                  if (cell.column.Header === "NAME") {
                    data = (
                      <Flex align='center' justify='center'>
                        <Icon
                          w='24px'
                          h='24px'
                          me='5px'
                          color= "blue.600"
                          mr='15px'
                          as={
                            cell.value === "KRW"
                              ? BiWon
                              : cell.value === "KRDC"
                              ? BiCoin
                              : null
                          }
                        />
                      <Text color={textColor} fontSize='sm' fontWeight='700'>
                        {cell.value}
                      </Text>
                      </Flex>
                    );
                    // For modal .jsx component
                    modalContent = (cell.value == "KRW")
                                    ? modalContentKrw
                                    : cell.value == "KRDC"
                                    ? modalContentKrc
                                    : ""

                    modalType = (cell.value == "KRW")
                                    ? "krwDepositAdmin"
                                    : cell.value == "KRDC"
                                    ? "krdcDepositAdmin"
                                    : ""

                    buttonTxt = (cell.value == "KRW")
                                    ? "Deposit"
                                    : cell.value == "KRDC"
                                    ? "Deposit"
                                    : ""

                  } else if (cell.column.Header === "BALANCE") {
                    data = (
                      <Flex align='center' justify='center'>
                        <Text color={textColor} fontSize='sm' fontWeight='700'>
                          {cell.value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                        </Text>
                      </Flex>
                    );
                  }  else if (cell.column.Header === "RATIO") {
                    cell.value !== 0
                    ? data = (
                      <Flex align='center' justify='center'>
                        <Progress
                          variant='table'
                          colorScheme='brandScheme'
                          h='8px'
                          w='108px'
                          value={cell.value/sum*100}
                        />
                        <Text
                          fontSize='13px'
                          fontWeight='500'
                          ml='10px'>
                            {Math.round(cell.value/sum*100)}%
                        </Text>
                      
                      </Flex>
                    )
                    : data = (
                      <Flex align='center' justify='center'>
                        <Progress
                          variant='table'
                          colorScheme='brandScheme'
                          h='8px'
                          w='108px'
                          value={cell.value/sum*100}
                        />
                      </Flex>
                    )
                  } else if (cell.column.Header === "DEPOSIT") {
                    data = (
                      <Flex justify='center' align='center'>
                        
                        <ModalButton
                          mr='0px' mt='18px' pl='21px' pr='21px'
                          buttonText = {buttonTxt}
                          buttonIcon = <MdAddShoppingCart ml='2px'/>
                          buttonColor = "blue"
                          buttonSize = 'md'
                          buttonWidth = '150px'
                          modalType = {modalType}
                          contentText = {modalContent}/>
                      
                      </Flex>
                    );
                    
                  }
                  return (
                    <Td
                      {...cell.getCellProps()}
                      key={index}
                      fontSize={{ sm: "14px" }}
                      maxH='30px !important'
                      py='15px'
                      minW={{ sm: "150px", md: "200px", lg: "auto" }}
                      borderColor='transparent'>
                      {data}
                    </Td>
                  );
                })}
              </Tr>
            );
          })}
        </Tbody>
      </Table>
    </Card>
  );
}
