// React
import React, { useMemo, useState } from "react";
// External Libraries
import { Flex, Text } from "@chakra-ui/react";
// Components
import Card from "components/card/Card";
import TxnTableButton from "views/service/components/TxnTableButton";
import TxnTableContent from "views/service/components/TxnTableContent";
// Variables
import { columnsDataTxn } from "variables/txnTable/columnsDataTxn";
// Assets
import gradient1 from "assets/img/service/gradient1.jpg";

/**
 * 
 * Props list
 *  - txnTableData: transaction data for TxnTable
 * 
 */
export default function TxnTable({ txnTableData }) {

  // Variables to handle the menu tab (filtering the data)
  const [currentMenu, setCurrentMenu] = useState('ALL');
  
  // Variables for the table (react-table)
  const columns = useMemo(() => columnsDataTxn, [columnsDataTxn]);
  const tableData = useMemo(() => txnTableData, [txnTableData]);
  const tableDataKRC = useMemo(() => txnTableData.filter(d => d.type == 'KRC'), [tableData]);
  const tableDataKRW = useMemo(() => txnTableData.filter(d => d.type == 'KRW'), [tableData]);

  return (
    <Card
      direction='row'
      w='100%'
      overflowX={{ base: "scroll", sm: "scroll", lg: "hidden" }}
      backgroundImage={gradient1}
      backgroundRepeat='no-repeat'
      bgSize='cover'
      alignSelf='center'
      bgPosition='10%'>
      <Flex direction="column" justify='space-between'>
        <Flex direction='row' px='20px' pl='35px' justify='space-between' mt='35px' mb='0px' align='center'>
          <Text
            color='whiteAlpha.900'
            fontSize={{ base: "18px", sm: "20px", lg: "25px" }}
            fontWeight='700'
            lineHeight='100%'
            overflowX='hidden'
            overflowY='hidden'>
            {currentMenu} TRANSACTIONS
          </Text>
          
          <TxnTableButton
            ml="5px"
            mt="30px"
            mr="0px"
            currentMenu = {currentMenu}
            setCurrentMenu = {setCurrentMenu}
          />
        </Flex>

      {
        (tableData.length === 0)
        ? <TxnTableContent columns = {columns} data = {tableData} cFlag = "" dFlag = {0} />
        : (currentMenu === 'ALL')
        ? <TxnTableContent columns = {columns} data = {tableData} cFlag = "TYPE" dFlag = {6} />
        : (currentMenu === 'KRDC')
        ? <TxnTableContent columns = {columns} data = {tableDataKRC} cFlag = "TYPE" dFlag = {6}/>
        : (currentMenu === 'KRW')
        ?  <TxnTableContent columns = {columns} data = {tableDataKRW} cFlag = "TYPE" dFlag = {6}/>
        : <Card> NO TAB SELECTED </Card>
      }
       </Flex>
    </Card>
  );
}