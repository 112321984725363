// React
import React, { useState, useRef} from 'react';
// External Libraries
import styled from 'styled-components';
import { Link } from 'react-router-dom';
import { Text, Flex } from "@chakra-ui/react";

/**
 * 
 * Props list
 *  - item: Each element of SidebarDataList from SidebarContent.js
 *          [{title, iconClosed, iconOpened, subNav},{title, iconClosed, iconOpened, subNav}]
 *          0th element : AccountList element  /  1st element : ContactList element
 *  - onClose: useDisclosure() variable to handle the open/close state of the subNav element
 * 
 */
export default function SidebarConsoleElement ({ item }) {

  return (
    <>
      <SidebarLink  to={item.path} >
        <div>
        <Flex
          w='100%'
          flexDirection="row"
          justify='right'
          align='center'>
          {item.icon}
          <SidebarLabel>{item.title}</SidebarLabel>
        </Flex>
        </div>
      </SidebarLink>
    </>
  );
};

  const SidebarLink = styled(Link)`
    display: flex;
    color: #343434;
    justify-content: space-between;
    align-items: center;
    padding: 20px;
    list-style: none;
    height: 55px;
    text-decoration: none;
    font-size: 17px;
    font-weight: 400;
    margin-top: 8pt;
  
    &:hover {
      /* background: #D5D5D5; */
      border-left: 5px solid #2763A4;
      /* opacity: 0.3; */
      cursor: pointer;
    }
  `;
  
  const SidebarLabel = styled(Text)`
    margin-left: 16px;
    text-align: center;
  `;
 